<template>
  <div class="text-center">
    <v-snackbar
      v-model="snackbar.visible"
      :multi-line="snackbar.multiLine"
      :color="snackbar.color"
      light
    >
      {{ snackbar.text }}
      <template #action="{ attrs }">
        <v-btn
          class="btn-close"
          v-bind="attrs"
          fab
          text
          small
          @click="snackbar.visible = false"
        >
          <v-icon dark>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'BaseSnackbar',
  computed: {
    ...mapState('baseSnackbar', ['snackbar'])
  },
  methods: {
    ...mapMutations('baseSnackbar', ['closeAlert'])
  }
}
</script>

<style scoped>
.btn-close {
  display: flex;
}

.snack-text {
  display: flex;
  justify-content: flex-start;
}

.v-snack__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
