import axios from 'axios'

const getAllPeople = async () => {
  return await axios.get('/person', {
      params: {
        page: 1,
        itemsPerPage: -1,
      },
      headers: {
        'Cache-Control': 'no-cache'
      }
    }).then((response) => {
      return response.data
    }).catch(() => {
      return false
    })
}

const getSyncPersonByClinicalHistoryId = async (clinicalHistoryId) => {
  return await axios.get(`/person/clinical-history/${clinicalHistoryId}`).then((response) => {
      return response.data
    }).catch(() => {
      return null
    })
}

const getParentByPersonId = async (personId) => {
  return await axios.get(`/person/parent/${personId}`).then((response) => {
      return response.data
    }).catch(() => {
      return null
    })
}

export { getAllPeople, getSyncPersonByClinicalHistoryId, getParentByPersonId }
