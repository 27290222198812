<template>
  <div>
    <BaseLoadingToolbar />
    <nav app>
      <!-- Navigation -->
      <v-navigation-drawer
        ref="drawerNav"
        v-model="drawer"
        app
        clipped
        class="elevation-4"
        :mini-variant="miniVariant"
      >
        <v-skeleton-loader
          v-for="(i) in 6"
          :key="i"
          v-bind="{
            class: 'skeleton'
          }"
          type="chip"
        />
      </v-navigation-drawer>
    </nav>
  </div>
</template>

<script>
import BaseLoadingToolbar from './BaseLoadingToolbar.vue'
export default {
  name: 'BaseLoadingNavbar',
  components: {
    BaseLoadingToolbar
  },
  data () {
    return {
      miniVariant: null,
      drawer: null
    }
  },
  async mounted () {
    this.$watch('$refs.drawerNav.isMobile', (value) => {
      if (value === true) {
        this.miniVariant = false
      }
      if (value === false) {
        this.drawer = true
        this.miniVariant = true
      }
    })
  }
}
</script>
<style lang="css">
  .v-skeleton-loader__bone {
    width: 100% !important;
  }
  .skeleton {
    padding: 10px 20px 10px 20px !important;
  }
</style>
