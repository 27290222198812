import moment from 'moment'

class PersonDto {
  constructor (row) {
    this.firstname = row.Nombre
    this.lastname = row.Apellido
    this.bornDate = row['Fecha de Nacimiento']
    const date = moment(this.bornDate, 'DD-MM-YYYY')
    this.bornDate = date.isValid() ? date.format('YYYY-MM-DD') : null
    this.fixedBirthday = row['Fecha de Nacimiento Corregida (Nutricion)']
    this.fixedBirthday = this.fixedBirthday ? moment(this.fixedBirthday, 'DD-MM-YYYY').format('YYYY-MM-DD') : null
    this.document = row['Numero de Documento']
    this.document = this.document ? this.document.replaceAll(',', '').replaceAll('.', '') : null
    this.sex = row.Sexo
    this.gender = row.Genero
    this.healthInsurance = row['Posee Obra Social']
    this.healthInsuranceName = row['Nombre Obra Social']
    this.ethnicity = row['Etnia']
    this.language = row['Principal Idioma']
    this.interpreterNeeded = row['Necesita Intérprete']
    this.socialBenefits = row['¿Cuáles beneficios?']
    this.center = row.Centro
    this.community = row.Comunidad
    this.follow = {
      lastFollowDate: row['Fecha ultimo control'],
      lastFollowResult: row['Diagnóstico']
    }
    this.parent = {
      firstname: row['Nombre del adulto referente'],
      lastname: row['Apellido del adulto referente'],
      document: row['Documento del adulto referente (opcional)'],
      bond: row['Tipo de vinculo']
    }
  }
}

/**
 * Process text from csv file and parse it to array of objects
 * @param {String} content of CSV
 * @param {String} delimiter character
 * @returns an array of objects
 */
const csvToArray = (str, delimiter = ',') => {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf('\n')).replace('\r', '').split(delimiter)

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf('\n') + 1).split('\n')

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows.map(function (row) {
    const values = row.replace('\r', '').split(delimiter)
    const el = headers.reduce(function (object, header, index) {
      object[header] = values[index]
      return object
    }, {})
    return el
  })
  // return the array
  return arr.map(row => new PersonDto(row))
}

/**
 * Procesa un archivo .csv y lo convierte a un arreglo javascript
 * @param {File} archivo a procesar
 * @returns las filas del archivo csv parseadas a un array (descarta la primera fila / headers /)
 */
const getRows = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()
      reader.addEventListener('load', function (e) {
        resolve(csvToArray(e.target.result))
      })
      reader.readAsBinaryString(file)
    } catch (error) {
      reject(error)
    }
  })
}

/**
 * Si falta alguno de estos datos se toma como formato inválido
 */
const validatePerson = (person) => {
  if (!person ||
    !person.firstname ||
    !person.lastname) {
    return 'Formato de archivo inválido'
  }
  return null
}

/**
 * procesador de arhivo csv con datos de personas
 * retorna un arreglo con las personas
 */
const processPeople = async (file) => {
  let error
  // Parseamos el archivo a una estructura javascript
  const rows = await getRows(file).then(rows => { return rows })
    .catch(err => {
      console.log(err)
      error = err
    })

  if (error) return { error }

  // Validamos el formato correcto de los datos
  const rowForValidation = rows[0]
  error = validatePerson(rowForValidation)

  // Retornamos sólo los seguimientos que están dentro del rango de fechas seleccionados
  // rows = rows.filter(row => {
  //   return moment(row.date).isSameOrAfter(moment(filters.dateFrom)) &&
  //     moment(row.date).isSameOrBefore(moment(filters.dateTo))
  // })
  return { rows: rows, error }
}

export {
  processPeople
}
