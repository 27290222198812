import axios from 'axios'

export const state = {
  foundationStore: {
    name: '',
    isEnabled: null,
    description: '',
    addressId: null
  },
  foundations: [],
  totalFoundation: 0,
  filters: {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    name: '',
    includeDisabled: false
  }
}

export const mutations = {
  'SET_FOUNDATIONS' (state, data) {
    state.foundations = data.items
    state.totalFoundation = data.totalItems
  },
  'SET_FOUNDATION' (state, data) {
    state.foundationStore = data
  }
}

export const actions = {
  /**
     * Obtiene los usuarios de la base de datos filtrados y paginados.
     */
  getByFilters: async ({ commit }) => {
    await axios.get('/foundation', { params: state.filters })
      .then((response) => {
        commit('SET_FOUNDATIONS', response.data.body)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  /**
     * Crea un nuevo usuario en la base de datos y retona un true/false
     */
  create: async ({ commit, dispatch }, foundation) => {
    return await axios.post('/foundation', foundation)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: response.data.messageToShow }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  /**
     * Edita un usuario en la base de datos y retona un true/false
     */
  update: async ({ commit, dispatch }, foundation) => {
    return await axios.put('/foundation', foundation)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: response.data.messageToShow }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  /**
     * Busca un usuario en la base de datos
     */
  getFoundationById: async ({ commit }, id) => {
    return await axios.get('/foundation/' + id)
      .then((response) => {
        commit('SET_FOUNDATION', response.data.body)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  /**
     * Activa o desactiva un usuario de base de datos "status" = true/false
     */
  logicalEnabled: async ({ commit }, params) => {
    return await axios.put('/foundation/logicalEnabled/' + params.id, { status: params.status })
      .then((response) => {
        commit('baseSnackbar/success', { text: response.data.messageToShow }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  }
}
