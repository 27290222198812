import axios from 'axios'
export const state = {
  // Dto para los get, create, update
  results: [],
  totalResults: 0,
  filters: {
    includeDisabled: false,
    area: null,
    center: null,
    statusColor: null
  }
}
export const mutations = {
  'SET_REPORTS' (state, data) {
    state.results = data.items
    state.totalResults = data.totalItems
  }
}
export const actions = {
  getByFilters: async ({ commit }) => {
    await axios.get('report/get-patient-status-report', { params: state.filters })
      .then((response) => {
        commit('SET_REPORTS', response.data)
      }).catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
