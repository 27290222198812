import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import routes from './routes'
import store from '../state/store'
import { getFoundationName } from '../utils/urlHelper'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  keyName: 'page'
})

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// Antes de ingresa a cada ruta
router.beforeEach(async (to, from, next) => {
  const titleName = to.name ? `${getFoundationName()} - ${to.name}` : 'Inay'
  // Router rules
  if (to.path === '/reset/password') {
    // store.dispatch('auth/logOut', false)
    const token = to.query.token
    next({ path: '/reset/password/' + token })
  }
  if (to.meta.authRequired) {
    // Si requiere autenticación chequeamos token
    if (!store.getters['auth/loggedIn']) next({ name: 'Login' })
  }
  document.title = titleName
  next()
})

export default router
