import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/src/locale/es.ts'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    // dark: true,
    default: 'dark',
    themes: {
      light: {
        primary: '#0f6938',
        secondary: '#6e6e6e',
        accent: '#82B1FF',
        error: '#D63420',
        info: '#2196F3',
        success: '#5BCB4F',
        warning: '#FCAF2C',
        activeLink: '#7f97b3',
        orange: '#E58D1F'
      },
      dark: {
        primary: '#2196F3',
        secondary: '#6e6e6e',
        accent: '#FF4081',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
        activeLink: '#66668a',
        orange: '#E58D1F'
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es',
  }  
})
