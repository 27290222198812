import axios from 'axios'

export const state = {
  provincesStore: [],
  townsStore: []
}

export const mutations = {
  'SET_PROVINCES' (state, data) {
    state.provincesStore = data
  },
  'SET_TOWNS' (state, data) {
    state.townsStore = data
  }
}

export const actions = {
  async getProvinces ({ commit }) {
    await axios.get('/provinces/all').then(
      response => {
        commit('SET_PROVINCES', response.data.body)
      }
    )
  },
  async getTowns ({ commit }, provinceId) {
    await axios.get('/town/province/' + provinceId).then(
      response => {
        commit('SET_TOWNS', response.data)
      }
    )
  }
}
