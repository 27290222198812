var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticClass:"elevation-4 orange-bg",attrs:{"app":"","clipped-left":"","height":"65"}},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"avatar"}},'v-skeleton-loader',{
        class: 'skeleton-nav'
      },false)),_c('v-skeleton-loader',_vm._b({attrs:{"type":"image"}},'v-skeleton-loader',{
        class: 'skeleton-logo'
      },false)),_c('v-spacer'),_c('v-skeleton-loader',_vm._b({attrs:{"type":"avatar"}},'v-skeleton-loader',{
        class: 'skeleton-avatar'
      },false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }