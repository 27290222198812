<template>
  <div>
    <v-tooltip
      bottom
    >
      <template #activator="{ on }">
        <v-icon
          class="pr-1"
          :color="connectionStatus.color"
          style="font-size: 35px;"
          v-on="on"
        >
          {{ connectionStatus.icon }}
        </v-icon>
      </template>
      <span>{{ connectionStatus.text }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data () {
    return {
      connected: {
        icon: 'mdi-wifi',
        text: 'Tienes conexión',
        color: 'green'
      },
      disconnected: {
        icon: 'mdi-wifi-off',
        text: 'No tienes conexión',
        color: 'red'
      }
    }
  },
  computed: {
    connectionStatus () {
      if (this.$root.onLine) return this.connected
      return this.disconnected
    }
  }
}
</script>
