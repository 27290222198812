import axios from 'axios'

export const state = {
  nutritionalDataStore: {
    clinicalHistoryId: null,
    id: null,
    weight: null,
    size: null,
    headCircumference: null,
    externalData: null
  }
}

export const mutations = {
  SET_NUTRITIONAL_DATA (state, data) {
    state.nutritionalDataStore = data
  }
}

export const actions = {
  getNutritionalDataByClinicalHistoryId: async ({ commit }, id) => {
    return await axios.get('/nutritional-data/' + id)
      .then((response) => {
        commit('SET_NUTRITIONAL_DATA', response.data)
        return response.data
      })
      .catch((error) => {
        commit('SET_NUTRITIONAL_DATA', {})
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  createNutritionalData: async ({ commit, state }, nutritionalData) => {
    return await axios.post('nutritional-data', nutritionalData)
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Datos creados con éxito' }, { root: true })
        return response.data.id
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  updateNutritionalData: async ({ commit, state }, nutritionalData) => {
    return await axios.put('nutritional-data', nutritionalData)
      .then(() => {
        commit('baseSnackbar/success', { text: 'Datos actualizados con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
