import axios from 'axios'

export const state = {
  rodriguezTestStore: {
    id: null,
    motherName: null,
    totalPoints: null,
    testDate: null,
    examiner: null,
    weight: null,
    size: null,
    psychomotorDevelopment: null,
    clinicalHistoryId: null,
    observation: null,
    pointsByTest: {}
  },
  rodriguezTest: [], // aca se guardarian todos
  totalTest: 0,
  points: [],
  patientSelectedStore: null
}

export const mutations = {
  'SET_RODRIGUEZTEST_STORE' (state, data) {
    state.rodriguezTestStore = data
  },
  'SET_ALLRODRIGUEZTEST' (state, data) {
    state.rodriguezTest = data
  },
  'SET_PATIENT_STORE' (state, data) {
    state.patientSelectedStore = data
  },
  'SET_POINTS' (state, data) {
    state.points = data
  }
}

export const actions = {
  getRodriguezTest: async ({ commit }, clinicalHistoryId) => {
    await axios.get('rodriguez-test/clinical-history/' + clinicalHistoryId)
      .then((response) => {
        commit('SET_ALLRODRIGUEZTEST', response.data.body)
        return true
      })
      .catch((error) => {
        commit('SET_ALLRODRIGUEZTEST', [])
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  create: async ({ commit, dispatch }, test) => {
    const pointsByTest = []
    for (const point in test.pointsByTest) {
      if (test.pointsByTest[point] === true) {
        pointsByTest.push(
          {
            rodriguezTestId: test.id,
            rodriguezTestPointsId: point,
            id: null
          }
        )
      }
    }
    test.pointsByTest = pointsByTest
    return await axios.post('/rodriguez-test/', test)
      .then((response) => {
        dispatch('getRodriguezTest', test.clinicalHistoryId)
        commit('baseSnackbar/success', { text: response.data.messageToShow }, { root: true })
        return response.data.body
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  update: async ({ commit, dispatch }, test) => {
    const pointsByTest = []
    for (const point in test.pointsByTest) {
      if (test.pointsByTest[point] === true) {
        pointsByTest.push(
          {
            rodriguezTestId: test.id,
            rodriguezTestPointsId: point,
            id: null
          }
        )
      }
    }
    test.pointsByTest = pointsByTest
    return await axios.put('/rodriguez-test/', test)
      .then(
        (response) => {
          dispatch('getRodriguezTest', test.clinicalHistoryId)
          commit('baseSnackbar/success', { text: response.data.messageToShow }, { root: true })
          return response.status
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  getRodriguezTestById: async ({ commit }, id) => {
    return await axios.get('rodriguez-test/' + id)
      .then((response) => {
        commit('SET_RODRIGUEZTEST_STORE', response.data.body)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  getPoints: async ({ commit }) => {
    await axios.get('rodriguez-test/points')
      .then((response) => {
        commit('SET_POINTS', response.data.body)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  cleanStore: ({ commit }) => {
    commit('SET_RODRIGUEZTEST_STORE', null)
    commit('SET_ALLRODRIGUEZTEST', null)
    commit('SET_PATIENT_STORE', null)
    commit('SET_POINTS', null)
    return false
  }
}
