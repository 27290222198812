import axios from 'axios'

export const state = {
  errorLogStore: {
    id: null,
    resolved: true,
    errorMessage: null,
    source: null,
    quantity: null,
    createdByUserId: null,
    lastUpdateByUserId: null,
    lastUpdateByUserName: null,
    createdAt: null,
    updatedAt: null
  },
  errorLogs: [],
  totalerrorLogs: 0,
  filters: {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    name: '',
    startDate: null,
    endDate: null,
    includeResolved: false
  }
}

export const mutations = {
  'SET_ERROR_LOGS' (state, data) {
    state.errorLogs = data.items
    state.totalerrorLogs = data.totalItems
  },
  'SET_ERROR_LOG' (state, data) {
    state.errorLogStore = data
  }
}

export const actions = {
  getByFilters: async ({ commit }) => {
    await axios.get('/error-log', { params: state.filters })
      .then((response) => {
        commit('SET_ERROR_LOGS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  getErrorLogById: async ({ commit }, id) => {
    return await axios.get('/error-log/' + id)
      .then((response) => {
        commit('SET_ERROR_LOG', response.data.body)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  }
  // TO DO AGREGAR UDATE ACA Y EN EL CONTROLADOR
}
