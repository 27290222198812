import axios from 'axios'

export const state = {
  filters: {
    area: null,
    center: null,
    year: null,
    month: null
  },
  benefits: null
}

export const mutations = {
  'SET_BENEFITS' (state, data) {
    state.benefits = data
  }
}

export const actions = {
  getBenefitsByFilters: async ({ commit }) => {
    await axios.get('/report/get-benefits', { params: state.filters })
      .then((response) => {
        commit('SET_BENEFITS', response.data)
        return true
      })
      .catch((error) => {
        commit('SET_BENEFITS', [])
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
