import axios from 'axios'
export const state = {
  // Dto para los get, create, update
  personStore: {
    id: null,
    firstname: null,
    lastname: null,
    documentType: null,
    document: null,
    sex: null,
    bornDate: null,
    healthInsurance: null,
    healthInsuranceName: null,
    phoneNumber: null,
    socialBenefits: null,
    personType: null,
    cuil: null,
    centerId: null,
    ethnicity: null,
    language: null,
    interpreterNeeded: null,
    communityId: null,
    sexChanged: null,
    gender: null,
    schoolingLevel: null,
    familyGroupId: null // agrego para buscador grupo familiar
  },
  // Arreglo donde se almacenan los registros que devuelve la acción getAll
  persons: [],
  totalPersons: 0,
  filters: {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    includeDisabled: false,
    firstname: null,
    lastname: null,
    documentType: null,
    document: null,
    personType: null,
    centerId: null
  },
  profilePictureStore: null,
  familyPeopleStore: null
}
export const mutations = {
  'SET_PERSONS' (state, data) {
    state.persons = data.items
    state.totalPersons = data.totalItems
  },
  'SET_PERSON' (state, data) {
    state.personStore = data
  },
  'SET_PROFILE_PICTURE' (state, data) {
    state.profilePictureStore = data
  },
  'SET_FAMILY_PEOPLE' (state, data) {
    state.familyPeopleStore = data
  }
}
export const actions = {
  getByFilters: async ({ commit }) => {
    await axios.get('/person', { params: state.filters })
      .then((response) => {
        commit('SET_PERSONS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  getPersonById: async ({ commit }, id) => {
    return await axios.get('/person/' + id)
      .then((response) => {
        commit('SET_PERSON', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  getPersonByDocument: async ({ commit }, document) => {
    return await axios.get('/person/document/' + document)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  /**
     * Funcion para obtenerr foto de perfil
     * @param {int} id id de la persona
     */
  getProfilePictureById: async ({ commit }, id) => {
    return await axios.get('files/profile/' + id, { responseType: 'blob' })
      .then(response => {
        if (response.data) {
          // Convertimos la respuesta a un archivo tipo Blob
          const blob = new Blob([response.data], { type: 'image' })
          commit('SET_PROFILE_PICTURE', blob)
          return true
        }
        return false
      })
      .catch(() => {
        // commit('baseSnackbar/alert', { text: 'No se encontró foto de perfil', code: error.response.status }, { root: true })
        return false
      })
  },
  /**
     * Funcion para crear/actualizar foto de perfil
     * @param {Object} data objeto con el archivo de la imagen y el id de la persona
     */
  setProfilePictureById: async ({ commit }, data) => {
    // Creamos FormData para enviar en el body de la request
    const fd = new FormData()
    fd.append('file', data.picture)
    return await axios.post('files/profile/' + data.id, fd)
      .then(response => {
        if (response) {
          commit('baseSnackbar/success', { text: 'Foto actualizada con éxito' }, { root: true })
          return true
        }
        return false
      }).catch((e) => {
        commit('baseSnackbar/alert', { text: 'Ocurrió un error al cargar la imagen', code: e.response.status }, { root: true })
        return false
      })
  },
  update: async ({ commit, dispatch }, person) => {
    delete person.familyGroupId
    return await axios.put('/person/' + person.id, person)
      .then(
        (response) => {
          dispatch('getByFilters')
          commit('baseSnackbar/success', { text: 'Persona actualizada con éxito' }, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit, dispatch }, person) => {
    return await axios.post('/person', person)
      .then(
        (response) => {
          dispatch('getByFilters')
          commit('baseSnackbar/success', { text: 'Persona creada con éxito' }, { root: true })
          // Actualizamos estado de sincronización
          dispatch('sync/getNotSyncRegisters', {}, { root: true })
          return response.data
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  // Altas y bajas lógicas
  enable: async ({ commit }, params) => {
    return await axios.put('/person/enable/' + params.id, { status: params.status })
      .then((response) => {
        commit('baseSnackbar/success', { text: `Persona ${params.status === 1 ? 'activada' : 'desactivada'} con éxito` }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  // Modifica el grupo familiar de una persona
  updateFamilyGroup: async ({ commit }, params) => {
    return await axios.put('/person/family-group/' + params.id, { familyGroupId: params.familyGroupId, isChanging: params.isChanging })
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Grupo familiar actualizado' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  /**
   * Busca las personas por nombre, apellido y documento
   * junto con los datos de la historia clinica a la que
   * esta relacionada
   */
  getAllForClinicalHistory: async ({ commit }, value) => {
    return await axios.get('person/getAllForClinicalHistory', { params: { value: value } })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  /**
   * Busca las personas de un grupo familiar
   */
  getFamilyGroupPeopleById: async ({ commit }, id) => {
    return await axios.get('person/family-group/' + id)
      .then((response) => {
        commit('SET_FAMILY_PEOPLE', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  /**
   * Busca las personas por nombre, apellido y documento
   * junto con los datos de la grupo familiar a la que
   * esta relacionada filtrando por personas de tipo 'Niño' o 'Madre Embarazada'
   */
  getAllForFamilyGroup: async ({ commit }, value) => {
    return await axios.get('person/getAllForFamilyGroup', { params: { value: value } })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  /**
   * Busca las personas por nombre, apellido y documento
   * junto con los datos de la grupo familiar a la que
   * esta relacionada
   */
  getAllForFamilyGroupMember: async ({ commit }, value) => {
    return await axios.get('person/getAllForFamilyGroupMember', { params: { value: value } })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  /**
   * Busca las personas por nombre, apellido y documento
   * junto con los datos de la HC a la que estan relacionados
   * Siembre y cuando la HC este En Curso
   */
  getPatientForSearchWithHcByState: async ({ commit }, filters) => {
    return await axios.get(
      'person/patient-search-with-hc-by-state', { params: filters })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
