import { db } from "../database"

const userKey = 'currentUser'
const installationSkippedKey = 'installationSkipped'

export const getCurrentUser = async () => {
  const currentUser = await db.sessionStore.get(userKey)
  return currentUser
}

export const putCurrentUser = async (user) => {
  if (!user) return
  user.key = userKey
  db.sessionStore.put(user)
}

export const deleteCurrentUser = async () => {
  await db.sessionStore.delete(userKey)
}

export const putInstallationSkipped = async (value) => {
  const installationSkipped = {
    key: installationSkippedKey,
    value: value
  }
  await db.sessionStore.put(installationSkipped)
}

export const getInstallationSkipped = async () => {
  const installationSkipped = await db.sessionStore.get(installationSkippedKey)
  return installationSkipped && installationSkipped.value
}
