import axios from 'axios'

export const state = {
  communityStore: {
    id: '',
    name: '',
    townId: null,
    centerId: null,
    isEnabled: ''
  },
  communities: [],
  totalCommunities: 0,
  filters: {
    page: 1,
    itemsPerPage: 10,
    includeDisabled: false,
    name: '',
    townId: '',
    centerId: ''
  }
}

export const mutations = {
  'SET_COMMUNITIES' (state, data) {
    state.communities = data.items
    state.totalCommunities = data.totalItems
  },
  'SET_COMMUNITY' (state, data) {
    state.communityStore = data
  }
}

export const actions = {

  getByFilters: async ({ commit }) => {
    await axios.get('community', { params: state.filters })
      .then((response) => {
        commit('SET_COMMUNITIES', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit, dispatch }, community) => {
    return await axios.post('community', community)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Comunidad creada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  update: async ({ commit, dispatch }, community) => {
    return await axios.put(`community/${community.id}`, community)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Comunidad actualizada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getCommunityById: async ({ commit }, id) => {
    return await axios.get('community/' + id)
      .then((response) => {
        commit('SET_COMMUNITY', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  enable: async ({ commit }, params) => {
    return await axios.put('community/enable/' + params.id, { status: params.status })
      .then(
        (response) => {
          commit('baseSnackbar/success', { text: `Comunidad ${params.status === 1 ? 'activada' : 'desactivada'} con éxito` }, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }

}
