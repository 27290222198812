import { syncProcessStatuses } from '../../enums/sync.enum'
import { getNotSyncRegisters, getSyncErrorsCount, getPeopleSyncErrors, getClinicalHistorySyncErrors, getFollowsSyncErrors, getAttendancesSyncErrors, getMovementsSyncErrors, getLastUpdate } from '../../workbox/repository/sync.repository'
import { getAllNotSyncAttendances } from '../../workbox/repository/attendance.repository'

export const state = {
  notSyncRegistersCount: 0, // Registros esperando sincronización
  syncErrorsCount: 0, // Cantitdad de solicitudes con intento de sincronización erróneo
  syncProcessStatus: syncProcessStatuses.CLEAN, // estado de la sincronización
  peopleSyncErrors: [],
  clinicalHistorySyncErrors: [],
  followsSyncErrors: [],
  attendancesSyncErrors: [],
  movementsSyncErrors: [],
  notSyncPeople: [],
  notSyncClinicalHistories: [],
  notSyncFollows: [],
  notSyncAttendances: [],
  notSyncMovements: [],
  notSyncDiagnosis: [],
  lastUpdate: {}
}

export const mutations = {
  'SET_NOT_SYNC_REGISTERS' (state, notSyncRegisters) {
    let count = 0
    for (const notSyncList in notSyncRegisters) {
      count += notSyncRegisters[notSyncList].length
    }
    state.notSyncRegistersCount = count
    state.notSyncPeople = notSyncRegisters.notSyncPeople
    state.notSyncClinicalHistories = notSyncRegisters.notSyncClinicalHistories
    state.notSyncFollows = notSyncRegisters.notSyncFollows
    state.notSyncAttendances = notSyncRegisters.notSyncAttendances
    state.notSyncMovements = notSyncRegisters.notSyncMovements
    state.notSyncDiagnosis = notSyncRegisters.notSyncDiagnosis
  },
  'SET_NOT_SYNC_ATTENDANCES' (state, notSync) {
    state.notSyncAttendances = notSync
  },
  'SET_SYNC_ERRORS_COUNT' (state, count) {
    state.syncErrorsCount = count
  },
  'SET_APP_BADGE' (state) {
    const totalNotSync = state.syncErrorsCount + state.notSyncRegistersCount
    if (totalNotSync) {
      navigator.setAppBadge(totalNotSync)
    } else {
      navigator.clearAppBadge()
    }
  },
  'SET_PEOPLE_SYNC_ERRORS' (state, errors) {
    state.peopleSyncErrors = errors
  },
  'SET_CLINICAL_HISTORY_SYNC_ERRORS' (state, errors) {
    state.clinicalHistorySyncErrors = errors
  },
  'SET_FOLLOWS_SYNC_ERRORS' (state, errors) {
    state.followsSyncErrors = errors
  },
  'SET_ATTENDANCES_SYNC_ERRORS' (state, errors) {
    state.attendancesSyncErrors = errors
  },
  'SET_MOVEMENTS_SYNC_ERRORS' (state, errors) {
    state.movementsSyncErrors = errors
  },
  'SET_SYNC_PROCESS_STATUS' (state, status) {
    state.syncProcessStatus = status
  },
  'UPDATE_SYNC_PROCESS_STATUS' (state) {
    if (!state.notSyncRegistersCount > 0 && !state.syncErrorsCount) {
      state.syncProcessStatus = syncProcessStatuses.CLEAN
    } else if (state.syncErrorsCount) {
      state.syncProcessStatus = syncProcessStatuses.ERROR
    } else {
      state.syncProcessStatus = syncProcessStatuses.WAITING_FOR_SYNC
    }
  },
  'SET_LAST_UPDATE' (state, lastUpdate) {
    state.lastUpdate = lastUpdate
  }
}

export const actions = {
  getNotSyncRegisters: async ({ commit }) => {
    const notSync = await getNotSyncRegisters()
    commit('SET_NOT_SYNC_REGISTERS', notSync)
    commit('UPDATE_SYNC_PROCESS_STATUS')
    commit('SET_APP_BADGE')
  },
  getNotSyncAttendances: async ({ commit }) => {
    const notSync = await getAllNotSyncAttendances()
    commit('SET_NOT_SYNC_ATTENDANCES', notSync)
    commit('UPDATE_SYNC_PROCESS_STATUS')
    commit('SET_APP_BADGE')
  },
  getSyncErrorsCount: async ({ commit }) => {
    const syncErrorsCount = await getSyncErrorsCount()
    commit('SET_SYNC_ERRORS_COUNT', syncErrorsCount)
    commit('UPDATE_SYNC_PROCESS_STATUS')
    commit('SET_APP_BADGE')
  },
  getPeopleSyncErrors: async ({ commit }) => {
    const syncErrors = await getPeopleSyncErrors()
    commit('SET_PEOPLE_SYNC_ERRORS', syncErrors)
  },
  getClinicalHistorySyncErrors: async ({ commit }) => {
    const syncErrors = await getClinicalHistorySyncErrors()
    commit('SET_CLINICAL_HISTORY_SYNC_ERRORS', syncErrors)
  },
  getFollowsSyncErrors: async ({ commit }) => {
    const syncErrors = await getFollowsSyncErrors()
    commit('SET_FOLLOWS_SYNC_ERRORS', syncErrors)
  },
  getAttendancesSyncErrors: async ({ commit }) => {
    const syncErrors = await getAttendancesSyncErrors()
    commit('SET_ATTENDANCES_SYNC_ERRORS', syncErrors)
  },
  getMovementsSyncErrors: async ({ commit }) => {
    const syncErrors = await getMovementsSyncErrors()
    commit('SET_MOVEMENTS_SYNC_ERRORS', syncErrors)
  },
  getLastUpdate: async ({ commit }) => {
    const lastUpdate = await getLastUpdate()
    commit('SET_LAST_UPDATE', lastUpdate)
  },
  getAllSyncErrors: async ({ dispatch, commit }) => {
    dispatch('getPeopleSyncErrors')
    dispatch('getClinicalHistorySyncErrors')
    dispatch('getFollowsSyncErrors')
    dispatch('getAttendancesSyncErrors')
    dispatch('getMovementsSyncErrors')
    commit('UPDATE_SYNC_PROCESS_STATUS')
  }
}