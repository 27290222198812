import axios from 'axios'
import moment from 'moment'

export const state = {
  workshopScheduleStore: {
    id: null,
    date: null,
    workshopId: null,
    teacherId: null,
    reasonId: null,
    communityId: null
  },
  schedules: [],
  totalSchedules: 0,
  filters: {
    page: 1,
    itemsPerPage: 30,
    includeDisabled: false,
    dateFrom: moment().startOf('month').subtract(3, 'months').format('YYYY-MM-DD')
  },
  workshopTypes: []
}

export const mutations = {
  'SET_SCHEDULES' (state, data) {
    state.schedules = data.items
    state.totalSchedules = data.totalItems
  },
  'SET_SCHEDULE' (state, data) {
    state.workshopScheduleStore = data
  }
}

export const actions = {

  getByFilters: async ({ commit }) => {
    return await axios.get('workshop-schedule', { params: state.filters })
      .then((response) => {
        commit('SET_SCHEDULES', response.data)
        return true
      })
      .catch((error) => {
        commit('SET_SCHEDULES', [])
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit, dispatch }, schedule) => {
    return await axios.post('workshop-schedule', schedule)
      .then(() => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Agenda creada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  update: async ({ commit, dispatch }, schedule) => {
    return await axios.put(`workshop-schedule/${schedule.id}`, schedule)
      .then(() => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Agenda actualizada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getScheduleById: async ({ commit }, id) => {
    return await axios.get('workshop-schedule/' + id)
      .then((response) => {
        commit('SET_SCHEDULE', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  enable: async ({ commit }, params) => {
    return await axios.put('/workshop-schedule/enable/' + params.id, { status: params.status })
      .then((response) => {
        commit('baseSnackbar/success', { text: `Agenda ${params.status === 1 ? 'activado' : 'desactivado'} con éxito` }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }

}
