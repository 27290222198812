function getColor (value) {
  if (value === 3) return '#DB8163'
  else if (value === 2) return '#efa94a'
  else if (value === 1) return '#bdecb6'
  else if (value === 4) return 'D7D7D7'
  else return '#D7D7D7'
}
function getClass (value) {
  if (value === 3) return 'bg-red'
  else if (value === 2) return 'bg-yellow'
  else if (value === 1) return 'bg-green'
  else if (value === 4) return 'bg-white'
  else return 'bg-grey'
}
function getZColor (value) {
  if (value > -1 && value < 1) {
    return {
      bg: '#bdecb6',
      dark: false
    }
  } else if ((value > -2 && value <= -1) || (value >= 1 && value < 2)) {
    return {
      bg: '#efa94a',
      dark: false
    }
  } else if ((value > -3 && value <= -2) || (value >= 2 && value < 3)) {
    return {
      bg: '#DB8163',
      dark: false
    }
  } else {
    return {
      bg: '#000000',
      dark: true
    }
  }
}
function getReportColors () {
  return ['#bdecb6', '#efa94a', '#DB8163', '#D7D7D7']
}

function getGreenScale () {
  return ['#93c08c', '#a1cf9a', '#afdda8', '#bdecb6', '#ccfbc4']
}

function getYellowScale () {
  return ['#efa94a', '#f0b15c', '#f2ba6e', '#f3c280', '#f5cb92']
}
function getRedScale () {
  return ['#db8163', '#e4967b', '#ecab95', '#f2bfae', '#f8d4c9']
}

export {
  getColor,
  getClass,
  getZColor,
  getReportColors,
  getGreenScale,
  getYellowScale,
  getRedScale
}
