import axios from 'axios'

export const state = {
  anamnesisDataStore: {}
}

export const mutations = {
  'SET_ANAMNESIS' (state, data) {
    state.anamnesisDataStore = data
  }
}

export const actions = {
  getAnamnesisIdByClinicalHistoryId: async ({ commit }, clinicalHistoryId) => {
    if (!clinicalHistoryId) {
      commit('SET_ANAMNESIS', {})
      return false
    }
    return await axios.get('at-anamnesis/clinical-history/' + clinicalHistoryId)
      .then((response) => {
        commit('SET_ANAMNESIS', response.data.body)
        return true
      })
      .catch((error) => {
        commit('SET_ANAMNESIS', {})
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },

  update: async ({ commit }, anamnesis) => {
    return await axios.put('/at-anamnesis/' + anamnesis.id, anamnesis)
      .then(
        (response) => {
          commit('baseSnackbar/success', { text: response.data.messageToShow }, { root: true })
          return response.body
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit }, anamnesis) => {
    return await axios.post('/at-anamnesis', anamnesis)
      .then(
        (response) => {
          commit('baseSnackbar/success', { text: response.data.messageToShow }, { root: true })
          return response.data.body
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  }
}
