import axios from 'axios'

export const state = {
  workshopStore: {
    id: null,
    title: null,
    summary: null,
    description: null,
    areaId: null,
    typeId: null
  },
  workshops: [],
  totalWorkshops: 0,
  filters: {
    page: 1,
    itemsPerPage: 10,
    includeDisabled: false
  },
  workshopTypes: [],
  workshopReasons: []
}

export const mutations = {
  'SET_WORKSHOPS' (state, data) {
    state.workshops = data.items
    state.totalWorkshops = data.totalItems
  },
  'SET_WORKSHOP' (state, data) {
    state.workshopStore = data
  },
  'SET_WORKSHOP_TYPES' (state, data) {
    state.workshopTypes = data
  },
  'SET_WORKSHOP_REASONS' (state, data) {
    state.workshopReasons = data
  }
}

export const actions = {

  getByFilters: async ({ commit }) => {
    return await axios.get('workshop', { params: state.filters })
      .then((response) => {
        commit('SET_WORKSHOPS', response.data)
        return true
      })
      .catch((error) => {
        commit('SET_WORKSHOPS', [])
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit, dispatch }, workshop) => {
    return await axios.post('workshop', workshop)
      .then(() => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Taller creada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  update: async ({ commit, dispatch }, workshop) => {
    return await axios.put(`workshop/${workshop.id}`, workshop)
      .then(() => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Taller actualizada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getWorkshopById: async ({ commit }, id) => {
    return await axios.get('workshop/' + id)
      .then((response) => {
        commit('SET_WORKSHOP', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getWorkshopTypes: async ({ commit }) => {
    return await axios.get('workshop/types/all')
      .then((response) => {
        commit('SET_WORKSHOP_TYPES', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getWorkshopReasons: async ({ commit }) => {
    return await axios.get('workshop/reasons/all')
      .then((response) => {
        commit('SET_WORKSHOP_REASONS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  enable: async ({ commit }, params) => {
    return await axios.put('/workshop/enable/' + params.id, { status: params.status })
      .then((response) => {
        commit('baseSnackbar/success', { text: `Taller ${params.status === 1 ? 'activado' : 'desactivado'} con éxito` }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }

}
