<template>
  <div>
    <v-app-bar
      app
      clipped-left
      height="65"
      class="elevation-4 orange-bg"
    >
      <v-app-bar-nav-icon @click="openNav" />
      <v-img
        max-width="110"
        max-height="70"
        :src="getAsset('Horizontal.webp')"
        @click="goHome"
      />
      <v-spacer />
      <BaseNotificationsIcon v-if="loggedUser.center?.id" />
      <BaseToolbarConectionStatus v-if="!loggedUser.area.onlineRequired" />
      <BaseToolbarSyncStatus v-if="!loggedUser.area.onlineRequired" />
      <BaseToolbarUserAvatar v-if="loggedUser" />
    </v-app-bar>
  </div>
</template>
<script>
import BaseToolbarUserAvatar from '../Toolbar/BaseToolbarUserAvatar'
import BaseToolbarSyncStatus from '../Toolbar/BaseToolbarSyncStatus.vue'
import { getCurrentUser } from '../../utils/localStorageService'
import { getAsset } from '../../utils/assetsHelper'
import BaseToolbarConectionStatus from './BaseToolbarConectionStatus.vue'
import BaseNotificationsIcon from './BaseNotificationsIcon.vue'
export default {
  components: {
    BaseToolbarUserAvatar,
    BaseToolbarSyncStatus,
    BaseNotificationsIcon,
    BaseToolbarConectionStatus
},
  data () {
    return {
      loggedUser: null
    }
  },
  async beforeMount () {
    this.loggedUser = this.getCurrentUser()
  },
  methods: {
    openNav () {
      this.$emit('openNav')
    },
    goHome () {
      if (this.$route.fullPath !== '/') this.$router.push('/')
    },
    getAsset,
    getCurrentUser
  }
}
</script>
<style css scoped>
  .orange-bg {
    background-color: #E58D1F !important;
  }
</style>
