export const state = {
  loading: false
}
export const mutations = {
  /**
     * Muestra un modal con una barra de progreso
    */
  activateLoading (state, activate) {
    state.loading = activate
  }
}
