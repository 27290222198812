import axios from 'axios'

export const state = {
  referralStore: {
    id: null,
    startDate: null,
    summary: null,
    state: null,
    clinicalHistory: null,
    institution: null,
    clinicalHistoryId: null,
    institutionId: null,
    reason: null
  },
  referralsStore: [],
  totalReferrals: 0,
  filters: {
    page: 1,
    itemsPerPage: 10
  }
}

export const mutations = {
  'SET_REFERRALS' (state, data) {
    state.referralsStore = data.items
    state.totalReferrals = data.totalItems
  },
  'SET_REFERRAL' (state, data) {
    state.referralStore = data
  }
}

export const actions = {

  getByClinicalHistoryId: async ({ commit }, clinicalHistoryId) => {
    return await axios.get(`referral/clinicalHistory/${clinicalHistoryId}`, { params: state.filters })
      .then((response) => {
        commit('SET_REFERRALS', response.data)
        return true
      })
      .catch((error) => {
        commit('SET_REFERRALS', { items: [], totalItems: 0 })
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getReferralCountByClinicalHistoryId: async ({ commit }, clinicalHistoryId) => {
    return await axios.get(`referral/clinicalHistory/${clinicalHistoryId}/count`)
      .then((response) => {
        return response.data
      })
      .catch(() => {
        // commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit, dispatch }, referral) => {
    return await axios.post('referral', referral)
      .then((response) => {
        dispatch('getByClinicalHistoryId', referral.clinicalHistoryId)
        commit('baseSnackbar/success', { text: 'Derivación creada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  update: async ({ commit, dispatch }, referral) => {
    return await axios.put(`referral/${referral.id}`, referral)
      .then((response) => {
        dispatch('getByClinicalHistoryId', referral.clinicalHistoryId)
        commit('baseSnackbar/success', { text: 'Derivación actualizada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getReferralById: async ({ commit }, id) => {
    return await axios.get('referral/' + id)
      .then((response) => {
        commit('SET_REFERRAL', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }

}
