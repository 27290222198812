import Vue from 'vue'
import './registerServiceWorker'
import App from './App.vue'
import router from './router/index'
import createAxios from './utils/createAxios'
import store from './state/store'
import vuetify from './plugins/vuetify'
import BaseNavbar from './components/Layout/BaseNavbar'
import BaseLoadingNavbar from './components/Layout/BaseLoadingNavbar'
import './utils/vee-validate'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import * as VueGoogleMaps from 'vue2-google-maps'

// Definición de componentes layout
Vue.component('NavbarLayout', BaseNavbar)
Vue.component('LoadingLayout', BaseLoadingNavbar)

// Configuración de axios
createAxios()
// inicializamos componente maps con una key de juan
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,
    libraries: 'places'
  }
})

new Vue({
  router,
  store,
  vuetify,
  data: () => {
    return {
      onLine: navigator.onLine
    }
  },
  beforeMount() {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
  methods: {
    updateOnlineStatus() {
      this.onLine = navigator.onLine
    }
  },
  render: h => h(App)
}).$mount('#app')
