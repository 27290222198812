import axios from 'axios'

export const state = {
  productStore: {
    name: null,
    description: null,
    type: null,
    typeId: null,
    magnitude: null,
    barcode: null,
    isEnabled: null
  },
  products: [],
  totalProducts: 0,
  filters: {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    name: null,
    barcode: null,
    typeId: null,
    includeDisabled: false
  }
}

export const mutations = {
  'SET_PRODUCTS' (state, data) {
    state.products = data.items
    state.totalProducts = data.totalItems
  },
  'SET_PRODUCT' (state, data) {
    state.productStore = data
  }
}

export const actions = {
  /**
   * Obtiene los productos a partir de los filtros establecidos
   */
  getByFilters: async ({ commit }) => {
    await axios.get('/product', { params: state.filters })
      .then((response) => {
        commit('SET_PRODUCTS', response.data)
        return true
      })
      .catch((error) => {
        commit('SET_PRODUCTS', [])
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  /**
     * Crea un nuevo producto en la base de datos y retona un true/false
     */
  create: async ({ commit, dispatch }, product) => {
    return await axios.post('/product', product)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Producto creado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  /**
     * Edita un producto en la base de datos y retona un true/false
     */
  update: async ({ commit, dispatch }, product) => {
    return await axios.put('/product/' + product.id, product)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Producto actualizado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  /**
     * Busca un producto en la base de datos
     */
  getProductById: async ({ commit }, id) => {
    return await axios.get('/product/' + id)
      .then((response) => {
        commit('SET_PRODUCT', response.data)
        return response.data
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  /**
     * Activa o desactiva un producto de base de datos "status" = true/false
     */
  enable: async ({ commit }, params) => {
    return await axios.put('/product/enable/' + params.id, { status: params.status })
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Producto desactivado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
