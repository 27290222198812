import { paginateArray } from "../../utils/arrayHelper"
import { stringAgeCalculator } from "../../utils/dateHelper"
import { db } from "../database"
import { getActivePatientsByFilters, getClinicalHistoryById } from "./clinicalHistory.repository"
import { getPersonById } from "./person.repository"
import { attendanceStatus } from '../../enums/attendance.enum'
import { CONNECTION_ERROR_MESSAGE, FETCH_ERROR_MESSAGE } from "../../utils/constants"

// Construye el modelo para guardar una asistencia autogenerada
const getAttendanceModelForAutoGeneration = (patientToAttend, date) => {
  return {
    date: date,
    clinicalHistoryId: patientToAttend.id || null,
    status: attendanceStatus.Ausente,
    isEnabled: true,
    wasManuallyAdded: false,
    notSync: 1,
    isLocked: patientToAttend.notSync
  }
}

export const getAttendanceById = async (id) => {
  return await db.attendanceStore.get(id)
}

export const updateAttendance = async (id, { status, notSync }) => {
  await db.attendanceStore.update(id, { status: status, notSync: notSync })
  return await getAttendanceById(id)
}

export const addOrUpdateAttendance  = async (attendance) => {
  if (!attendance) return
  attendance.clinicalHistory = await getClinicalHistoryById(attendance.clinicalHistoryId)
  const attendanceAdded = await db.attendanceStore.put(attendance)
  return attendanceAdded
}

export const addAttendances  = async (attendances) => {
  if (!attendances) return
  for (const attendance of attendances) {
    attendance.clinicalHistory = await getClinicalHistoryById(attendance.clinicalHistoryId)
  }
  await db.attendanceStore.bulkPut(attendances)
}

// Buscador de asistencias con filtros
export const getAttendancesByFilters = async ({ page, itemsPerPage, includeManuallyAdded, date, communityId, personType })=> {
  // Obtenemos todas las asistencias
  let attendances = await db.attendanceStore.where({ date }).toArray()
  for (const attendance of attendances) {
    const clinicalHistory = await getClinicalHistoryById(attendance.clinicalHistoryId)
    attendance.clinicalHistory = clinicalHistory
  }
  // Filtramos por comunidad
  if (communityId) {
    attendances = attendances.filter(attendance => attendance.clinicalHistory.person.communityId === Number(communityId))
  }
  if (personType) {
    attendances = attendances.filter(attendance => attendance.clinicalHistory.person.personType === personType)
  }
  if (!includeManuallyAdded) {
    attendances = attendances.filter(attendance => !attendance.wasManuallyAdded)
  }
  attendances.sort((p1, p2) => String(p1.clinicalHistory.person.lastname).toUpperCase().localeCompare(String(p2.clinicalHistory.person.lastname).toUpperCase()))
  return paginateArray(attendances, page, itemsPerPage)
}

// Generador de asistencias luego de filtrar por fecha y coomunidad
// Busca a las personas de la comunidad que deberían asistir ese día, y les crea asistencias en estado ausente
export const generateAttendances = async ({page, itemsPerPage, includeManuallyAdded, date, communityId, personType, states}) => {
  communityId = Number(communityId)
  const filtersForExistingAttendances = {
    page: -1,
    itemsPerPage: -1,
    date: date,
    communityId: communityId,
    personType: personType,
    includeManuallyAdded: true
  }
  // Primero validamos asistencias existentes y las guardamos en un diccionario por id de legajo
  let existingAttendances = await getAttendancesByFilters(filtersForExistingAttendances)
  var existingAttendancesMap = new Map()
  if (existingAttendances) {        
    existingAttendances.items.forEach(attendance => {
      existingAttendancesMap.set(attendance.clinicalHistory.id, attendance.id)
    })
  }
  // Luego buscamos a todos los pacientes que deberían asistir
  const patientsToAttend = await getActivePatientsByFilters(communityId, date, personType, states)
  if (patientsToAttend && patientsToAttend.length > 0) {
    const newAttendances = []
    patientsToAttend.forEach(patientToAttend => {
      if (!existingAttendancesMap.get(patientToAttend.id)) {
        // Si todavía no tiene asistencia generada se la creamos
        newAttendances.push(getAttendanceModelForAutoGeneration(patientToAttend, date))
      }
    })
    await addAttendances(newAttendances)
  }
  // Por último retornamos todas las asistencias
  const result = await getAttendancesByFilters({
    page,
    itemsPerPage,
    includeManuallyAdded,
    date,
    communityId,
    personType
  })
  return result
}

export const getAllNotSyncAttendances = async () => {
  let attendances = await db.attendanceStore.where({ notSync: 1 }).toArray()
  return attendances
}

export const addFailedSyncAttendances = async (attendancesErrors) => {
  for (const attendanceError of attendancesErrors) {
    db.failedAttendancesStore.put(attendanceError)
  }
}

export const removeNotSyncAttendances = async () => {
  const notSyncAttendances = await getAllNotSyncAttendances()
  for (const attendance of notSyncAttendances) {
    await db.attendanceStore.delete(attendance.id)
  }
}

export const deleteAttendance = async (attendanceId) => {
  await db.attendanceStore.delete(Number(attendanceId))
}


export const addOrUpdateAttendanceError = async (attendanceError) => {
  const attendance = await getAttendanceById(attendanceError.id)
  if (attendance.error && attendance.error.message === FETCH_ERROR_MESSAGE) {
    attendance.error.message = CONNECTION_ERROR_MESSAGE
  }
  const attendanceAdded = await db.failedAttendancesStore.put(attendance)
  return attendanceAdded
}

export const getAttendanceByPatientAndDate = async (clinicalHistoryId, date) => {
  return await db.attendanceStore.where({ clinicalHistoryId, date }).toArray()
}

// Crea una asistencia agregada manualmente
export const createAttendance = async (attendance) => {
  const clinicalHistory = await getClinicalHistoryById(attendance.clinicalHistoryId)
  const person = await getPersonById(clinicalHistory.personId)
  // Replicamos el modelo para guardar
  const attendanceForSave = {
    age: stringAgeCalculator(person.bornDate, new Date()),
    clinicalHistory: clinicalHistory,
    clinicalHistoryId: clinicalHistory.id,
    date: attendance.date,
    isEnabled: 1,
    lastUpdateByUserId: null,
    status: attendanceStatus.Presente,
    wasManuallyAdded: true,
    notSync: 1,
    isLocked: clinicalHistory.notSync
  }
  return await addOrUpdateAttendance(attendanceForSave)
}