export const state = {
  // Valores por defecto
  snackbar: {
    visible: false,
    color: 'success',
    text: null,
    timeout: 10000,
    multiline: false
  },
  colorByCode: (code) => {
    if (code >= 300 && code < 500) {
      return 'warning'
    } else {
      return 'error'
    }
  }
}
export const mutations = {
  /**
     * Muestra un snackbar configurable
     * @param {Object} payload objeto que contiene las configuraciones del snackbar
    */
  success (state, payload) {
    state.snackbar.text = payload.text || 'Operación concretada con éxito.'
    state.snackbar.color = payload.color || 'success'

    state.snackbar.multiline = payload.text.length >= 50

    // Si es mas de 50 caracteres definirlo como multilinea
    if (payload.multiline) {
      state.snackbar.multiline = payload.multiline
    }

    // Tiempo de duración
    if (payload.timeout) {
      state.snackbar.timeout = payload.timeout
    }

    state.snackbar.visible = true
  },
  /**
     * Muestra un snackbar configurable y asigna color en función del código de la respuesta
     * @param {Object} payload objeto que contiene las configuraciones del snackbar
    */
  alert (state, payload) {
    if (!payload) return false
    state.snackbar.text = payload.text || 'Ocurrió un problema al realizar esta acción.'
    state.snackbar.multiline = payload.text && payload.text.length >= 50

    // Color para mensajes de error o éxito
    state.snackbar.color = payload.color || state.colorByCode(payload.code)

    // Si es mas de 50 caracteres definirlo como multilinea
    if (payload.multiline) {
      state.snackbar.multiline = payload.multiline
    }

    // Tiempo de duración
    if (payload.timeout) {
      state.snackbar.timeout = payload.timeout
    }
    state.snackbar.visible = true
  },
  closeAlert (state) {
    state.snackbar.visible = false
    state.snackbar.multiline = false
    state.snackbar.text = null
  }
}
