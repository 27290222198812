// import { openTransaction } from "../database"
import { getClinicalHistoryById, getClinicalHistoryByPersonId } from "./clinicalHistory.repository"
import { getAllPeople, getPersonById } from "./person.repository"


const getPersonForClinicalHistorySearchModel = ({ person, clinicalHistory }) => {
  return {
    id: person.id,
    personType: person.personType,
    firstname: person.firstname,
    lastname: person.lastname,
    document: person.document,
    centerId: person.centerId,
    clinicalHistoryId: clinicalHistory && clinicalHistory.id,
    title: `${person.firstname} ${person.lastname}`,
    description: `${clinicalHistory ? 'Legajo: ' + clinicalHistory.id + ' - ' : ''}Documento: ${person.document} - Comunidad: ${person.community?.name}`,
    bornDate: person.bornDate,
    notSync: person.notSync || (clinicalHistory && clinicalHistory.notSync)
  }
}

const getPatientForFollowUpSearchModel = ({ person, clinicalHistory }) => {
  return {
    id: person.id,
    personId: person.id,
    personType: person.personType,
    sex: person.sex,
    firstname: person.firstname,
    lastname: person.lastname,
    document: person.document,
    centerId: person.centerId,
    familyGroupId: person.familyGroupId,
    clinicalHistoryId: clinicalHistory && clinicalHistory.id,
    title: `${person.firstname} ${person.lastname}`,
    subtitle: `${clinicalHistory ? 'Legajo: ' + clinicalHistory.id + ' - ' : ''}Documento: ${person.document} - Comunidad: ${person.community?.name}`,
    bornDate: person.bornDate,
    fixedBirthday: clinicalHistory && clinicalHistory.fixedBirthday,
    notSync: person.notSync || (clinicalHistory && clinicalHistory.notSync),
    communityName: person.community?.name,
    clinicalHistoryState: clinicalHistory?.state
  }
}

/**
 * Busca un listado de pacientes por valor de búsqueda con la información de su legajo (en caso de que tenga)
 * @param {String} valor de búsqueda que debe coincidir con el nombre o el apellido
 * @param {Number} id de centro para filtrar
 * @param {Boolean} Si es verdadero retornará sólo pacientes con historías clínicas creadas 
 * @returns 
 */
export const getPatientsByValue = async (searchValue, centerId, clinicalHistoryRequired) => {
  const peopleResult = await getAllPeople(-1, -1, centerId)
  const people = peopleResult.items
  // Primeros obtenemos las personas cuyo nombre coincide con el criterio de búsqueda
  const matchedPeople = people.filter(person => {
    const title = `${person.firstname}${person.lastname}`
    return title.toUpperCase().includes(searchValue.toUpperCase())
  })
  const patients = []
  for (const person of matchedPeople) {
    const clinicalHistory = await getClinicalHistoryByPersonId(person.id)
    if (clinicalHistoryRequired && !clinicalHistory) continue
    patients.push({
      person,
      clinicalHistory
    })
  }
  return patients
}


/**
 * Simulador de búsqueda de pacientes en la sección de Seguimientos para cuando no hay conexión
 * @param {*} valor de búsqueda
 * @returns pacientes que coinciden con el valor de búsqueda (la coincidencia es por nombre o apellido)
 */
export const getPatientsForFollowUpSearch = async (searchValue, centerId) => {
  const patients = await getPatientsByValue(searchValue, centerId, true)
  return patients
    .map(patient => { return getPatientForFollowUpSearchModel(patient) })
    .filter(patient => !centerId || patient.centerId === centerId )
}

/**
 * Busca personas (con sus legajos en caso de que tengan) para la sección
 * de legajos
 * @returns personas que coinciden con el valor de búsqueda (la coincidencia es por nombre o apellido)
 */
export const getPeopleForClinicalHistorySearch = async (searchValue, centerId) => {
  const people = await getPatientsByValue(searchValue, centerId)
  const test = people
    .map(person => { return getPersonForClinicalHistorySearchModel(person) })
  return test.filter(person => !centerId || person.centerId === centerId )
  
}

export const getPatientForFollowUp = async (clinicalHistoryId) => {
  const clinicalHistory = await getClinicalHistoryById(clinicalHistoryId)
  if (!clinicalHistory) return null
  const person = await getPersonById(clinicalHistory.personId)
  return getPatientForFollowUpSearchModel({person, clinicalHistory})
}
