import axios from 'axios'

export const actions = {
  getFileList: async ({ commit }, clinicalHistoryId) => {
    return await axios.get(`files/patient/${clinicalHistoryId}`)
      .then(
        (response) => {
          return response.data.filter(file => { return file.name !== '' })
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  downloadFile: async ({ commit }, fileData) => {
    return await axios.get(`files/?clinicalHistoryId=${fileData.clinicalHistoryId}&fileName=${fileData.fileName}`, { responseType: 'blob' })
      .then(
        (response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileData.fileName
          link.click()
          URL.revokeObjectURL(link.href)
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  uploadFile: async ({ commit }, fileData) => {
    const formData = new FormData()
    formData.append('file', fileData.file)
    const headers = { 'Content-Type': 'multipart/form-data' }
    return await axios.post(`files/patient/${fileData.clinicalHistoryId}`, formData, { headers })
      .then(
        () => {
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
