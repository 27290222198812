import config from '../utils/config'

const getWebServiceUrl = () => {
  const clientPort = config.VUE_APP_PORT
  const clientHost = config.VUE_APP_HOST
  const clientHttp = config.VUE_APP_HTTP
  return config.NODE_ENV === 'production' ? `${clientHttp}://${clientHost}`
  : `${clientHttp}://${clientHost}:${clientPort}`
}

const getFoundationName = () => {
  const currentUrl = String(window.location.href)
  return currentUrl.includes('dignamente') ? 'Dignamente'
    : currentUrl.includes('patapila') ? 'Pata Pila' : 'Pata Pila'
}

const getQueryParams = (urlParams) => {
  if (!urlParams) return {}
  const params = Object.fromEntries(  
    new URLSearchParams(urlParams)
  )
  return params
}

export {
  getWebServiceUrl,
  getFoundationName,
  getQueryParams
}
