import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', { currentUser: (state) => state.currentUser }),
  ...mapGetters('auth', ['loggedIn'])
}

export const authActions = mapActions('auth', ['logIn', 'logOut'])

export const enumComputed = {
  ...mapState('enum', ['clinicalHistoryState', 'docType', 'healthInsurance', 'sex', 'personType', 'overcrowdingTypes', 'housingConditionsTypes', 'waterTypes', 'electricityTypes', 'fuelTypes', 'locationTypes', 'familyType', 'occupationalSituation', 'psychomotorDevelopment'])
}
