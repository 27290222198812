import { render, staticRenderFns } from "./BaseToolbar.vue?vue&type=template&id=afbe66ba&scoped=true&"
import script from "./BaseToolbar.vue?vue&type=script&lang=js&"
export * from "./BaseToolbar.vue?vue&type=script&lang=js&"
import style0 from "./BaseToolbar.vue?vue&type=style&index=0&id=afbe66ba&prod&css=true&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afbe66ba",
  null
  
)

export default component.exports