import axios from 'axios'

export const state = {
  productTypeStore: {
    name: null,
    description: null,
    unity: null,
    offlineEnabled: false,
    isEnabled: null
  },
  types: [],
  totalTypes: 0,
  filters: {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    includeDisabled: false
  },
  minimumStockStore: {},
  quantitiesByType: []
}

export const mutations = {
  'SET_TYPES' (state, data) {
    state.types = data.items
    state.totalTypes = data.totalItems
  },
  'SET_PRODUCT_TYPE' (state, data) {
    state.productTypeStore = data
  },
  'SET_MINIMUM_STOCK_STORE' (state, data) {
    state.minimumStockStore = data
  },
  'SET_QUANTITIES' (state, data) {
    state.quantitiesByType = data
  }
}

export const actions = {

  getByFilters: async ({ commit }) => {
    await axios.get('/product-type', { params: state.filters })
      .then((response) => {
        commit('SET_TYPES', response.data)
        return true
      })
      .catch((error) => {
        commit('SET_TYPES', [])
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit, dispatch }, product) => {
    return await axios.post('/product-type', product)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Categoría creada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  update: async ({ commit, dispatch }, product) => {
    return await axios.put('/product-type/' + product.id, product)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Categoría actualizada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getProductTypeById: async ({ commit }, id) => {
    return await axios.get('/product-type/' + id)
      .then((response) => {
        commit('SET_PRODUCT_TYPE', response.data)
        return response
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  enable: async ({ commit }, params) => {
    return await axios.put('/product-type/enable/' + params.id, { status: params.status })
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Categoría desactivada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getMinimumByTypeId: async ({ commit }, productTypeId) => {
    return await axios.get('/product-type/minimum/' + productTypeId)
      .then((response) => {
        commit('SET_MINIMUM_STOCK_STORE', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  createMinimumStock: async ({ commit, dispatch }, product) => {
    return await axios.post('/product-type/minimum', product)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Stock mínimo creado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  updateMinimumStock: async ({ commit, dispatch }, product) => {
    return await axios.put('/product-type/minimum/' + product.id, product)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Stock mínimo actualizado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getQuantitiesByType: async ({ commit }, centerId) => {
    return await axios.get('/product-type/quantities', { params: { centerId: centerId } })
      .then((response) => {
        commit('SET_QUANTITIES', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
