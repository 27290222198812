import axios from 'axios'

export const state = {
  childEntryReasons: [
    'Ingreso por desnutrición',
    'Ingreso por mal nutrición',
    'Ingreso por hermano en tratamiento',
    'Ingreso por situación de vulnerabilidad social extrema',
    'Ingreso por traslado/derivación a otro centro de esta fundación',
    'Ingreso por derivación de otro Centro de otra fundación',
    'Ingreso por psicopedagogía',
    'Ingreso por madre en programa'
  ],
  childReEntryReasons: [
    'Reingreso por desnutrición',
    'Reingreso por mal nutrición',
    'Reingreso por situación de vulnerabilidad social extrema'
  ],
  childExitReasons: [
    'Egreso por recuperación total',
    'Egreso por recuperación parcial',
    'Egreso por edad',
    'Egreso por abandono',
    'Egreso por derivación a otro Centro',
    'Egreso por fallecimiento'
  ],
  pregnantEntryReasons: [
    'Embarazo avanzado con vulnerabilidad social',
    'Embarazo de primer trimestre',
    'Embarazada bajo peso',
    'Embarazada mal nutrida',
    'Embarazo no deseado',
    'Embarazada víctima de violencia',
    'Embarazada con patología crónica',
    'Embarazada con dificultad intelectual',
    'Embarazada con familia numerosa',
    'Embarazada adolescente'
  ],
  pregnantExitReasons: [
    '45 días posteriores al parto',
    'Interrupción del embarazo',
    'Abandono',
    'Egreso por fallecimiento'
  ],
  transitionsStore: []
}

export const mutations = {
  'SET_TRANSITIONS_STORE' (state, data) {
    state.transitionsStore = data
  }
}

export const actions = {
  getTransitions: async ({ commit }, clinicalHistoryId) => {
    await axios.get('hc-transition/' + clinicalHistoryId)
      .then((response) => {
        commit('SET_TRANSITIONS_STORE', response.data)
        return true
      })
      .catch((error) => {
        commit('SET_TRANSITIONS_STORE', [])
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  create: async ({ commit }, transition) => {
    return await axios.post('hc-transition', transition)
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Transición creada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
