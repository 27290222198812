import { putCurrentUser } from "../workbox/repository/session.repository"

const userKey = 'auth.currentUser'

function getToken () {
  const currentUser = JSON.parse(window.localStorage.getItem(userKey))
  if (currentUser) {
    const token = currentUser.token
    return token
  }
  return null
}

function getRefreshToken () {
  const currentUser = JSON.parse(window.localStorage.getItem(userKey))
  if (currentUser) {
    const refreshToken = currentUser.refreshToken
    return refreshToken
  }
  return null
}

function getCurrentUser () {
  const currentUser = JSON.parse(window.localStorage.getItem(userKey))
  if (currentUser) {
    return currentUser
  }
  return null
}

function setToken (token) {
  const currentUser = JSON.parse(window.localStorage.getItem(userKey))
  if (currentUser) {
    currentUser.token = token
    setCurrentUser(currentUser)
    return true
  }
  return false
}

function setRefreshToken (refreshToken) {
  const currentUser = JSON.parse(window.localStorage.getItem(userKey))
  if (currentUser) {
    currentUser.refreshToken = refreshToken
    setCurrentUser(currentUser)
    return true
  }
  return false
}

function setItem (key, value) {
  const item = JSON.stringify(value)
  window.localStorage.setItem(key, item)
}

function setCurrentUser (value) {
  const item = JSON.stringify(value)
  window.localStorage.setItem(userKey, item)
  try {
    // Guardamos JWT en IDB para que sea accedido por service worker
    putCurrentUser(value)
  } catch (error) {
    console.log('An error ocurrend trying to save session data on IDB', error)
  }
}

export {
  getToken,
  getRefreshToken,
  setToken,
  setRefreshToken,
  setItem,
  setCurrentUser,
  getCurrentUser
}
