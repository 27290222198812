import { paginateArray } from '../../utils/arrayHelper'
import { db } from '../database'
import { getPeopleByFilters } from './person.repository'
import { personTypeEnum } from '../../enums/personType.enum'

export const addOrUpdateFamily  = async (familyGroup) => {
  if (!familyGroup) return
  const familyGroupAdded = await db.familyStore.put(familyGroup)
  return familyGroupAdded
}

export const addFamilies  = async (familyGroup) => {
  if (!familyGroup) return
  await db.familyStore.bulkPut(familyGroup)
}

export const getAllFamilies = async (page, itemsPerPage) => {
  const allFamilies = await db.familyStore.toArray()
  return paginateArray(allFamilies, page, itemsPerPage)
}

export const getFamiliesByFilters = async ({ page, itemsPerPage, lastname }) => {
  let families = await getAllFamilies(-1, -1)
  families = families.items
  if (lastname) {
    families = families.filter(family => { return String(family.lastname).toUpperCase().includes(String(lastname).toUpperCase()) })
  }
  return paginateArray(families, page, itemsPerPage)
}

export const getFamilyById = async (id) => {
  const family = await db.familyStore.get({id: Number(id)})
  return family
}

export const addOrUpdateBond  = async (bond) => {
  const bondAdded = await db.bondsStore.put(bond)
  return bondAdded
}

export const addBonds  = async (bonds) => {
  if (!bonds) return
  await db.bondsStore.bulkPut(bonds)
}

export const getBonds = async () => {
  const allBonds = await db.bondsStore.toArray()
  return allBonds
}

export const getBondsByChildId = async (childId) => {
  const bonds = await db.bondsStore.where({ childId }).toArray()
  return bonds
}

// Obtiene los vínculos familiares para mostrar en la sección de grupo familiar
export const getBondsFamilyById = async (familyGroupId) => {
  const people = await getPeopleByFilters({
    page: -1,
    itemsPerPage: -1,
    familyGroupId: Number(familyGroupId)
  })

  const peopleWithBonds = []
  if (!people || !people.totalItems) return peopleWithBonds
  const children = people.items.filter(person => person.personType === personTypeEnum.PEDIATRIC)
  
  // Sobre el listado de niños iteramos para encontrar los vínculos
  for (const person of children) {
    const bonds = await getBondsByChildId(Number(person.id))
    if (bonds && bonds.length) person.bonds = bonds
    peopleWithBonds.push(person)
  }

  // Luego construimos un diccionario tal cual se hace en el backend
  const childDictionary = {}
  for (const item of peopleWithBonds) {
    const child = getSimplePerson(item)
    // Creamos diccionario vacío de relaciones
    child.bonds = {}
    // Agregamos el niño al diccionario
    childDictionary[child.id] = child
    // Cargamos diccionario de relaciones
    item.bonds.forEach(bond => {
      // Tomamos id de familiar
      child.bonds[bond.relative.id] = {}
      child.bonds[bond.relative.id].relative = getSimplePerson(bond.relative)
      child.bonds[bond.relative.id].bond = {
        id: bond.id,
        bond: bond.bond,
        observation: bond.observation
      }
    })
  }
  return childDictionary
}

const getSimplePerson = (item) => {
  return {
    id: item.id,
    firstname: item.firstname,
    lastname: item.lastname,
    familyGroupId: item.familyGroupId,
    familyGroup: item.familyGroup,
    personType: item.personType
  }
}
