/* eslint-disable no-console */

import { register } from 'register-service-worker'

const notifySwEvent = (event) => {
  window.dispatchEvent(new CustomEvent('sw-status', {
    detail: {
      msg: event
    }
  }))
}
const registerPeriodicSync = async () => {
  const registration = await navigator.serviceWorker.ready;
  // Check if periodicSync is supported
  if ('periodicSync' in registration) {
    // Request permission
    const status = await navigator.permissions.query({
      name: 'periodic-background-sync',
    });
    if (status.state === 'granted') {
      try {
        await registration.periodicSync.register('update-cached-data', {
          minInterval: 6 * 60 * 60 * 1000 // Seis horas
        });
        console.log('Periodic background sync registered!');
      } catch(e) {
        console.error(`Periodic background sync failed:\nx${e}`);
      }
    } else {
      console.info('Periodic background sync is not granted.');
    }
  } else {
    console.log('Periodic background sync is not supported.');
  }
}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
      notifySwEvent('sw-active')
      registerPeriodicSync()
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound (registration) {
      console.log('New content is downloading.')
      if (registration.installing) {
        notifySwEvent('sw-installing')
      }
    },
    updated (registration) {
      console.log('New content is available; please refresh.')
      if (registration.waiting) {
        notifySwEvent('sw-waiting')
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
