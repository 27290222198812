import axios from 'axios'

export const state = {
  centerStore: {
    id: null,
    name: null,
    isEnabled: null,
    foundationId: null,
    coordinator: null,
    professionalQuantity: null,
    areasQuantity: null,
    isConinCenter: null,
    m2Built: null,
    m2Total: null,
    m2HortProduction: null,
    addressId: null
  },
  centers: [],
  totalCenters: 0,
  filters: {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    includeDisabled: false,
    name: null
  }
}

export const mutations = {
  'SET_CENTERS' (state, data) {
    state.centers = data.items
    state.totalCenters = data.totalItems
  },
  'SET_CENTER' (state, data) {
    state.centerStore = data
  },
  'ADD_EMPTY_VALUE' (state, data) {
    state.centers.unshift(data)
  }
}

export const actions = {
  addEmptyValue: async ({ commit }) => {
    const emptyCenter = { id: null, name: 'General' }
    commit('ADD_EMPTY_VALUE', emptyCenter)
    return true
  },
  getCentersByFoundationId: async ({ commit }) => {
    return await axios.get('/center/foundation')
      .then((response) => {
        commit('SET_CENTERS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  getByFilters: async ({ commit }) => {
    await axios.get('/center', { params: state.filters })
      .then((response) => {
        commit('SET_CENTERS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  getCenterById: async ({ commit }, id) => {
    return await axios.get('/center/' + id)
      .then((response) => {
        commit('SET_CENTER', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  update: async ({ commit, dispatch }, center) => {
    return await axios.put('/center/' + center.id, center)
      .then(
        (response) => {
          dispatch('getByFilters')
          commit('baseSnackbar/success', { text: 'Centro actualizado con éxito' }, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  create: async ({ commit, dispatch }, center) => {
    return await axios.post('/center', center)
      .then(
        (response) => {
          dispatch('getByFilters')
          commit('baseSnackbar/success', { text: 'Centro creado con éxito' }, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  // Altas y bajas lógicas
  enable: async ({ commit }, params) => {
    return await axios.put('/center/enable/' + params.id, { status: params.status })
      .then(
        (response) => {
          commit('baseSnackbar/success', { text: 'Centro actualizado con éxito' }, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
