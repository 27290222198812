import moment from 'moment'

function ageCalculator (birthday, currentDate) {
  currentDate = currentDate ? moment(currentDate) : moment(new Date())
  const bdate = moment(birthday)
  const diff = currentDate.diff(bdate, 'months')
  const daysDiff = currentDate.diff(bdate, 'days')
  let result = {}
  if (daysDiff > 31) {
    result = {
      number: diff,
      unity: 'Meses'
    }
  } else {
    result = {
      number: daysDiff,
      unity: 'Días'
    }
  }
  result.text = result.number + ' ' + result.unity
  return result
}

function stringAgeCalculator (birthday, currentDate) {
  const ageCalculated = ageCalculator(birthday, currentDate)
  let result = ''
  if (ageCalculated.unity !== 'Meses' || (ageCalculated.number / 12) < 1) {
    result = ageCalculated.number + ' ' + ageCalculated.unity
  } else {
    const years = Math.trunc(ageCalculated.number / 12)
    const months = ageCalculated.number - (years * 12)
    result += years
    result += years === 1 ? ' año ' : ' años'
    if (months !== 0) {
      result += ' y ' + months
      result += months === 1 ? ' mes' : ' meses'
    }
  }
  return result
}

function daysCalculator (dateFrom, dateTo) {
  const df = moment(dateFrom)
  const dt = moment(dateTo)
  return dt.diff(df, 'days')
}

function getDayOfWeek (intDay) {
  var daysOfWeek = [[0, 'Domingo'],
    [1, 'Lunes'],
    [2, 'Martes'],
    [3, 'Miercoles'],
    [4, 'Jueves'],
    [5, 'Viernes'],
    [6, 'Sabado']]
  var daysOfWeekMap = new Map(daysOfWeek)
  return daysOfWeekMap.get(intDay)
}

export {
  ageCalculator,
  daysCalculator,
  getDayOfWeek,
  stringAgeCalculator
}
