import axios from 'axios'

export const state = {
  referralFollowStore: {
  },
  referralFollows: [],
  totalReferralFollows: 0,
  filters: {
    page: 1,
    itemsPerPage: 10
  }
}

export const mutations = {
  'SET_REFERRAL_FOLLOWS' (state, data) {
    state.referralFollows = data.items || []
    state.totalReferralFollows = data.totalItems || 0
  },
  'ADD_REFERRAL_FOLLOWS' (state, data) {
    state.referralFollows = state.referralFollows.concat(data.items)
    state.totalReferralFollows = data.totalItems
  },
  'SET_REFERRAL_FOLLOW' (state, data) {
    state.referralFollowStore = data
  },
  'RESET_FILTERS' (state) {
    state.filters = {
      page: 1,
      itemsPerPage: 10
    }
  }
}

export const actions = {

  getByReferralId: async ({ commit }, { id, isScrolling }) => {
    if (isScrolling && (state.referralFollows.length === state.totalReferralFollows)) { return }
    // Si está scrolleando vamos a agregar el resultado a los seguimientos existentes
    if (isScrolling && (state.referralFollows.length < state.totalReferralFollows)) {
      state.filters.page = Math.ceil(state.referralFollows.length / state.filters.itemsPerPage) + 1
    } else {
      commit('RESET_FILTERS')
    }
    await axios.get(`referral-follow/referral/${id}`, { params: state.filters })
      .then((response) => {
        if (isScrolling) {
          commit('ADD_REFERRAL_FOLLOWS', response.data)
        } else {
          commit('SET_REFERRAL_FOLLOWS', response.data)
        }
        return true
      })
      .catch((error) => {
        commit('SET_REFERRAL_FOLLOWS', [])
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit, dispatch }, referralFollow) => {
    return await axios.post('referral-follow', referralFollow)
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Seguimiento creado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  update: async ({ commit, dispatch }, referralFollow) => {
    return await axios.put(`referral-follow/${referralFollow.id}`, referralFollow)
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Seguimiento actualizado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getReferralById: async ({ commit }, id) => {
    return await axios.get('referral-follow/' + id)
      .then((response) => {
        commit('SET_REFERRAL_FOLLOW', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }

}
