<template>
  <v-dialog
    v-model="showDialog"
    max-width="fit-content"
    persistent
    transition="dialog-bottom-transition"
    @keyup.enter.capture="submit"
  >
    <v-card :elevation="4">
      <img
        class="installation-prompt-logo"
        :src="getAsset('Cuadrado.webp')"
      >
      <v-card-text class="installation-prompt-text">
        <h2
          justify-center
          class="white--text text-center mt-5"
        >
          ¡Hay una versión disponible para instalar en tu dispositivo!
        </h2>
        <h4 class="skip-installation-text text-center mt-5 mb-5">
          Te recomendamos que instales la aplicación para una mejor experiencia.
        </h4>
        <v-btn
          block
          class="installation-prompt-button"
          color="orange"
          large
          @click="install"
        >
          Instalar aplicación
        </v-btn>

        <div class="skip-installation justify-center">
          <v-btn
            text
            class="skip-installation-text"
            @click="skipInstall"
          >
            No instalar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getAsset } from '../../utils/assetsHelper'
import { putInstallationSkipped, getInstallationSkipped } from '../../workbox/repository/session.repository'
export default {
  data () {
    return {
      deferredPrompt: null
    }
  },
  computed: {
    showDialog () {
      return this.deferredPrompt || false
    }
  },
  async beforeMount () {
    const installationSkipped = await getInstallationSkipped()
    if (installationSkipped) return
    const that = this
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault()
      // Save the event because you'll need to trigger it later.
      that.deferredPrompt = e
      return false
    })
  },
  methods: {
    async install () {
      if (!this.deferredPrompt) {
        return
      }
      // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
      this.deferredPrompt.prompt()
      // The deferredPrompt can only be used once.
      this.deferredPrompt = null
    },
    async skipInstall () {
      await putInstallationSkipped(true)
      this.deferredPrompt = null
    },
    getAsset
  }
}
</script>

<style scoped>

.installation-prompt-logo {
  display: flex;
  height: 100px;
  width: 100px;
  padding: 10px;
  margin-top: 10px;
}

.v-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 23.5rem;
  min-height: 26.6rem;
  background: rgba(22, 22, 22, 0.856);
  backdrop-filter: blur(30px);
}

.installation-prompt-text {
  padding-top: 0.5rem;
}

.installation-prompt-button {
  margin-top: 30px;
}

.v-dialog {
  max-width: 23.5rem;
  max-height: fit-content !important;
}

.skip-installation {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  width: 100%;
}

.skip-installation-text {
  font-size: 14px;
  color: #6e6e6e;
  text-decoration: none;
}

.installation-prompt {
  background-color: transparent !important;
}

</style>
