import axios from 'axios'

export const state = {
  institutionStore: {
    id: null,
    name: null,
    type: null,
    townId: null
  },
  institutions: [],
  totalInstitutions: 0,
  filters: {
    page: 1,
    itemsPerPage: 10,
    includeDisabled: false,
    name: null,
    type: null,
    townId: null
  }
}

export const mutations = {
  'SET_INSTITUTIONS' (state, data) {
    state.institutions = data.items
    state.totalInstitutions = data.totalItems
  },
  'SET_INSTITUTION' (state, data) {
    state.institutionStore = data
  }
}

export const actions = {

  getByFilters: async ({ commit }) => {
    await axios.get('institution', { params: state.filters })
      .then((response) => {
        commit('SET_INSTITUTIONS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit, dispatch }, institution) => {
    return await axios.post('institution', institution)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Municipio creada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  update: async ({ commit, dispatch }, institution) => {
    return await axios.put(`institution/${institution.id}`, institution)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Municipio actualizada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getInstitutionById: async ({ commit }, id) => {
    return await axios.get('institution/' + id)
      .then((response) => {
        commit('SET_INSTITUTION', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }

}
