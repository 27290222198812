import axios from 'axios'

export const state = {
  userStore: {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    area: null,
    center: null,
    professionalRegistration: null,
    isEnabled: null,
    centerAreas: null
  },
  users: [],
  totalUsers: 0,
  filters: {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    includeDisabled: false,
    firstname: '',
    startDate: null,
    endDate: null
  },
  userPermissionsStore: []
}

export const mutations = {
  'SET_USERS' (state, data) {
    state.users = data.items
    state.totalUsers = data.totalItems
  },
  'SET_USER' (state, data) {
    state.userStore = data
  },
  'SET_USER_PERMISSIONS' (state, data) {
    state.userPermissionsStore = data
  }
}

export const actions = {

  getByFilters: async ({ commit }) => {
    await axios.get('user', { params: state.filters })
      .then((response) => {
        commit('SET_USERS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit, dispatch }, user) => {
    return await axios.post('user', user)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Usuario creado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  update: async ({ commit, dispatch }, user) => {
    return await axios.put('user', user)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Usuario actualizado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getUserById: async ({ commit }, id) => {
    return await axios.get('user/' + id)
      .then((response) => {
        commit('SET_USER', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  disableUser: async ({ commit }, params) => {
    return await axios.put('user/disable/' + params.id, { status: params.status })
      .then(
        (response) => {
          commit('baseSnackbar/success', { text: `Usuario ${params.status === 1 ? 'activado' : 'desactivado'} con éxito` }, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getUserPermission: async ({ commit }, id) => {
    return await axios.get('user/' + id + '/permission')
      .then((response) => {
        commit('SET_USER_PERMISSIONS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  createUserPermission: async ({ commit, dispatch }, permission) => {
    return await axios.post('user/permission', permission)
      .then(() => {
        dispatch('getUserPermission', permission.userId)
        commit('baseSnackbar/success', { text: 'Permiso creado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  disableUserPermission: async ({ commit }, id) => {
    return await axios.put('user/permission/logical-enable/' + id)
      .then(
        (response) => {
          commit('baseSnackbar/success', { text: 'Permiso eliminado' }, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getAccessForUser: async ({ commit }) => {
    return await axios.get('user/access')
      .then(
        (response) => {
          commit('SET_USER', response.data)
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }

}
