import axios from 'axios'

export const state = {
  familyBondsStore: {}
}
export const mutations = {
  'SET_FAMILY_BONDS' (state, data) {
    state.familyBondsStore = data
  }
}
export const actions = {
  getBondsByFamilyGroupId: async ({ commit }, id) => {
    return await axios.get('family-bond/family-group/' + id)
      .then((response) => {
        commit('SET_FAMILY_BONDS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  createAndUpdate: async ({ commit }, bonds) => {
    return await axios.post('family-bond/', bonds)
      .then((response) => {
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
