<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <component
      :is="layout"
      v-if="$router.currentRoute.name != 'Login'
        && currentUser != null
        && $router.currentRoute.path != '/change-password'
        && appReady"
    />

    <v-container
      v-if="appReady"
      class="d-flex"
    >
      <v-main class="main-container">
        <v-scroll-y-transition
          class="cover-height"
          :hide-on-leave="true"
        >
          <router-view
            class="cover-height"
            :layout.sync="selectedLayout"
          />
        </v-scroll-y-transition>
      </v-main>
    </v-container>
    <BaseLoadingPage v-if="!appReady" />
    <BaseSnackbar />
    <BaseLoading />
    <BaseConfirm />
    <BaseInstallPrompt />
    <BaseUpdatePrompt
      ref="updatePrompt"
      @updateSkipped="updateSkipped"
    />
  </v-app>
</template>

<script>
import BaseSnackbar from './components/BaseSnackbar.vue'
import BaseLoading from './components/BaseLoading.vue'
import BaseConfirm from './components/BaseConfirm.vue'
import { authComputed } from './state/helpers'
import { mapState } from 'vuex'
import BaseLoadingPage from './components/Layout/BaseLoadingPage.vue'
import BaseInstallPrompt from './components/PWA/BaseInstallPrompt.vue'
import BaseUpdatePrompt from './components/PWA/BaseUpdatePrompt.vue'

const defaultLayout = 'navbar-layout'

export default {
  name: 'App',
  components: {
    BaseSnackbar,
    BaseLoading,
    BaseConfirm,
    BaseLoadingPage,
    BaseInstallPrompt,
    BaseUpdatePrompt
},
  data: () => ({
    selectedLayout: null,
    swActive: false,
    swWaiting: false,
    appReady: false
  }),
  computed: {
    ...authComputed,
    ...mapState('auth', ['currentUser']),
    layout () {
      return this.selectedLayout || this.$route.meta.layout || defaultLayout
    },
    theme () {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    }
  },
  watch: {
    swActive () {
      this.appReady = this.swActive
    },
    swWaiting () {
      if (this.swWaiting) {
        this.$refs.updatePrompt && this.$refs.updatePrompt.open()
      }
    }
  },
  beforeMount () {
    window.addEventListener('sw-status', this.updateSwStatus)
  },
  methods: {
    updateSwStatus(event) {
      if (event.detail.msg === 'sw-active') {
        this.swActive = true
      } else if (event.detail.msg === 'sw-waiting') {
        this.swActive = false
        this.swWaiting = true
      } else if (event.detail.msg === 'sw-installing') {
        this.swActive = false
      }
    },
    updateSkipped () {
      this.swActive = true
      this.swWaiting = false
    }
  }
}
</script>

<style scoped>
  .container {
    height: auto;
    max-width: inherit;
    padding: 0 !important;
  }
</style>

<!--- Estilos globales -->
<style>
  .bg-green {
    background-color: #bdecb6 !important;
  }
  .bg-yellow {
    background-color: #efa94a !important;
  }
  .bg-red {
    background-color: #DB8163 !important;
  }
  .bg-white {
    background-color: #D7D7D7 !important;
  }
  .bg-grey {
    background-color: #D7D7D7 !important;
  }
  .main-container {
    min-height: 100vh;
  }
  .cover-height {
    height: 100%
  }
  .container {
    background-color: #f7f7f8;
  }
</style>
