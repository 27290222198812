import axios from 'axios'
import moment from 'moment'
import { personTypeEnum } from '../../enums/personType.enum'
export const state = {
  attendances: [],
  totalattendances: 0,
  filters: {
    page: 1,
    itemsPerPage: -1,
    includeManuallyAdded: true,
    date: moment().format('YYYY-MM-DD'),
    batchId: null,
    personType: personTypeEnum.PEDIATRIC,
    states: []
  },
  attendanceStore: null,
  officeFilters: {},
  officeAttendances: [],
  officeAttended: []
}

export const mutations = {
  'SET_ATTENDANCE_STORE' (state, data) {
    state.attendanceStore = data
  },
  'SET_ATTENDANCES' (state, data) {
    state.attendances = data.items
    state.totalattendances = data.totalItems
  },
  'cleanState' (state) {
    state.filters = {
      page: 1,
      itemsPerPage: 10,
      includeManuallyAdded: true,
      date: moment().format('YYYY-MM-DD'),
      personType: personTypeEnum.PEDIATRIC,
      states: []
    }
    state.attendances = []
    state.totalattendances = 0
    state.attendanceStore = {}
  },
  'ADD_ATTENDANCE' (state, data) {
    state.attendances = state.follows.concat(data.items)
    state.totalattendances = data.totalattendances
  },
  'SET_FOLLOW' (state, data) {
    state.attendanceStore = data
  },
  'SET_OFFICE_ATTENDANCES' (state, data) {
    state.officeAttendances = data
  },
  'SET_OFFICE_ATTENDED' (state, data) {
    state.officeAttended = data
  },
  'setFilters' (state, data) {
    state.filters = data
  }
}

// export const getters = {
// }

export const actions = {
  getByFilters: async ({ commit }) => {
    await axios.get('/attendance', { params: state.filters })
      .then((response) => {
        commit('SET_ATTENDANCES', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  generateAttendances: async ({ commit, dispatch }) => {
    await axios.get('/attendance/generate-attendances', { params: state.filters })
      .then((response) => {
        commit('SET_ATTENDANCES', response.data)
        // Actualizamos estado de sincronización
        dispatch('sync/getNotSyncRegisters', {}, { root: true })
        return true
      })
      .catch((error) => {
        commit('SET_ATTENDANCES', { items: [], totalItems: 0 })
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  create: async ({ commit, dispatch }, attendance) => {
    return await axios.post('/attendance', attendance)
      .then(
        (response) => {
          dispatch('getByFilters')
          commit('baseSnackbar/success', { text: 'Asistencia creada' }, { root: true })
          // Actualizamos estado de sincronización
          dispatch('sync/getNotSyncRegisters', {}, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  syncAttendance: async ({ commit, dispatch }, attendance) => {
    return await axios.post('/attendance/sync', attendance)
      .then(
        (response) => {
          dispatch('getByFilters')
          commit('baseSnackbar/success', { text: 'Asistencia creada' }, { root: true })
          // Actualizamos estado de sincronización
          dispatch('sync/getNotSyncRegisters', {}, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  changeStatus: async ({ commit, dispatch }, params) => {
    return await axios.put('/attendance/change-status/' + params.id, { status: params.status })
      .then(
        (response) => {
          commit('baseSnackbar/success', { text: 'Asistencia actualizada' }, { root: true })
          // Actualizamos estado de sincronización
          dispatch('sync/getNotSyncRegisters', {}, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  delete: async ({ commit }, params) => {
    return await axios.delete('attendance/' + params.id)
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Asistencia eliminada' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  getOfficeAttendances: async ({ commit }) => {
    const params = {
      communityId: state.officeFilters.communityId,
      date: state.officeFilters.date
    }
    await axios.get('/attendance/office/for-attendance', { params })
      .then((response) => {
        commit('SET_OFFICE_ATTENDANCES', response.data)
        return true
      })
      .catch((error) => {
        commit('SET_OFFICE_ATTENDANCES', [])
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  getOfficeAttended: async ({ commit }) => {
    const params = {
      communityId: state.officeFilters.communityId,
      date: state.officeFilters.date
    }
    await axios.get('/attendance/office/attended', { params })
      .then((response) => {
        commit('SET_OFFICE_ATTENDED', response.data)
        return true
      })
      .catch((error) => {
        commit('SET_OFFICE_ATTENDED', [])
        if (error.response.status === 500) {
          commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        }
        return false
      })
  }
}
