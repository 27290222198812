import axios from 'axios'

export const state = {
  perinatalHistoryStore: {
    clinicalHistoryId: null,
    id: null,
    controlledPregnancy: null,
    numberOfControls: null,
    ultrasounds: null,
    numberOfUltrasounds: null,
    trimester: null,
    result: null,
    serology: null,
    infections: null,
    trauma: null,
    toxicHabits: null,
    dbt: null,
    hta: null,
    app: null,
    others: null,
    previousPregnancies: null,
    problems: null,
    abortions: null,
    stillbirths: null,
    perinatalPathology: null,
    typeOfChildbirth: null,
    typeOfChildbirthCause: null,
    prn: null,
    trn: null,
    pcrn: null,
    eg: null,
    apgar: null
  }
}

export const mutations = {
  setPerinatalHistoryData (state, data) {
    state.perinatalHistoryStore = data
  }
}

export const actions = {
  getPerinatalHistoryByClinicalHistoryId: async ({ commit }, id) => {
    return await axios.get('/clinicalHistory/perinatal-history/' + id)
      .then((response) => {
        commit('setPerinatalHistoryData', response.data)
        return response.data
      })
      .catch((error) => {
        commit('setPerinatalHistoryData', {})
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  createPerinatalHistory: async ({ commit, state }, perinatalHistory) => {
    return await axios.post('clinicalHistory/perinatal-history', perinatalHistory)
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Datos creados con éxito' }, { root: true })
        return response.data.id
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  updatePerinatalHistory: async ({ commit, state }, perinatalHistory) => {
    return await axios.put('clinicalHistory/perinatal-history', perinatalHistory)
      .then(() => {
        commit('baseSnackbar/success', { text: 'Datos actualizados con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
