<template>
  <div>
    <v-badge
      inline
      color="primary"
      :content="String(notSyncAndErrorsRegistersCount)"
      :value="notSyncAndErrorsRegistersCount"
    >
      <v-tooltip
        bottom
      >
        <template #activator="{ on }">
          <v-icon
            class="pr-1 pl-1"
            :color="syncStatus.color"
            style="font-size: 35px;"
            v-on="on"
            @click="moveToSyncRoute"
          >
            {{ syncStatus.icon }}
          </v-icon>
        </template>
        <span>{{ syncStatus.text }}</span>
      </v-tooltip>
    </v-badge>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapMutations, mapState } from 'vuex'
import { syncProcessStatuses } from '../../enums/sync.enum'
import { sessionNextToExpire } from '../../utils/sessionHelper'
export default {
  data () {
    return {
    }
  },
  computed: {
    tokenNextToExpire () {
      if (!this.currentUser) return false
      return sessionNextToExpire(this.currentUser.expiration)
    },
    showUpdateAlert () {
      if (!this.lastUpdate) return true
      return moment().diff(moment(this.lastUpdate), 'hours') > 18 || this.tokenNextToExpire
    },
    syncStatus () {
      let text, icon, color
      if (this.syncErrorsCount || this.syncProcessStatus === syncProcessStatuses.ERROR) {
        text = 'Ocurrió un error al sincronizar'
        icon = 'mdi-sync-alert'
        color = 'red'
      } else if (this.notSyncRegistersCount === 0) {
        if (this.showUpdateAlert) {
          text = 'Refrescar datos'
          icon = 'mdi-sync-alert'
          color = 'yellow'
        } else {
          text = 'Todo sincronizado'
          icon = 'mdi-check-circle-outline'
          color = 'green'
        }
      } else {
        if (this.syncProcessStatus === syncProcessStatuses.IN_PROGRESS) {
          text = 'Sincronizando'
          icon = 'mdi-sync'
          color = 'yellow'
        }
        else if (this.notSyncRegistersCount || this.syncProcessStatus === syncProcessStatuses.WAITING_FOR_SYNC) {
          text = 'Hay registros pendientes de sincronizar'
          icon = 'mdi-sync-off'
          color = 'yellow'
        } else {
          text = 'Refrescar datos'
          icon = 'mdi-sync-alert'
          color = 'yellow'
        }
      }
      return { text, icon, color }
    },
    notSyncAndErrorsRegistersCount () {
      return this.notSyncRegistersCount + this.syncErrorsCount
    },
    ...mapState('auth', ['currentUser']),
    ...mapState('sync', ['notSyncRegistersCount','syncErrorsCount', 'syncProcessStatus', 'lastUpdate'])
  },
  async beforeMount () {
    await this.getNotSyncRegisters()
    await this.getAllSyncErrors()
    await this.getSyncErrorsCount()
    await this.getLastUpdate()
  },
  mounted () {
    navigator.serviceWorker.addEventListener('message', async (event) => {
      if (event.data.msg === 'sync-updated') {
        await this.getNotSyncRegisters()
        await this.getSyncErrorsCount()
        await this.getAllSyncErrors()
      }
    })
  },
  methods: {
    moveToSyncRoute () {
      this.$router.push('/sync-status')
    },
    ...mapMutations('sync', ['SET_SYNC_PROCESS_STATUS']),
    ...mapActions('sync', ['getSyncErrorsCount', 'getNotSyncRegisters', 'getAllSyncErrors', 'getLastUpdate'])
  }
}
</script>
