import axios from 'axios'
import router from '../../router'
import {
  setCurrentUser,
  getCurrentUser
} from '../../utils/localStorageService'
import { deleteCurrentUser } from '../../workbox/repository/session.repository'
import { clearDatabase } from '../../workbox/repository/sync.repository'
import store from '../store'

export const state = {
  currentUser: getCurrentUser()
}

export const mutations = {
  SET_CURRENT_USER (state, user) {
    state.currentUser = user
    setCurrentUser(user)
  }
}

export const getters = {
  loggedIn (state) {
    if (state.currentUser) {
      return true
    }
    return false
  },
  centerId (state) {
    if (state.currentUser) {
      return state.currentUser.centerId
    }
  },
  userArea (state) {
    if (state.currentUser) {
      return state.currentUser.area
    }
  },
  foundationId (state) {
    if (state.currentUser) {
      return state.currentUser.foundationId
    }
  },
  id (state) {
    if (state.currentUser) {
      return state.currentUser.id
    }
  }
}

export const actions = {
  // init ({ dispatch }) {
  //   dispatch('validate')
  // },

  async logIn ({ commit }, authCredentials) {
    return axios
      .post('auth/login', authCredentials)
      .then(response => {
        const user = response.data
        if (user.token) {
          commit('SET_CURRENT_USER', user)
          commit(
            'baseSnackbar/success', { text: 'Usuario autenticado' }, { root: true }
          )
          store.dispatch('permission/getMenu')
        }
        return user
      })
      .catch(error => {
        commit(
          'baseSnackbar/alert',
          {
            text: error.response.data && error.response.data.message,
            code: error.response.status
          },
          { root: true }
        )
        return false
      })
  },

  async changeAccess ({ commit }, accessData) {
    return axios
      .post('auth/login/change', {
        center: accessData.center === 'General' ? null : accessData.center,
        area: accessData.area
      })
      .then(response => {
        const user = response.data
        if (user.token) {
          commit('SET_CURRENT_USER', user)
          store.dispatch('permission/getMenu')
        }
        return response
      })
      .catch(error => {
        commit(
          'baseSnackbar/alert',
          {
            text: error.response.data && error.response.data.message,
            code: error.response.data.status
          },
          { root: true }
        )
        return false
      })
  },

  async resetPassword ({ commit }, user) {
    const token = router.currentRoute.params.token
    return await axios.post(`auth/reset/password/${token}`, user)
      .then(response => {
        router.push('/login')
        commit('baseSnackbar/success', { text: 'Contraseña actualizada con éxito' }, { root: true })
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  async changePassword ({ commit }, user) {
    return await axios.post('auth/change/password/', user)
      .then(response => {
        router.push('/login')
        commit('baseSnackbar/success', { text: 'Solicitud de cambio exitosa' }, { root: true })
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  async logOut ({ commit }, withReload) {
    await clearDatabase()
    commit('SET_CURRENT_USER', null)
    await deleteCurrentUser()
    router.push('/login')
    if (withReload) {
      location.reload()
    }
  },

  async validate ({ commit }) {
    return axios
      .get('auth/validate/')
      .then(res => {
        return true
      })
      .catch(() => {
        commit('SET_CURRENT_USER', null)
        return false
      })
  }

}
