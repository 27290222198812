import { db } from "../database"

export const addOrUpdateBatch  = async (batch) => {
  if (!batch) return
  const batchAdded = await db.batchStore.put(batch)
  return batchAdded
}

export const addBatches  = async (batches) => {
  if (!batches) return
  await db.batchStore.bulkPut(batches)
}

// Buscador de lotes por id de producto
export const getBatchesByProductId = async(productId) => {
  const batches = await db.batchStore
    .where({productId})
    .toArray()
  return batches
}

export const getBatchById = async (id) => {
  const batch = await db.batchStore.get({id: Number(id)})
  return batch
}
