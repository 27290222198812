import axios from 'axios'
import moment from 'moment'

export const state = {
  filters: {
    toDate: moment().format('YYYY-MM-DD'),
    fromDate: moment().startOf('month').format('YYYY-MM-DD')
  },
  reportData: {},
  inProgress: {},
  totalDiagnosis: 0,
  follows: {},
  peopleAttended: [],
  attendances: {},
  entries: {},
  exits: {},
  workshops: {},
  milk: {}
}
export const mutations = {
  'SET_IN_PROGRESS'(state, data) {
    state.inProgress = data
  },
  'SET_TOTAL_DIAGNOSIS'(state, data) {
    state.totalDiagnosis = data?.count || 0
  },
  'SET_FOLLOWS'(state, data) {
    state.follows = data
  },
  'SET_PEOPLE_ATTENDED'(state, data) {
    state.peopleAttended = data
  },
  'SET_ATTENDANCES'(state, data) {
    state.attendances = data
  },
  'SET_ENTRIES_EXITS'(state, data) {
    state.entries = data?.entries
    state.exits = data?.exits
  },
  'SET_WORKSHOPS'(state, data) {
    state.workshops = data
  },
  'SET_MILK'(state, data) {
    state.milk = data
  },
}

export const actions = {
  getInProgress: async ({ commit }) => {
    return await axios
      .get('/clinicalHistory/monthly-report', { params: state.filters })
      .then((response) => {
        commit('SET_IN_PROGRESS', response.data)
        return true
      })
      .catch(() => {
        return false
      })
  },
  getTotalDiagnosis: async ({ commit }) => {
    return await axios
      .get('/follow-up/monthly-report/diagnosis', { params: state.filters })
      .then((response) => {
        commit('SET_TOTAL_DIAGNOSIS', response.data)
        return true
      })
      .catch(() => {
        return false
      })
  },
  getFollowsCount: async ({ commit }) => {
    return await axios
      .get('/follow-up/monthly-report', { params: state.filters })
      .then((response) => {
        commit('SET_FOLLOWS', response.data)
        return true
      })
      .catch(() => {
        return false
      })
  },
  getPeopleAttendedCount: async ({ commit }) => {
    return await axios
      .get('/follow-up/total-people', { params: state.filters })
      .then((response) => {
        commit('SET_PEOPLE_ATTENDED', response.data)
        return true
      })
      .catch(() => {
        return false
      })
  },
  getAttendanceCount: async ({ commit }) => {
    return await axios
      .get('/attendance/monthly-report', { params: state.filters })
      .then((response) => {
        commit('SET_ATTENDANCES', response.data)
        return true
      })
      .catch(() => {
        return false
      })
  },
  getHcTransitions: async ({ commit }) => {
    return await axios
      .get('/hc-transition/monthly-report', { params: state.filters })
      .then((response) => {
        commit('SET_ENTRIES_EXITS', response.data)
        return true
      })
      .catch(() => {
        return false
      })
  },
  getWorkshops: async ({ commit }) => {
    return await axios
      .get('/workshop-schedule/monthly-report', { params: state.filters })
      .then((response) => {
        commit('SET_WORKSHOPS', response.data)
        return true
      })
      .catch(() => {
        return false
      })
  },
  getMilk: async ({ commit }) => {
    return await axios
      .get('/batch/monthly-report', { params: state.filters })
      .then((response) => {
        commit('SET_MILK', response.data)
        return true
      })
      .catch(() => {
        return false
      })
  }
}
