<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="fit-content"
      class="dialog"
      persistent
    >
      <v-card :elevation="4">
        <img
          class="logo"
          :src="getAsset('Cuadrado.webp')"
        >
        <v-card-text>
          <v-form
            ref="loginForm"
            block
            class="login-form"
          >
            <!-- Centro -->
            <v-select
              v-model="accessData.center"
              dark
              label="Centro"
              :items="centers"
              item-text="name"
              item-value="id"
            />
            <!-- Area -->
            <v-select
              v-if="accessData.center"
              v-model="accessData.area"
              dark
              label="Area"
              :items="centerAreasDictionary[accessData.center].areas"
              item-text="name"
              item-value="id"
            />
            <v-btn
              block
              class="login-button"
              color="secondary"
              large
              @click="submit"
            >
              Cambiar acceso
            </v-btn>
            <div class="cancel-text">
              <v-btn
                text
                class="login-button"
                color="secondary"
                @click="cancel"
              >
                Cancelar
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getAsset } from '../../utils/assetsHelper'
export default {
  data () {
    return {
      dialog: false,
      centers: [],
      centerAreasDictionary: {},
      accessData: {}
    }
  },
  computed: {
    ...mapState('user', ['userStore'])
  },
  methods: {
    getAsset,
    ...mapActions('auth', ['changeAccess']),
    async openForm () {
      this.dialog = true
      this.setCenterDictionary(this.userStore.centerAreas)
    },
    setCenterDictionary (centerAreas) {
      centerAreas.forEach(centerArea => {
        if (this.centerAreasDictionary[centerArea.center.id]) {
          this.centerAreasDictionary[centerArea.center.id].areas.push(centerArea.area)
        } else {
          this.centers.push(centerArea.center)
          this.centerAreasDictionary[centerArea.center.id] = centerArea.center
          this.centerAreasDictionary[centerArea.center.id].areas = [centerArea.area]
        }
      })
    },
    submit () {
      this.changeAccess(this.accessData).then(async (success) => {
        if (success) {
          const user = success.data
          if (user.area.onlineRequired) {
            this.$router.push('/home')
            location.reload()
            return
          }
          await this.$router.push('/precaching')
          location.reload()
        }
      })
    },
    cancel () {
      this.dialog = false
    }
  }
}
</script>
<style lang="css" scoped>
  .logo {
    display: flex;
    height: 100px;
    width: 100px;
    padding: 10px;
    margin-top: 10px;
  }
  .v-card {
    width: 23.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: rgba(22, 22, 22, 0.856);
    backdrop-filter: blur(30px);
  }
  .login-button {
    margin-top: 30px;
  }
  .right {
    align-self: flex-end !important;
  }
  .cancel-text{
    display: flex;
    justify-content: center;
  }
</style>
