import { CONNECTION_ERROR_MESSAGE } from "../../utils/constants";
import { unlockByPersonId } from "../repository/clinicalHistory.repository";
import { removePersonByRequestHash, addFailedSyncRequest, getPersonByRequestHash } from "../repository/person.repository";


export const personOnSyncHandler = async (entry) => {
  const requestHash = entry.request.headers.get('request-hash')
  try {
    // Debemos clonar la solicitud para que en caso de que haya error, la solicitud original
    // siga almacenada y permita ser accedida
    const clonedRequest = entry.request.clone()
    const response = await fetch(clonedRequest);
    const body = await response.json()
    if (response.status >= 400) throw body // Si da error rechazamos
    // Si se pudo guardar procedemos a actualizar el store local
    const newPersonId = body.id
    const oldPerson = await getPersonByRequestHash(requestHash)
    // Primero eliminamos la persona creada localmente
    await removePersonByRequestHash(requestHash)
    // Y actualizamos los datos del legajo (en caso de que haya sido creado)
    await unlockByPersonId(oldPerson.id, newPersonId, body)
  } catch (error) {
    await addFailedSyncRequest(requestHash, error)
    await removePersonByRequestHash(requestHash)
    console.log('ERR: An error ocurred trying to sync person', error)
    throw error;
  }
}

export const forcePersonSyncError = async (notSyncPerson) => {
  await addFailedSyncRequest(notSyncPerson.requestHash, CONNECTION_ERROR_MESSAGE)
  await removePersonByRequestHash(notSyncPerson.requestHash)
}
