import { paginateArray } from "../../utils/arrayHelper"
import { db } from "../database"

export const addOrUpdateProduct  = async (product) => {
  if (!product) return
  const productAdded = await db.productStore.put(product)
  return productAdded
}

export const addProducts  = async (products) => {
  if (!products) return
  await db.productStore.bulkPut(products)
}

export const getAllProducts = async () => {
  const allProducts = await db.productStore.toArray()
  return allProducts
}

// Buscador de productos para entrega a pacientes
export const getProductsBySearchValue = async(value) => {
  const products = await getAllProducts()
  const matchedProducts = products.filter(product => {
    return product.title.toUpperCase().includes(value.toUpperCase())
  })
  return paginateArray(matchedProducts)
}

export const getProductById = async (id) => {
  const product = await db.productStore.get({id: Number(id)})
  return product
}
