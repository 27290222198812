/**
 * Archivo para la configuracion general de las validaciones de la aplicacion
 */
/* eslint-disable */
import { required, email, max, min, required_if, numeric } from 'vee-validate/dist/rules'
import moment from 'moment'
import { extend } from 'vee-validate'

extend('required', {
  ...required,
  message: 'Requerido'
})

extend('min', {
  ...min,
  message: '{length} caracteres mínimo'
})

extend('email', {
  ...email,
  message: '{_field_} debe ser válido'
})

extend('max', {
  ...max,
  message: '{_field_} {length} caracteres máximos'
})

extend('required_if', {
  ...required_if,
  message: '{_field_} es requerido'
})

extend('numeric', {
  ...numeric,
  message: '{_field_} debe ser numérico'
})

extend('cuil', {
  validate (cuil) {
    return /\b(20|23|24|27|30|33|34)(-)[0-9]{8}(-)?[0-9]/g.test(cuil)
  },
  message: 'Formato de CUIL incorrecto'
})

extend('person_sex', {
  params: ['target'],
  validate (value, { target }) {
    return !((value === 'Masculino' && target === 'Paciente obstetricia') || (value === 'Masculino' && target === 'Madre') || (value === 'Femenino' && target === 'Padre'))
  },
  message: 'El sexo debe coincidir con el tipo de persona'
})

extend('center_area', {
  params: ['target'],
  validate (value, { target }) {
    return !(value === 0 && (target === 'Coordinación' || target === 'Nutrición' || target === 'Pediatría' || target === 'Psicopedagogía' || target === 'Atención Temprana' || target === 'Trabajo Social' || target === 'Fonoaudiología') )
  },
  message: 'Centro es un dato obligatorio para el área del usuario'
})

extend('date_after', {
  params: ['target'],
  validate (value, { target }) {
    if (moment(value).isAfter(moment(target))) {
      return true
    }
  },
  message: 'La fecha debe ser posterior'
})

extend('date_after_or_same', {
  params: ['target'],
  validate (value, { target }) {
    if (moment(value).isSameOrAfter(moment(target))) {
      return true
    }
  },
  message: 'La fecha debe ser posterior'
})

extend('date_before_or_same', {
  params: ['target'],
  validate (value, { target }) {
    if (moment(value).isSameOrBefore(moment(target))) {
      return true
    }
  },
  message: 'La fecha debe ser anterior'
})

// TODO: revisar esta validación
extend('date_diff_week', {
  params: ['target'],
  validate (value, { target }) {
    if (moment(value).diff(moment(target)) > 7) {
      return true
    }
  },
  message: 'La diferencia entre fechas no puede superar los 7 días'
})
