import axios from 'axios'
import router from '../router/index'
import { getToken, getRefreshToken, setToken, setCurrentUser, setRefreshToken } from './localStorageService'
import { getWebServiceUrl } from './urlHelper'
import httpStatus from 'http-status'
import hash from 'object-hash'
import { deleteCurrentUser } from '../workbox/repository/session.repository'
import { clearDatabase } from '../workbox/repository/sync.repository'

export default () => {
  // Axios default
  axios.defaults.baseURL = `${getWebServiceUrl()}/api/`
  // Axios interceptors
  axios.interceptors.request.use(
    config => {
      const token = getToken()
      if (token) {
        config.headers.Authorization = 'Bearer ' + token
      }
      const data = config.data
      if (data && data.constructor && data.constructor.name != "FormData") {
        // Si enviamos datos en la solicitud generamos un hash
        const requestHash = hash(data)
        config.headers['request-hash'] = requestHash
      }
      return config
    },
    error => {
      Promise.reject(error)
    })

  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      // TODO: Corregir refresh. No está funcionando
      return new Promise((resolve, reject) => {
        const originalRequest = error.config

        if (error.response.status === httpStatus.UNAUTHORIZED && !originalRequest._retry) {
          originalRequest._retry = true
          const refreshToken = getRefreshToken()
          if (refreshToken) {
            axios.post('auth/refresh', { refreshToken: refreshToken })
              .then(response => {
                const { token, refreshToken } = response.data
                setToken(token)
                setRefreshToken(refreshToken)
                axios.defaults.headers.common.Authorization = 'Bearer ' + getToken()
                axios(originalRequest).then(resolve, reject)
              }).catch(async error => {
                reject(error)
                await redirectToLogin()
              })
          } else {
            redirectToLogin().finally(() => {
              reject(error)
            })
          }
        } else {
          reject(error)
        }
      })
    })
}

const redirectToLogin = async () => {
  if (router.name !== 'Login') {
    await clearDatabase()
    await setCurrentUser(null)
    await deleteCurrentUser()
    router.push('/login')
  }
}
