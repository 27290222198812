import axios from 'axios'

const getInternalReferralsByFilters = async (filters) => {
  return await axios.get('/internal-referral', { params: filters }).then((response) => {
    return response.data
  }).catch(() => {
    return []
  })
}

const getReferralsByClinicalHistoryId = async (clinicalHistoryId) => {
  return await axios.get(`/internal-referral/clinicalHistory/${clinicalHistoryId}`).then((response) => {
    return response.data
  }).catch(() => {
    return false
  })
}

const createInternalReferral = async (internalReferral) => {
  return await axios.post('/internal-referral', internalReferral)
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}

const setReadInternalReferral = async (internalReferralId, isRead) => {
  return await axios.put(`/internal-referral/read/${internalReferralId}`, { isRead })
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}


const getInternalReferralById = async (id) => {
  return await axios.get(`/internal-referral/${id}`).then((response) => {
    return response.data
  }).catch(() => {
    return false
  })
}

const getReferralsCountByClinicalHistoryId = async (clinicalHistoryId) => {
  return await axios.get(`/internal-referral/clinicalHistory/${clinicalHistoryId}/count`).then((response) => {
    return response.data
  }).catch(() => {
    return false
  })
}

export { getInternalReferralsByFilters, getReferralsByClinicalHistoryId, createInternalReferral, setReadInternalReferral, getInternalReferralById, getReferralsCountByClinicalHistoryId }
