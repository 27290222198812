<template>
  <v-dialog
    v-model="showDialog"
    max-width="fit-content"
    persistent
    transition="dialog-bottom-transition"
    @keyup.enter.capture="submit"
  >
    <v-card :elevation="4">
      <v-icon
        color="orange"
        class="update-version-logo"
      >
        mdi-cellphone-arrow-down
      </v-icon>
      <v-card-text class="update-version-text">
        <h2
          justify-center
          class="white--text text-center mt-5"
        >
          ¡Hay una nueva versión disponible!
        </h2>
        <h4 class="skip-update-text text-center mt-5 mb-5">
          {{ subtitleText }}
        </h4>
        <v-btn
          block
          class="update-version-button"
          color="orange"
          large
          @click="submit"
        >
          {{ buttonText }}
        </v-btn>

        <div class="skip-update justify-center">
          <v-btn
            text
            class="skip-update-text"
            @click="skipUpdate"
          >
            Saltar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data () {
    return {
      showDialog: false
    }
  },
  computed: {
    allClean () {
      return !this.notSyncRegistersCount && !this.syncErrorsCount
    },
    subtitleText () {
      if (this.allClean) {
        return 'Haz click en el botón para actualizar a la nueva versión.'
      }
      return 'Tienes datos por sincronizar. Asegúrate de que todo esté sincronizado y luego refresca la aplicación.'
    },
    buttonText () {
      if (this.allClean) {
        return 'Actualizar'
      }
      return 'Ir a sincronizar'
    },
    ...mapState('sync', ['notSyncRegistersCount', 'syncErrorsCount'])
  },
  methods: {
    async submit () {
      if (this.allClean) {
        this.showDialog = false
        location.reload()
        return
      } else {
        this.skipUpdate()
        this.$router.push('/sync-status')
      }
    },
    skipUpdate () {
      this.showDialog = false
      this.$emit('updateSkipped')
    },
    open () {
      this.showDialog = true
    }
  }
}
</script>

<style scoped>

.update-version-logo {
  display: flex;
  height: 100px;
  width: 100px;
  padding: 10px;
  margin-top: 10px;
  size: 100px;
  font-size: 100px;
}

.v-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 23.5rem;
  min-height: 26.6rem;
  background: rgba(22, 22, 22, 0.856);
  backdrop-filter: blur(30px);
}

.update-version-text {
  padding-top: 0.5rem;
}

.update-version-button {
  margin-top: 30px;
}

.v-dialog {
  max-width: 23.5rem;
  max-height: fit-content !important;
}

.skip-update {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  width: 100%;
}

.skip-update-text {
  font-size: 14px;
  color: #6e6e6e;
  text-decoration: none;
}

.update-version {
  background-color: transparent !important;
}

</style>
