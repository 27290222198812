<template>
  <div>
    <v-menu
      v-if="notificationsCount"
      offset-y
      class="mt-5"
    >
      <template #activator="{ on, attrs }">
        <v-badge
          class="mr-3"
          color="primary"
          :content="notificationsCount"
          overlap
        >
          <v-icon
            class="pr-1"
            color="primary"
            style="font-size: 35px;"
            v-bind="attrs"
            v-on="on"
          >
            mdi-bell-outline
          </v-icon>
        </v-badge>
      </template>
      <v-list class="notificationsList">
        <template
          v-for="(internalReferral, index) in internalReferrals"
        >
          <v-list-item
            :key="`referral-item-${index}-${internalReferral.id}`"
            @click="openInternalReferral(internalReferral)"
          >
            <v-list-item-content>
              <v-list-item-subtitle>
                Nueva derivación interna
              </v-list-item-subtitle>
              <v-list-item-title class="my-1 text--primary">
                <strong>
                  {{ internalReferral?.clinicalHistory?.patient }}
                </strong>
              </v-list-item-title>
              <v-list-item-subtitle>
                Derivado por <span class="text--primary">{{ internalReferral.createdBy }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="`referral-divider-${index}-${internalReferral.id}`" />
        </template>
        <template
          v-for="(patient, index) in nonAttendedPatients"
        >
          <v-list-item
            :key="`patient-item-${index}-${patient.clinicalHistoryId}`"
            @click="openFollowUp(patient)"
          >
            <v-list-item-content>
              <v-list-item-subtitle class="notification-data">
                Se solicita atención a
              </v-list-item-subtitle>
              <v-list-item-title class="my-1 text--primary">
                <strong>
                  {{ `${patient?.firstname} ${patient?.lastname}` }}
                </strong>
              </v-list-item-title>
              <v-list-item-subtitle class="notification-data">
                Última atención: <span class="text--primary">{{ `Hace ${patient.days} días` }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="notification-data">
                Comunidad: <span class="text--primary">{{ patient.community }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="notification-data">
                Estado {{ patient.area }}:
                <v-chip
                  small
                  :color="getColor(patient.resultValue)"
                >
                  {{ patient.resultName }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="`patient-divider-${index}-${patient.clinicalHistoryId}`" />
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getInternalReferralsByFilters } from '../../services/internalReferral.service'
import { getPatientStatusNotification } from '../../services/report.service'
import { personTypeEnum } from '../../enums/personType.enum'
import { getColor } from '../../utils/colorHelper'

export default {
  data () {
    return {
      referralsCount: 0,
      internalReferrals: [],
      nonAttendedPatientsCount: 0,
      nonAttendedPatients: []
    }
  },
  computed: {
    notificationsCount () {
      return this.referralsCount + this.nonAttendedPatientsCount
    },
    ...mapState('auth', ['currentUser'])
  },
  async created () {
    const referralFilters = {
      isRead: false,
      toAreaId: this.currentUser.area.id
    }
    const patientStatusFilters = {
      area: [],
      personType: personTypeEnum.PEDIATRIC,
      forNotification: 1
    }
    if (this.currentUser.area.hasIndicators) {
      patientStatusFilters.area = [this.currentUser.area.id]
    } else {
      patientStatusFilters.area = [5, 7, 9, 10]
      patientStatusFilters.onlyCritical = true
    }
    this.internalReferrals = await getInternalReferralsByFilters(referralFilters)
    this.referralsCount = this.internalReferrals && this.internalReferrals.length
    this.nonAttendedPatients = await getPatientStatusNotification(patientStatusFilters)
    this.nonAttendedPatientsCount = this.nonAttendedPatients && this.nonAttendedPatients.length
  },
  methods: {
    openInternalReferral (internalReferral) {
      this.$router.push('/follow-up?clinicalHistoryId=' + internalReferral.clinicalHistoryId + '&area=' + internalReferral.fromAreaId)
    },
    openFollowUp(patient) {
      this.$router.push('/follow-up?clinicalHistoryId=' + patient.clinicalHistoryId + '&area=' + patient.areaId)
    },
    getColor
  }
}
</script>
<style scoped>
.notificationsList {
  width: 500px;
  max-height: 60vh !important;
  max-width: 100vw !important;
  overflow-y: scroll !important;
}
.notification-data {
  overflow: visible;
}
</style>