import axios from 'axios'

export const state = {
  primaryCareStore: {
    clinicalHistoryId: null,
    id: null,
    fullVaccination: null,
    fullVaccinationObservations: null,
    healthChildCheck: null,
    healthChildCheckObservations: null,
    oea: null,
    oeaObservations: null,
    eyeFundus: null,
    eyeFundusObservations: null,
    ultrasoundOfHips: null,
    ultrasoundOfHipsObservations: null,
    neurometabolic: null,
    neurometabolicObservations: null
  }
}

export const mutations = {
  setPrimaryCareData (state, data) {
    state.primaryCareStore = data
  }
}

export const actions = {
  getPrimaryCareByClinicalHistoryId: async ({ commit }, id) => {
    return await axios.get('/clinicalHistory/primary-care/' + id)
      .then((response) => {
        commit('setPrimaryCareData', response.data)
        return response.data
      })
      .catch((error) => {
        commit('setPrimaryCareData', {})
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  createPrimaryCare: async ({ commit, state }, primaryCare) => {
    return await axios.post('clinicalHistory/primary-care', primaryCare)
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Datos creados con éxito' }, { root: true })
        return response.data.id
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  updatePrimaryCare: async ({ commit, state }, primaryCare) => {
    return await axios.put('clinicalHistory/primary-care', primaryCare)
      .then(() => {
        commit('baseSnackbar/success', { text: 'Datos actualizados con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
