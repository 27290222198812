import axios from 'axios'
import moment from 'moment'

export const state = {
  appointmentStore: {
  },
  appointments: [],
  appointmentFilters: {
    dateFrom: moment().format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
    institution: null,
    assistanceRequired: true
  },
  appointmentsForHome: []
}

export const mutations = {
  'SET_APPOINTMENTS' (state, data) {
    state.appointments = data
  },
  'SET_APPOINTMENT' (state, data) {
    state.appointmentStore = data
  },
  'SET_APPOINTMENTS_FOR_HOME' (state, data) {
    state.appointmentsForHome = data
  }
}

export const actions = {

  getAppointmentByReferralId: async ({ commit }, id) => {
    await axios.get(`appointment/referral/${id}`)
      .then((response) => {
        commit('SET_APPOINTMENTS', response.data)
        return true
      })
      .catch((error) => {
        commit('SET_APPOINTMENTS', [])
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit, dispatch }, appointment) => {
    return await axios.post('appointment', appointment)
      .then((response) => {
        dispatch('getAppointmentByReferralId', appointment.referralId)
        commit('baseSnackbar/success', { text: 'Turno creado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  update: async ({ commit, dispatch }, appointment) => {
    return await axios.put(`appointment/${appointment.id}`, appointment)
      .then((response) => {
        dispatch('getAppointmentByReferralId', appointment.referralId)
        commit('baseSnackbar/success', { text: 'Turno actualizado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getAppointmentById: async ({ commit }, id) => {
    return await axios.get('appointment/' + id)
      .then((response) => {
        commit('SET_APPOINTMENT', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getAppointmentsForHome: async ({ commit }) => {
    await axios.get('appointment', { params: state.appointmentFilters })
      .then((response) => {
        commit('SET_APPOINTMENTS_FOR_HOME', response.data)
        return true
      })
      .catch((error) => {
        commit('SET_APPOINTMENTS_FOR_HOME', [])
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }

}
