import axios from 'axios'
export const state = {
  indicators: null,
  currentResults: null,
  ageRange: [
    '0 a 6 meses',
    '6 a 12 meses',
    '1 a 2 años',
    'más de 2 años'
  ],
  byValue: [],
  byCenter: [],
  bySex: [],
  byAge: []
}

export const getters = {
  getLegendFromValue (value) {
    return getters.valueLegends()[value - 1]
  },
  getAgeRange (range) {
    return state.ageRange[range - 1]
  },
  valueLegends () {
    const legends = [null, null, null, null]
    if (!state.currentResults) return legends
    state.currentResults.forEach(result => {
      if (legends[result.value - 1] === null) {
        legends[result.value - 1] = result.name
      } else {
        legends[result.value - 1] += ' - ' + result.name
      }
    })
    return legends
  },
  valueSeries () {
    const result = [null, null, null, null]
    state.byValue.forEach(set => {
      result[set.value - 1] = {
        name: getters.getLegendFromValue(set.value),
        value: set.count
      }
    })
    return result
  },
  centerSeries () {
    const series = {}
    state.byCenter.forEach(set => {
      if (series[set.center] === undefined) {
        series[set.center] = [null, null, null, null]
      }
      if (series[set.center][set.value - 1]) {
        series[set.center][set.value - 1].value += Number(set.count)
      } else {
        series[set.center][set.value - 1] = {
          name: getters.getLegendFromValue(set.value),
          value: Number(set.count)
        }
      }
    })
    return series
  },
  sexSeries () {
    const series = {}
    state.bySex.forEach(set => {
      if (series[set.sex] === undefined) {
        series[set.sex] = [null, null, null, null]
      }
      if (series[set.sex][set.value - 1]) {
        series[set.sex][set.value - 1].value += Number(set.count)
      } else {
        series[set.sex][set.value - 1] = {
          name: getters.getLegendFromValue(set.value),
          value: Number(set.count)
        }
      }
    })
    return series
  },
  ageSeries () {
    const series = {}
    state.ageRange.forEach(age => {
      series[age] = null
    })
    state.byAge.forEach(set => {
      if (series[getters.getAgeRange(set.age)] === null) {
        series[getters.getAgeRange(set.age)] = [null, null, null, null]
      }
      if (series[getters.getAgeRange(set.age)][set.value - 1]) {
        series[getters.getAgeRange(set.age)][set.value - 1].value += Number(set.count)
      } else {
        series[getters.getAgeRange(set.age)][set.value - 1] = {
          name: getters.getLegendFromValue(set.value),
          value: Number(set.count)
        }
      }
    })
    return series
  },
  byCenter () {
    const centers = {}
    state.byCenter.forEach(item => {
      if (centers[item.center]) {
        centers[item.center].push(item)
      } else {
        centers[item.center] = [item]
      }
    })
    return centers
  },
  bySex () {
    const sexs = {}
    state.bySex.forEach(item => {
      if (sexs[item.sex]) {
        sexs[item.sex].push(item)
      } else {
        sexs[item.sex] = [item]
      }
    })
    return sexs
  },
  byAge () {
    const ages = {}
    state.byAge.forEach(item => {
      if (ages[getters.getAgeRange(item.age)]) {
        ages[getters.getAgeRange(item.age)].push(item)
      } else {
        ages[getters.getAgeRange(item.age)] = [item]
      }
    })
    return ages
  }
}

export const mutations = {
  'SET_INDICATORS' (state, data) {
    state.indicators = data
  },
  'SET_CURRENT_RESULTS' (state, data) {
    state.currentResults = data
  },
  'SET_BY_VALUE' (state, data) {
    state.byValue = data
  },
  'SET_BY_CENTER' (state, data) {
    state.byCenter = data
  },
  'SET_BY_SEX' (state, data) {
    state.bySex = data
  },
  'SET_BY_AGE' (state, data) {
    state.byAge = data
  }
}

export const actions = {
  getIndicatorsByArea: async ({ commit }, area) => {
    return await axios.get('follow-up/indicators-by-area?area=' + area)
      .then((response) => {
        commit('SET_INDICATORS', response.data)
        return true
      })
      .catch(() => {
        return false
      })
  },
  getGroupingByValue: async ({ commit }, indicator) => {
    await axios.get('general-report/status?byValue=true&indicator=' + indicator)
      .then((response) => {
        commit('SET_BY_VALUE', response.data)
      }).catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  getGroupingByCenter: async ({ commit }, indicator) => {
    await axios.get('general-report/status?byCenter=true&indicator=' + indicator)
      .then((response) => {
        commit('SET_BY_CENTER', response.data)
      }).catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  getGroupingBySex: async ({ commit }, indicator) => {
    await axios.get('general-report/status?bySex=true&indicator=' + indicator)
      .then((response) => {
        commit('SET_BY_SEX', response.data)
      }).catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  getGroupingByAge: async ({ commit }, indicator) => {
    await axios.get('general-report/status?byAge=true&indicator=' + indicator)
      .then((response) => {
        commit('SET_BY_AGE', response.data)
      }).catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  }
}
