import axios from 'axios'

export const state = {
  // Dto para los get, create, update
  addressStore: {
    id: null,
    neighborhood: null,
    street: null,
    number: null,
    observation: null,
    squareNumber: null,
    groundNumber: null,
    floorNumber: null,
    apartment: null,
    town: null,
    province: null,
    nation: null,
    latitude: null,
    longitude: null
  },
  totalAdress: 0
}

export const mutations = {
  'SET_ADDRESS' (state, data) {
    state.addressStore = data
  }
}

export const actions = {
  // buscar por id de address
  getById: async ({ commit }, id) => {
    return await axios.get('/address/' + id)
      .then((response) => {
        commit('SET_ADDRESS', response.data)
        return response.data.province
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  // actualizar datos
  update: async ({ commit }, address) => {
    return await axios.put('/address/' + address.id, address)
      .then(
        () => {
          commit('baseSnackbar/success', { text: 'Grupo familiar actualizado' }, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  // crear domicilio
  create: async ({ commit }, address) => {
    return await axios.post('/address', address)
      .then(
        (response) => {
          commit('baseSnackbar/success', { text: 'Grupo familiar creado' }, { root: true })
          return response.data.id
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  // Altas y bajas lógicas
  logicalEnabled: async ({ commit }, params) => {
    return await axios.put('/address/logicalEnabled/' + params.id, { status: params.status })
      .then(
        (response) => {
          commit('baseSnackbar/success', { text: 'Estado cambiado con éxito' }, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
