import axios from 'axios'

export const state = {
  difficultyDataStore: {
    clinicalHistoryId: null,
    id: null,
    seeing: null,
    seeingObservations: null,
    hearing: null,
    hearingObservations: null,
    walking: null,
    walkingObservations: null,
    grabbing: null,
    grabbingObservations: null,
    learning: null,
    learningObservations: null,
    communicating: null,
    communicatingObservations: null,
    relating: null,
    relatingObservations: null
  }
}

export const mutations = {
  setDifficultyData (state, data) {
    state.difficultyDataStore = data
  }
}

export const actions = {
  getDifficultyDataByClinicalHistoryId: async ({ commit }, id) => {
    return await axios.get('/difficulty-data/' + id)
      .then((response) => {
        commit('setDifficultyData', response.data)
        return response.data
      })
      .catch((error) => {
        commit('setDifficultyData', {})
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  createDifficultyData: async ({ commit, state }, difficultyData) => {
    return await axios.post('/difficulty-data', difficultyData)
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Datos creados con éxito' }, { root: true })
        return response.data.id
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  updateDifficultyData: async ({ commit, state }, difficultyData) => {
    return await axios.put('/difficulty-data', difficultyData)
      .then(() => {
        commit('baseSnackbar/success', { text: 'Datos actualizados con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
