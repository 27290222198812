export const state = {
  propsConfirm: {
    confirm: false
  },
  defaultProps: {
    confirm: false,
    title: 'Atención',
    message: '¿Está seguro/a que desea continuar?',
    dismissText: 'Cancelar',
    acceptText: 'Aceptar',
    color: 'warning',
    icon: 'warning',
    width: '300px',
    height: '200px',
    showCancel: true,
    accept: () => {},
    dismiss: () => {}
  },
  confirmResult: false,
  confirmed: () => {}
}
export const mutations = {
  setProps (state, props) {
    state.propsConfirm.confirm = true
    state.propsConfirm.title = props.title || state.defaultProps.title
    state.propsConfirm.message = props.message || state.defaultProps.message
    state.propsConfirm.dismissText = props.dismissText || state.defaultProps.dismissText
    state.propsConfirm.acceptText = props.acceptText || state.defaultProps.acceptText
    state.propsConfirm.color = props.color || state.defaultProps.color
    state.propsConfirm.icon = props.icon || state.defaultProps.icon
    state.propsConfirm.width = props.width || state.defaultProps.width
    state.propsConfirm.height = props.height || state.defaultProps.height
    state.propsConfirm.accept = props.accept || state.defaultProps.accept
    state.propsConfirm.dismiss = props.dismiss || state.defaultProps.dismiss
    state.propsConfirm.showCancel = props.showCancel !== undefined ? props.showCancel : state.defaultProps.showCancel
  },
  setConfirmResult (state, value) {
    state.confirmResult = value || false
  }
}
export const actions = {
  confirm ({ state, commit }, props) {
    commit('setProps', props)
    return new Promise((resolve) => {
      state.confirmed = () => {
        resolve(state.confirmResult)
        state.propsConfirm.confirm = false
      }
    })
  }
}
