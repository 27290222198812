import axios from 'axios'
import { processPeople } from '../../utils/personImportHelper'

export const state = {
  processedPeople: [],
  failedPeople: [],
  successfullyPeople: [],
  pageNumber: 30
}

export const mutations = {
  'SET_PROCESSED_PEOPLE' (state, data) {
    state.processedPeople = data
  },
  'SET_MIGRATION_RESULT' (state, data) {
    state.failedPeople = data.failedPeople
    state.successfullyPeople = data.successfullyPeople
  },
  'ADD_MIGRATION_RESULT' (state, data) {
    state.failedPeople = state.failedPeople.concat(data.failedPeople)
    state.successfullyPeople = state.successfullyPeople.concat(data.successfullyPeople)
  }
}

export const actions = {
  /*
   * Procesa el archivo csv de anthro
   * y genera un listado listo para migrar
   */
  processPeople: async ({ commit }, { file }) => {
    const { rows, error } = await processPeople(file)
    if (error) {
      commit('baseSnackbar/alert', { text: error, code: 400 }, { root: true })
      return false
    }
    commit('SET_PROCESSED_PEOPLE', rows)
  },
  /**
   * Envía el lsitado de seguimientos procesados desde anthro
   * al servidor. Lo realiza por lotes, para evitar un request body demasiado grande
   */
  importPeopleData: async ({ commit }) => {
    for (let index = 0; index < state.processedPeople.length; index += state.pageNumber) {
      const batch = [...state.processedPeople].splice(index, state.pageNumber)
      try {
        const response = await axios.post('person/import', batch)
        commit('ADD_MIGRATION_RESULT', response.data)
      } catch (error) {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      }
    }
    return true
  }
}
