import axios from 'axios'

export const state = {
  areas: [],
  areasWithFollows: [],
  areasWithIndicators: []
}

export const mutations = {
  'SET_AREAS' (state, data) {
    state.areas = data
  },
  'SET_AREAS_WITH_FOLLOWS' (state, data) {
    state.areasWithFollows = data
  },
  'SET_AREAS_WITH_INDICATORS' (state, data) {
    state.areasWithIndicators = data
  }
}

export const actions = {

  getAreas: async ({ commit }) => {
    await axios.get('area')
      .then((response) => {
        commit('SET_AREAS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getAreasWithFollows: async ({ commit }) => {
    await axios.get('area?hasFollows=true')
      .then((response) => {
        commit('SET_AREAS_WITH_FOLLOWS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getAreasWithIndicators: async ({ commit }) => {
    await axios.get('area?hasIndicators=true')
      .then((response) => {
        commit('SET_AREAS_WITH_INDICATORS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }

}
