import axios from 'axios'

export const state = {
  // Dto para los get, create, update
  housingConditionsStore: {
    id: null,
    familyGroupId: null,
    housingConditionsType: null,
    overcrowdingType: null,
    waterAccess: null,
    waterType: null,
    mustMoveToGetWater: null,
    hasEnoughWater: null,
    hasHotWater: null,
    electricity: null,
    fuelType: null,
    hasBathrom: null,
    hasBathromDischarge: null,
    hasSewers: null,
    hasSepticTank: null,
    sharedBathroom: null,
    bathroomLocation: null,
    isPrecariousBathrom: null,
    observations: null
  }
}

export const mutations = {
  'SET_HOUSING_CONDITIONS' (state, data) {
    state.housingConditionsStore = data
  }
}

export const actions = {
  // buscar Vivienda por familyGroupId
  getHousingConditionsByFamilyGroupId: async ({ commit }, familyGroupId) => {
    return await axios.get('/housing-conditions/' + familyGroupId)
      .then((response) => {
        commit('SET_HOUSING_CONDITIONS', response.data)
        return true
      })
      .catch(() => {
        return false
      })
  },
  // actualizar Vivienda
  update: async ({ commit }, housingConditions) => {
    return await axios.put('/housing-conditions/' + housingConditions.id, housingConditions)
      .then(
        (response) => {
          commit('baseSnackbar/success', { text: 'Vivienda actualizada' }, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  // crear Vivienda
  create: async ({ commit }, housingConditions) => {
    return await axios.post('/housing-conditions', housingConditions)
      .then(
        (response) => {
          commit('baseSnackbar/success', { text: 'Vivienda creada' }, { root: true })
          return response.data
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
