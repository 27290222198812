import Dexie from 'dexie';

export const db = new Dexie('db');

db.version(7).stores({
  personStore: '++id, notSync, requestHash, document, cuil',
  clinicalHistoryStore: 'id++, personId, state, notSync, isLocked, requestHash',
  followUpStore: 'id++, clinicalHistoryId, areaId, notSync, isLocked, requestHash, date, [clinicalHistoryId+areaId]',
  indicatorStore: 'id++, areaId',
  sessionStore: 'key',
  areaStore: 'id',
  communityStore: 'id',
  failedPeopleStore: 'id++, person.id',
  failedClinicalHistoriesStore: 'id++, clinicalHistory.id, clinicalHistory.personId',
  failedFollowsStore: 'id++, followUp.id, followUp.clinicalHistoryId',
  attendanceStore: 'id++, clinicalHistoryId, date, status, notSync',
  failedAttendancesStore: 'id++, attendance.id, attendance.clinicalHistoryId',
  familyStore: 'id++',
  provinceStore: 'id++',
  townStore: 'id++, provinceId',
  addressStore: 'id++, familyGroupId',
  housingConditionsStore: 'id++, familyGroupId',
  bondsStore: 'id++, childId',
  updatesStore: 'id++, date',
  diagnosisStore: 'id++',
  centerStore: 'id++, foundationId',
  permissionStore: 'id++',
  productStore: 'id++',
  batchStore: 'id++, productId',
  movementStore: 'id++, batchId, clinicalHistoryId, notSync, isLocked, requestHash',
  failedMovementsStore: 'id++, movement.id, movement.clinicalHistoryId, movement.batchId',
  failedRequestsStore: 'id++'
}).upgrade(async trans => {
  const failedFollows = await trans.table('failedFollowsStore').toArray()
  if (!failedFollows || !failedFollows.length) return
  const failedFollowsWithError = failedFollows.filter(failedFollow => !failedFollow.followUp || !failedFollow.error)
  if (!failedFollowsWithError || !failedFollowsWithError.length) return
  for (const failedFollow of failedFollowsWithError) {
    await trans.table('failedFollowsStore').delete(failedFollow.id)
  }
})

export const openDb = async () => {
  try {
    await db.open()
    console.log('Database successfuly opened')
  } catch (err) {
    console.error('Failed to open db: ' + (err.stack || err))
  }
}
