<template>
  <div>
    <v-container
      fluid
      fill-height
      class="my-auto loading-container"
    >
      <v-row>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-progress-circular
            :size="200"
            color="#E58D1F"
            indeterminate
          >
            <div 
              class="img-container d-flex justify-center align-center"
            >
              <img
                class="loading-logo"
                :src="getAsset('Cuadrado.webp')"
              >
            </div>
          </v-progress-circular>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <div class="text-container">
            <p
              class="loading-text"
            >
              {{ loadingText }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getAsset } from '../../utils/assetsHelper'
export default {
  data () {
    return {
      loadingText: 'Estamos preparando todo. Por favor aguarde.'
    }
  },
  methods: {
    getAsset
  }
}
</script>

<style scoped>

.logo-container {
  max-height: 50%;
  max-width: 50%;
}

.loading-logo {
  max-height: 80%;
  max-width: 80%;
  padding-bottom: 5px;
}

.loading-container {
  height: 100vh;
  width: 100vw;
  background: rgba(22, 22, 22, 0.856);
}

.loading-text {
  color: white;
  text-align: center;
}

.text-container {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

</style>
