import axios from 'axios'
export const state = {
  // Dto para los get, create, update
  results: [],
  totalResults: 0,
  filters: {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    month: null,
    year: null,
    center: null
  }
}
export const mutations = {
  'SET_REPORTS' (state, data) {
    state.results = data
    state.totalResults = data.lenght
  }
}
export const actions = {
  getByFilters: async ({ commit }) => {
    const parsedFilters = parseFilters()
    await axios.get('report/get-transitions-report', { params: parsedFilters })
      .then((response) => {
        commit('SET_REPORTS', response.data)
      })
      .catch((error) => {
        commit('SET_REPORTS', [])
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}

function parseFilters () {
  // Clonamos el objeto
  const parsedFilters = JSON.parse(JSON.stringify(state.filters))
  for (let i = 0; i < parsedFilters.sortBy.length; i++) {
    switch (parsedFilters.sortBy[i]) {
      case 'firstname':
        parsedFilters.sortBy[i] = 'Person.firstname'
        break
      case 'lastname':
        parsedFilters.sortBy[i] = 'Person.lastname'
        break
      case 'transitionDate':
        parsedFilters.sortBy[i] = 'FollowUp.date'
        break
    }
  }
  return parsedFilters
}
