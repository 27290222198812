import axios from 'axios'

export const state = {
  menu: [],
  permissionStore: {
    id: null,
    name: null,
    isEnabled: null,
    possition: null,
    parentId: null,
    icon: null,
    route: null,
    endpoint: null,
    areas: null
  },
  permissions: [],
  totalPermissions: 0,
  permissionAreasStore: [],
  filters: {
    page: 1,
    itemsPerPage: 50,
    sortBy: [],
    sortDesc: [],
    includeDisabled: false
  }
}

export const mutations = {
  'SET_MENU' (state, data) {
    state.menu = data
  },
  'SET_PERMISSIONS' (state, data) {
    state.permissions = data.items
    state.totalPermissions = data.totalItems
  },
  'SET_PERMISSION' (state, data) {
    state.permissionStore = data
  },
  'SET_PERMISSIONS_FOR_AREAS' (state, data) {
    state.permissionAreasStore = data
  }
}

export const actions = {
  /**
   * Obtiene los accesos para el menu según el área del usuario logueado.
   */
  getMenu: async ({ commit }) => {
    return await axios.get('permission/menu')
      .then((response) => {
        commit('SET_MENU', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.data.status }, { root: true })
        return false
      })
  },

  getByFilters: async ({ commit }) => {
    return await axios.get('permission', { params: state.filters })
      .then((response) => {
        commit('SET_PERMISSIONS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit, dispatch }, permission) => {
    return await axios.post('permission', permission)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Permiso creado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  update: async ({ commit, dispatch }, permission) => {
    return await axios.put('permission', permission)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Permiso actualizado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  enablePermission: async ({ commit }, params) => {
    return await axios.put('/permission/enable/' + params.id, { status: params.status })
      .then((response) => {
        commit('baseSnackbar/success', { text: `Permiso ${params.status === 1 ? 'activada' : 'desactivada'} con éxito` }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getPermissionById: async ({ commit }, id) => {
    return await axios.get('permission/' + id)
      .then((response) => {
        commit('SET_PERMISSION', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  deletePermissionForArea: async ({ commit }, id) => {
    return await axios.delete('permission/' + id)
      .then(
        (response) => {
          commit('baseSnackbar/success', { text: 'Permiso eliminado con éxito' }, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getAreasByPermission: async ({ commit }, permissionId) => {
    return await axios.get('permission/area/' + permissionId)
      .then((response) => {
        commit('SET_PERMISSIONS_FOR_AREAS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  changeEditionPermissionForArea: async ({ commit }, item) => {
    return await axios.put('permission/edition/' + item.id, { canEdit: !item.canEdit })
      .then(
        (response) => {
          commit('baseSnackbar/success', { text: `Permiso de edición ${item.canEdit === 1 ? 'activado' : 'desactivado'} con éxito` }, { root: true })
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  createPermissionForArea: async ({ commit, dispatch }, permission) => {
    return await axios.post('/permission/area/', permission)
      .then((response) => {
        dispatch('getAreasByPermission', permission.permissionId)
        commit('baseSnackbar/success', { text: 'Permiso creado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }

}
