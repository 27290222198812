import axios from 'axios'

export const state = {
  interconsultations: []
}

export const mutations = {
  'SET_INTERCONSULTATIONS' (state, data) {
    state.interconsultations = data
  }
}

export const actions = {
  getByClinicalHistoryId: ({ commit }, clinicalHistoryId) => {
    return axios.get('/interconsultation/clinical-history/' + clinicalHistoryId)
      .then((response) => {
        commit('SET_INTERCONSULTATIONS', response.data.body)
        return response.data.body
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  getInterconsultationById: ({ commit }, interconsultationId) => {
    return axios.get('/interconsultation/' + interconsultationId)
      .then((response) => {
        return response.data.body
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  createInterconsultation: ({ commit, dispatch }, interconsultation) => {
    return axios.post('/interconsultation', interconsultation)
      .then((response) => {
        dispatch('getByClinicalHistoryId', interconsultation.clinicalHistoryId)
        commit('baseSnackbar/success', { text: response.data.messageToShow }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  updateInterconsultation: ({ commit, dispatch }, interconsultation) => {
    return axios.put('/interconsultation/' + interconsultation.id, interconsultation)
      .then((response) => {
        dispatch('getByClinicalHistoryId', interconsultation.clinicalHistoryId)
        commit('baseSnackbar/success', { text: response.data.messageToShow }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  }

}
