import axios from 'axios'
import moment from 'moment'

export const state = {
  batches: [],
  filters: {
    id: null,
    includeWithoutStock: false
  },
  reportFilters: {
    typeId: null,
    dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
    movementType: null,
    movementReason: null,
    product: null,
    productId: null,
    centerId: null
  },
  reportData: [],
  movementType: ['Entrada', 'Salida'],
  movementReason: {
    Salida: ['Entrega a paciente', 'Envío a otro Centro', 'Fraccionado', 'Merienda', 'Vencimiento', 'Destruido', 'Robado', 'Ajuste de stock (Salida)', 'Armado de módulo alimentario'],
    Entrada: ['Donación', 'Compra', 'Ajuste de stock (Entrada)', 'Devolución', 'Ingreso desde otro Centro', 'Armado de módulo alimentario', 'Fraccionado']
  },
  expiredBatches: []
}

export const mutations = {
  'SET_BATCHES' (state, data) {
    state.batches = data
  },
  'SET_REPORT_DATA' (state, data) {
    state.reportData = data
  },
  'SET_EXPIRED_BATCHES' (state, data) {
    state.expiredBatches = data
  }
}

export const actions = {
  /**
   * Obtiene todos los movimientos (agrupados por lote) de un producto
   */
  getMovementsByProductId: async ({ commit }, id) => {
    const productId = id || state.filters.id
    return await axios.get('/batch/movements-by-product-id/' + productId, { params: state.filters })
      .then((response) => {
        commit('SET_BATCHES', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        commit('SET_BATCHES', [])
        return false
      })
  },

  /**
   * Crea un movimiento de stock para un producto determinado
   */
  createMovement: async ({ commit }, movement) => {
    return await axios.post('/batch/movement/', movement)
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Movimiento registrado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  createFractionateMovement: async ({ commit }, fractionateData) => {
    return await axios.post('/batch/fractionate/', fractionateData)
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Movimiento registrado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
    /**
   * Elimina un movimiento de stock para un producto determinado
   */
    deleteMovement: async ({ commit, dispatch }, movementId) => {
      return await axios.delete(`/batch/movement/${movementId}`)
        .then((response) => {
          commit('baseSnackbar/success', { text: 'Movimiento eliminado con éxito' }, { root: true })
          dispatch('getMovementsByProductId')
          return true
        })
        .catch((error) => {
          commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
          return false
        })
    },

  /**
   * Crea una serie de movimientos para los productos de una orden de entrega
   */
  createDeliveryOrder: async ({ commit }, { batchesWithMovements, toPatient, toCenter }) => {
    const params = {}
    if (toPatient) {
      params.toPatient = toPatient
    }
    if (toCenter) {
      params.toCenter = toCenter
    }
    return await axios.post('/batch/delivery-order/', batchesWithMovements, { params })
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Orden registrada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  /**
   * Obtiene movimientos según filtros indicados
   */
  getMovementsReport: async ({ commit }) => {
    state.reportFilters.productId = state.reportFilters.product ? state.reportFilters.product.id : null
    return await axios.get('/batch/report/', { params: state.reportFilters })
      .then((response) => {
        commit('SET_REPORT_DATA', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        commit('SET_REPORT_DATA', [])
        return false
      })
  },

  getExpiredBatches: async ({ commit }, centerId) => {
    return await axios.get('/batch/expired', { params: { centerId: centerId } })
      .then((response) => {
        commit('SET_EXPIRED_BATCHES', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        commit('SET_EXPIRED_BATCHES', [])
        return false
      })
  }
}
