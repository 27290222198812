import moment from 'moment'
import { paginateArray } from '../../utils/arrayHelper'
import { CONNECTION_ERROR_MESSAGE, FETCH_ERROR_MESSAGE } from '../../utils/constants'
import { db } from '../database'
import { getClinicalHistoryById, getClinicalHistoryByPersonId } from './clinicalHistory.repository'
import { getPeopleByFilters, getPersonByClinicalHistoryId } from './person.repository'

const getResultsDictionary = (results) => {
  if (results) {
    let resultsDictionary = {}
    for (const result of results) {
      resultsDictionary[result.indicatorId] = result.resultId
    }
    results = resultsDictionary
  }
  return results
}

// Crea un objeto con la misma estructura que el DTO que retorna
// la ruta /api/follow-up/last-follow-up/{id}
const buildLastFollowObject = (lastFollowUp) => {
  if (!lastFollowUp) return null
  const lastDeterminantResult = lastFollowUp.results.find(result => result.indicator.isDeterminant)
  const resultsDictionary = {}
  for (const resultForFollow of lastFollowUp.results) {
    if (!resultForFollow.indicator || !!resultForFollow.indicator) continue
    resultsDictionary[String(resultForFollow.indicator.id)] = Number(resultForFollow.result.id)
  }
  return {
    id: lastFollowUp.id,
    date: lastFollowUp.date,
    areaId: lastFollowUp.areaId,
    name: lastDeterminantResult && lastDeterminantResult.result && lastDeterminantResult.result.name,
    value: lastDeterminantResult && lastDeterminantResult.result && lastDeterminantResult.result.value,
    results: getResultsDictionary(lastFollowUp.results)
  }
}

export const addOrUpdateFollowUp  = async (followUp) => {
  if (!followUp) return
  const followUpAdded = await db.followUpStore.put(followUp)
  return followUpAdded
}

export const updateFollowUp  = async (followUp) => {
  if (!followUp) return
  const existingFollowUp = await db.followUpStore.get({ id: followUp.id })
  // Keep same request hash
  followUp.requestHash = existingFollowUp.requestHash
  const updatedFollowUp = await db.followUpStore.put(followUp)
  return updatedFollowUp
}

export const getFollowById = async (id) => {
  const followUp = await db.followUpStore.get({id: Number(id)})
  return followUp
}

export const addFollows  = async (follows) => {
  if (!follows) return
  await db.followUpStore.bulkPut(follows)
}

export const addResultsToFollow = async (followUp) => {
  for (const result of followUp.results) {
    result.indicator = await db.indicatorStore.get(Number(result.indicatorId))
    result.result = result.indicator.results.find(r => r.id == result.resultId)
  }
}

// Obtiene los ids de los legajos de toda una familia, a partir del id de legajo de una persona
const getFamilyIdsByClinicalHistoryId = async (clinicalHistoryId) => {
  const person = await getPersonByClinicalHistoryId(clinicalHistoryId)
  if (!person.familyGroupId) return [clinicalHistoryId]
  const familyPeople = await getPeopleByFilters({ familyGroupId: person.familyGroupId })
  const familyIds = []
  if (!familyPeople?.items?.length) return familyIds
  for (const member of familyPeople.items) {
    const clinicalHistory = await getClinicalHistoryByPersonId(member.id)
    // De cada miembro de la familia tomamos el ID del legajo
    if (clinicalHistory?.id) familyIds.push(clinicalHistory.id)
  }
  return familyIds
}

export const getLastFollow = async ({ clinicalHistoryId, areaId }) => {
  let allPatientFollows =[]
  let lastFollowUp
  const isSocialArea = areaId === 9 // Id del área social
  if (isSocialArea) { // Si es area social traemos los seguimientos de toda la familia
    const familyIds = await getFamilyIdsByClinicalHistoryId(clinicalHistoryId)
    // Realizamos la consulta de los seguimientos
    allPatientFollows = await db.followUpStore
    .where('clinicalHistoryId')
    .anyOf(familyIds)
    .and(follow => follow.areaId === areaId )
    .reverse()
    .sortBy('date')
    lastFollowUp = allPatientFollows[0]
  } else {
    allPatientFollows = await db.followUpStore
      .where({clinicalHistoryId, areaId})
      .reverse()
      .sortBy('date')
    lastFollowUp = allPatientFollows[0]
  }
  // Una vez que obtenemos el último seguimiento creamos la misma estructura
  // que devuelve la ruta follow-up/last-follow-up
  return buildLastFollowObject(lastFollowUp)
}


/**
 * Simulador de búsqueda de seguimientos para cuando no hay conexión
 * @param {*} filtros de búsqueda
 * @returns seguimientos que coinciden con los filtros de búsqueda
 */
export const getFollowsByFilters = async ({ clinicalHistoryId, page, itemsPerPage, areaId, startDate, endDate }) => {
  const isSocialArea = areaId === 9 // Id del área social
  let allPatientFollows = []
  if (isSocialArea) { // Si es area social traemos los seguimientos de toda la familia
    const familyIds = await getFamilyIdsByClinicalHistoryId(clinicalHistoryId)
    // Realizamos la consulta de los seguimientos
    allPatientFollows = await db.followUpStore
    .where('clinicalHistoryId')
    .anyOf(familyIds)
    .reverse()
    .sortBy('date')
  } else {
    allPatientFollows = await db.followUpStore
      .where({clinicalHistoryId})
      .reverse()
      .sortBy('date')
  }
  let filteredFollows = allPatientFollows
  if (areaId) {
    filteredFollows = allPatientFollows.filter(follow => follow.areaId == areaId)
  }
  if (startDate) {
    filteredFollows = allPatientFollows.filter(follow => moment(follow.date).isSameOrAfter(moment(startDate)))
  }
  if (endDate) {
    filteredFollows = allPatientFollows.filter(follow => moment(follow.date).isSameOrBefore(moment(endDate)))
  }
  return paginateArray(filteredFollows, page, itemsPerPage)
}

/**
 * Busca los seguimientos realizados en un día específico
 * @param {*} filtros de búsqueda
 * @returns seguimientos que coinciden con los filtros de búsqueda
 */
export const getFollowsByDate = async ({ areaId, date }) => {
  const allDateFollows = await db.followUpStore.where({ date, areaId }).toArray()
  return allDateFollows
} 

export const deleteNotSyncFollows = async () => {
  const follows = await db.followUpStore.toArray()
  const notSyncFollows = follows.filter(follow => follow.notSync)
  for (const follow of notSyncFollows) {
    await db.followUpStore.delete(follow.id)
  }
}

export const removeFollowUpByRequestHash = async (requestHash) => {
  const followUp = await db.followUpStore.get({ requestHash })
  if (followUp) {
    await db.followUpStore.delete(followUp.id)
  }
}

export const addFailedSyncRequest = async (requestHash, error) => {
  if (error && error.message === FETCH_ERROR_MESSAGE) {
    error.message = CONNECTION_ERROR_MESSAGE
  }
  const followUp = await db.followUpStore.get({ requestHash })
  if (followUp) {
    db.failedFollowsStore.put({ followUp, error })
  } else {
    throw new Error('Follow Up error not found')
  }
}

export const getFollowUpByRequestHash = async (requestHash) => {
  const followUp = await db.followUpStore.get({ requestHash })
  return followUp
}

export const verifyLockedByRequestHash = async (requestHash) => {
  const followUp = await getFollowUpByRequestHash(requestHash)
  return followUp.isLocked
}

export const unlockByClinicalHistoryId = async (oldChId, newChId) => {
  const follows = await db.followUpStore.where({ clinicalHistoryId: oldChId }).toArray()
  if (follows) {
    for (const followUp of follows) {
      await db.followUpStore.update(followUp.id, {
        clinicalHistoryId: newChId,
        isLocked: 0
      })
    }
  }
  const newClinicalHistory = await getClinicalHistoryById(newChId)
  const attendances = await db.attendanceStore.where({ clinicalHistoryId: oldChId }).toArray()
  if (attendances) {
    for (const attendance of attendances) {
      await db.attendanceStore.update(attendance.id, {
        clinicalHistoryId: newChId,
        isLocked: 0,
        clinicalHistory: newClinicalHistory
      })
    }
  }
  const movements = await db.movementStore.where({ clinicalHistoryId: oldChId }).toArray()
  if (movements) {
    for (const movement of movements) {
      await db.movementStore.update(movement.id, {
        clinicalHistoryId: newChId,
        isLocked: 0
      })
    }
  }
}