import axios from 'axios'

export const state = {
  familyGroupStore: {
    id: null,
    lastname: null,
    income: null,
    socialBenefits: null,
    familyType: null,
    occupationalSituation: null,
    observations: null,
    isEnabled: null,
    addressId: null
  },
  families: [],
  totalFamilies: 0,
  filters: {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    lastname: '',
    includeDisabled: false
  },
  isNewFamilyGroup: false,
  isViewFamilyGroup: false,
  last: null,
  personId: null,
  personsFilters: {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    includeDisabled: true,
    familyGroupId: null
  },
  persons: [],
  totalPersons: null
}
export const mutations = {
  'SET_FAMILIES' (state, data) {
    state.families = data.items
    state.totalFamilies = data.totalItems
  },
  'SET_FAMILY' (state, data) {
    state.familyGroupStore = data
  },
  'SET_LAST' (state, data) {
    state.last = data
  },
  'SET_ID' (state, data) {
    state.familyGroupStore.id = data
  },
  setViewFamilyGroup (state, data) {
    state.isViewFamilyGroup = data
  },
  setNewFamilyGroup (state, data) {
    state.isNewFamilyGroup = data
  },
  setPersonId (state, data) {
    state.personId = data
  },
  'SET_PERSONS' (state, data) {
    state.persons = data.items
    state.totalPersons = data.totalItems
  }
}
export const actions = {

  getByFilters: async ({ commit }) => {
    await axios.get('family-group', { params: state.filters })
      .then((response) => {
        commit('SET_FAMILIES', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getFamilyGroupById: async ({ commit }, id) => {
    return await axios.get('family-group/' + id)
      .then((response) => {
        commit('SET_FAMILY', response.data)
        commit('setViewFamilyGroup', true)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit }, familyGroup) => {
    const body = {
      familyGroup: familyGroup,
      personId: state.personId
    }
    return await axios.post('family-group', body)
      .then((response) => {
        if (response.data) {
          commit('SET_LAST', response.data.id)
          commit('baseSnackbar/success', { text: 'Grupo familiar creado' }, { root: true })
          commit('setViewFamilyGroup', true)
          commit('setNewFamilyGroup', false)
          commit('SET_FAMILY', familyGroup)
          commit('SET_ID', response.data.id)
        }
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  update: async ({ commit, dispatch }, familyGroup) => {
    return await axios.put('family-group', familyGroup)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Grupo familiar actualizado' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  logicalEnabled: async ({ commit }, params) => {
    return await axios.put('family-group/logical-enabled/' + params.id, { status: params.status })
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Se cambió el estado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getPersonsByFilters: async ({ commit }) => {
    await axios.get('/person', { params: state.personsFilters })
      .then((response) => {
        commit('SET_PERSONS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
