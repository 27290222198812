import axios from 'axios'
export const state = {
  // En el state alamacenamos todas las enumeraciones
  docType: [],
  healthInsurance: [],
  sex: [],
  personType: [],
  userArea: [],
  overcrowdingTypes: [],
  housingConditionsTypes: [],
  waterTypes: [],
  conveyances: [],
  waterManagers: [],
  waterFrequency: [],
  waterTreatment: [],
  electricityTypes: [],
  electricitySource: [],
  fuelTypes: [],
  bathroomLocation: [],
  familyType: [],
  occupationalSituation: [],
  clinicalHistoryState: [],
  month: [],
  year: [],
  statusColor: [],
  familyBondType: [],
  milkFrequency: [],
  milkType: [],
  milkQuantity: [],
  liquids: [],
  times: [],
  preparations: [],
  peopleWhoFeed: [],
  peopleWhoCook: [],
  food: [],
  waterOrigin: [],
  sanitation: [],
  storage: [],
  attendanceStatus: [],
  sphincterControl: [],
  action: [],
  oeaResult: [],
  sleep: [],
  typeSleep: [],
  interruptionCause: [],
  comunicationInHouse: [],
  causeOfRepeat: [],
  hoursSleep: [],
  quality: [],
  use: [],
  cutlery: [],
  limits: [],
  limitsForms: [],
  physicalRelationship: [],
  refersToSon: [],
  refersToMother: [],
  motherToInterviewer: [],
  riskIndex: [],
  typeOfChildbirth: [],
  developmentalGuidelines: [],
  motherAndSon: [],
  childToInterviewer: [],
  repeatSentence: [],
  attitudeToFailure: [],
  otherFoods: [],
  mealTime: [],
  admissionReason: [],
  nutritionalDiagnosis: [],
  psychomotorDevelopment: [],
  ethnicity: [],
  language: [],
  gender: [],
  unities: [],
  institutionTypes: [],
  referralStates: [],
  referralReasons: [],
  schoolingLevel: [],
  accommodationTypes: [],
  organizations: []
}

export const mutations = {
  // Aquí se crean los setter para cada enumeración
  setdocType (state, docType) {
    state.docType = docType
  },
  sethealthInsurance (state, healthInsurance) {
    state.healthInsurance = healthInsurance
  },
  setsex (state, sex) {
    state.sex = sex
  },
  setpersonType (state, personType) {
    state.personType = personType
  },
  setuserArea (state, userArea) {
    state.userArea = userArea
  },
  setfamilyType (state, familyType) {
    state.familyType = familyType
  },
  setoccupationalSituation (state, occupationalSituation) {
    state.occupationalSituation = occupationalSituation
  },
  setovercrowdingTypes (state, overcrowdingTypes) {
    state.overcrowdingTypes = overcrowdingTypes
  },
  sethousingConditionsTypes (state, housingConditionsTypes) {
    state.housingConditionsTypes = housingConditionsTypes
  },
  setwaterTypes (state, waterTypes) {
    state.waterTypes = waterTypes
  },
  setconveyances (state, conveyances) {
    state.conveyances = conveyances
  },
  setwaterManagers (state, waterManagers) {
    state.waterManagers = waterManagers
  },
  setwaterFrequency (state, waterFrequency) {
    state.waterFrequency = waterFrequency
  },
  setelectricityTypes (state, electricityTypes) {
    state.electricityTypes = electricityTypes
  },
  setelectricitySource (state, electricitySource) {
    state.electricitySource = electricitySource
  },
  setfuelTypes (state, fuelTypes) {
    state.fuelTypes = fuelTypes
  },
  setbathroomLocation (state, bathroomLocation) {
    state.bathroomLocation = bathroomLocation
  },
  setclinicalHistoryState (state, clinicalHistoryState) {
    state.clinicalHistoryState = clinicalHistoryState
  },
  setmonth (state, month) {
    state.month = month
  },
  setyear (state, year) {
    state.year = year
  },
  setstatusColor (state, statusColor) {
    state.statusColor = statusColor
  },
  setfamilyBondType (state, familyBondType) {
    state.familyBondType = familyBondType
  },
  setmilkFrequency (state, milkFrequency) {
    state.milkFrequency = milkFrequency
  },
  setmilkQuantity (state, milkQuantity) {
    state.milkQuantity = milkQuantity
  },
  setmilkType (state, milkType) {
    state.milkType = milkType
  },
  setliquids (state, liquids) {
    state.liquids = liquids
  },
  settimes (state, times) {
    state.times = times
  },
  setpreparations (state, preparations) {
    state.preparations = preparations
  },
  setpeopleWhoFeed (state, peopleWhoFeed) {
    state.peopleWhoFeed = peopleWhoFeed
  },
  setpeopleWhoCook (state, peopleWhoCook) {
    state.peopleWhoCook = peopleWhoCook
  },
  setfood (state, food) {
    state.food = food
  },
  setwaterOrigin (state, waterOrigin) {
    state.waterOrigin = waterOrigin
  },
  setwaterTreatment (state, waterTreatment) {
    state.waterTreatment = waterTreatment
  },
  setsanitation (state, sanitation) {
    state.sanitation = sanitation
  },
  setstorage (state, storage) {
    state.storage = storage
  },
  setattendanceStatus (state, attendanceStatus) {
    state.attendanceStatus = attendanceStatus
  },
  setsphincterControl (state, sphincterControl) {
    state.sphincterControl = sphincterControl
  },
  setaction (state, action) {
    state.action = action
  },
  setoeaResult (state, oeaResult) {
    state.oeaResult = oeaResult
  },
  setsleep (state, sleep) {
    state.sleep = sleep
  },
  settypeSleep (state, typeSleep) {
    state.typeSleep = typeSleep
  },
  setinterruptionCause (state, interruptionCause) {
    state.interruptionCause = interruptionCause
  },
  setcomunicationInHouse (state, comunicationInHouse) {
    state.comunicationInHouse = comunicationInHouse
  },
  setcauseOfRepeat (state, causeOfRepeat) {
    state.causeOfRepeat = causeOfRepeat
  },
  sethoursSleep (state, hoursSleep) {
    state.hoursSleep = hoursSleep
  },
  setquality (state, quality) {
    state.quality = quality
  },
  setuse (state, use) {
    state.use = use
  },
  setcutlery (state, cutlery) {
    state.cutlery = cutlery
  },
  setlimits (state, limits) {
    state.limits = limits
  },
  setlimitsForms (state, limitsForms) {
    state.limitsForms = limitsForms
  },
  setphysicalRelationship (state, physicalRelationship) {
    state.physicalRelationship = physicalRelationship
  },
  setrefersToSon (state, refersToSon) {
    state.refersToSon = refersToSon
  },
  setrefersToMother (state, refersToMother) {
    state.refersToMother = refersToMother
  },
  setmotherToInterviewer (state, motherToInterviewer) {
    state.motherToInterviewer = motherToInterviewer
  },
  setriskIndex (state, riskIndex) {
    state.riskIndex = riskIndex
  },
  settypeOfChildbirth (state, typeOfChildbirth) {
    state.typeOfChildbirth = typeOfChildbirth
  },
  setdevelopmentalGuidelines (state, developmentalGuidelines) {
    state.developmentalGuidelines = developmentalGuidelines
  },
  setmotherAndSon (state, motherAndSon) {
    state.motherAndSon = motherAndSon
  },
  setchildToInterviewer (state, childToInterviewer) {
    state.childToInterviewer = childToInterviewer
  },
  setrepeatSentence (state, repeatSentence) {
    state.repeatSentence = repeatSentence
  },
  setattitudeToFailure (state, attitudeToFailure) {
    state.attitudeToFailure = attitudeToFailure
  },
  setotherFoods (state, otherFoods) {
    state.otherFoods = otherFoods
  },
  setmealTime (state, mealTime) {
    state.mealTime = mealTime
  },
  setadmissionReason (state, admissionReason) {
    state.admissionReason = admissionReason
  },
  setnutritionalDiagnosis (state, nutritionalDiagnosis) {
    state.nutritionalDiagnosis = nutritionalDiagnosis
  },
  setpsychomotorDevelopment (state, psychomotorDevelopment) {
    state.psychomotorDevelopment = psychomotorDevelopment
  },
  setethnicity (state, ethnicity) {
    state.ethnicity = ethnicity
  },
  setlanguage (state, language) {
    state.language = language
  },
  setgender (state, gender) {
    state.gender = gender
  },
  setunities (state, unities) {
    state.unities = unities
  },
  setinstitutionTypes (state, institutionTypes) {
    state.institutionTypes = institutionTypes
  },
  setreferralStates (state, referralStates) {
    state.referralStates = referralStates
  },
  setreferralReasons (state, referralReasons) {
    state.referralReasons = referralReasons
  },
  setschoolingLevel (state, schoolingLevel) {
    state.schoolingLevel = schoolingLevel
  },
  setaccommodationTypes (state, accommodationTypes) {
    state.accommodationTypes = accommodationTypes
  },
  setorganizations (state, organizations) {
    state.organizations = organizations
  }

}
export const actions = {
  /**
     * Obtiene los valores de la enumeración "enumName" del back
     * @param {array} enumName nombre de la enumeración a cargar
    */
  getSomeone: async ({ state, commit }, enumName) => {
    // Sólo si no fue cargada la enumeración llamamos a la api
    if (state[enumName].length === 0) {
      await axios.get('/enum/' + enumName)
        .then(
          (response) => {
            commit('set' + enumName, response.data)
          }
        )
        .catch((e) => {
          console.log('Hubo un error' + e)
        })
    }
  },
  /**
   * Obtiene los valores de las enumeraciones que se pasen como parámetro
   * @param {array} enums arreglo con el nombre de las enumeraciones a cargar
  */
  getMany: async ({ dispatch }, enums) => {
    for (const en of enums) {
      // Para cada uno hacemos la consulta en el back
      await dispatch('getSomeone', en)
    }
  }
}
