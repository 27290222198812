import axios from "axios"

const getPatientStatusNotification = async (filters) => {
return await axios.get('report/get-patient-status-notification', { params: filters }).then((response) => {
    return response.data
  }).catch(() => {
    return []
  })
}

export { getPatientStatusNotification }