import axios from 'axios'
import { personTypeEnum } from '../../enums/personType.enum'
export const state = {
  recovered: null,
  evolved: null,
  total: null,
  involutions: null,
  recoveredTime: null,
  evolvedTime: null,
  involutionsTime: null,
  ageRange: [
    '0 a 6 meses',
    '6 a 12 meses',
    '1 a 2 años',
    'más de 2 años'
  ],
  recoveredByCenter: [],
  recoveredBySex: [],
  recoveredByAge: [],
  evolvedByCenter: [],
  evolvedBySex: [],
  evolvedByAge: [],
  involutionsByCenter: [],
  involutionsBySex: [],
  involutionsByAge: []
}

export const getters = {
  getRecoveredByAgeSeries () {
    return state.recoveredByAge.map(serie => {
      return {
        name: state.ageRange[serie.name - 1],
        value: serie.value
      }
    })
  },
  getEvolvedByAgeSeries () {
    return state.evolvedByAge.map(serie => {
      return {
        name: state.ageRange[serie.name - 1],
        value: serie.value
      }
    })
  },
  getInvolutionsByAgeSeries () {
    return state.involutionsByAge.map(serie => {
      return {
        name: state.ageRange[serie.name - 1],
        value: serie.value
      }
    })
  }
}

export const mutations = {
  'SET_RECOVERED' (state, data) {
    state.recovered = data.toString()
  },
  'SET_EVOLVED' (state, data) {
    state.evolved = data.toString()
  },
  'SET_INVOLUTIONS' (state, data) {
    state.involutions = data.toString()
  },
  'SET_RECOVERED_TIME' (state, data) {
    state.recoveredTime = Number.parseFloat(data).toFixed(0).toString()
  },
  'SET_EVOLVED_TIME' (state, data) {
    state.evolvedTime = Number.parseFloat(data).toFixed(0).toString()
  },
  'SET_INVOLUTIONS_TIME' (state, data) {
    state.involutionsTime = Number.parseFloat(data).toFixed(0).toString()
  },
  'SET_TOTAL' (state, data) {
    let total = 0
    for (const typeCount of data) {
      if (typeCount.type === personTypeEnum.PEDIATRIC) {
        total += Number(typeCount.count)
      }
    }
    state.total = total.toString()
  },
  'SET_RECOVERED_BY_CENTER' (state, data) {
    state.recoveredByCenter = data
  },
  'SET_RECOVERED_BY_SEX' (state, data) {
    state.recoveredBySex = data
  },
  'SET_RECOVERED_BY_AGE' (state, data) {
    state.recoveredByAge = data
  },
  'SET_EVOLVED_BY_CENTER' (state, data) {
    state.evolvedByCenter = data
  },
  'SET_EVOLVED_BY_SEX' (state, data) {
    state.evolvedBySex = data
  },
  'SET_EVOLVED_BY_AGE' (state, data) {
    state.evolvedByAge = data
  },
  'SET_INVOLUTIONS_BY_CENTER' (state, data) {
    state.involutionsByCenter = data
  },
  'SET_INVOLUTIONS_BY_SEX' (state, data) {
    state.involutionsBySex = data
  },
  'SET_INVOLUTIONS_BY_AGE' (state, data) {
    state.involutionsByAge = data
  }
}

export const actions = {
  getRecovered: async ({ commit }, onlyInProgress) => {
    const url = 'general-report/total-evolutions'
    const filters = '?recovered=true&onlyInProgress=' + onlyInProgress
    await axios.get(url + filters)
      .then((response) => {
        commit('SET_RECOVERED', response.data.count)
        commit('SET_RECOVERED_TIME', response.data.time)
        commit('SET_RECOVERED_BY_CENTER', response.data.byCenter)
        commit('SET_RECOVERED_BY_SEX', response.data.bySex)
        commit('SET_RECOVERED_BY_AGE', response.data.byAge)
      }).catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  getEvolved: async ({ commit }, onlyInProgress) => {
    const url = 'general-report/total-evolutions'
    const filters = '?recovered=false&onlyInProgress=' + onlyInProgress
    await axios.get(url + filters)
      .then((response) => {
        commit('SET_EVOLVED', response.data.count)
        commit('SET_EVOLVED_TIME', response.data.time)
        commit('SET_EVOLVED_BY_CENTER', response.data.byCenter)
        commit('SET_EVOLVED_BY_SEX', response.data.bySex)
        commit('SET_EVOLVED_BY_AGE', response.data.byAge)
      }).catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  getInvolutions: async ({ commit }, onlyInProgress) => {
    const url = 'general-report/total-evolutions'
    const filters = '?involutions=true&onlyInProgress=' + onlyInProgress
    await axios.get(url + filters)
      .then((response) => {
        commit('SET_INVOLUTIONS', response.data.count)
        commit('SET_INVOLUTIONS_TIME', response.data.time)
        commit('SET_INVOLUTIONS_BY_CENTER', response.data.byCenter)
        commit('SET_INVOLUTIONS_BY_SEX', response.data.bySex)
        commit('SET_INVOLUTIONS_BY_AGE', response.data.byAge)
      }).catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.messageToShow, code: error.response.status }, { root: true })
        return false
      })
  },
  getTotal: async ({ commit }, onlyInProgress) => {
    await axios.get('clinicalHistory/total-patients?onlyInProgress=' + onlyInProgress)
      .then((response) => {
        commit('SET_TOTAL', response.data)
      }).catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
