export default [
  {
    path: '/',
    name: 'Inicio',
    component: () => import('../views/TheHome.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/home',
    name: 'Inicio-',
    component: () => import('../views/TheHome.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/BaseLogin.vue'),
    meta: {
      authRequired: false
    }
  },
  {
    path: '/precaching',
    name: 'Estamos preparando todo',
    component: () => import('../views/ThePrecaching.vue'),
    meta: {
      authRequired: true,
      layout: 'LoadingLayout'
    }
  },
  {
    path: '/reset/password/:token',
    name: 'Restaurar Contraseña',
    component: () => import('../components/User/BaseResetPassword.vue'),
    meta: {
      authRequired: false
    }
  },
  {
    path: '/change/password',
    name: 'Cambiar Contraseña',
    component: () => import('../components/User/BaseChangePassword.vue'),
    meta: {
      authRequired: false
    }
  },
  {
    path: '/person',
    name: 'Personas',
    component: () => import('../views/ThePerson.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/user',
    name: 'Usuarios',
    component: () => import('../views/TheUser.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/family-group',
    name: 'Grupo Familiar',
    component: () => import('../views/TheFamilyGroup.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/clinical-history',
    name: 'Legajo',
    component: () => import('../views/TheClinicalHistory.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/center',
    name: 'Centro',
    component: () => import('../views/TheCenter.vue'),
    meta: {
      authRequired: true
    }
  }, {
    path: '/family-group/data/:id',
    name: 'Grupo Familiar Detalle',
    component: () => import('../views/TheFamilyGroupData.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/foundation',
    name: 'Fundación',
    component: () => import('../views/TheFoundation.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/follow-up',
    name: 'Seguimientos',
    component: () => import('../views/TheFollowUp.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/autocomplete-example',
    name: 'Autocomplete',
    component: () => import('../views/TheAutocomplete.example.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/housing-conditions',
    name: 'HousingConditions',
    component: () => import('../views/TheHousingConditions.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/foundation',
    name: 'Foundation',
    component: () => import('../views/TheFoundation.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/follow-up',
    name: 'FollowUp',
    component: () => import('../views/TheFollowUp.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/report/patient-status',
    name: 'Reporte de Estado de Pacientes',
    component: () => import('../views/ThePatientStatusReport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/report/conin',
    name: 'Reporte Conin',
    component: () => import('../views/TheConinReport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/report/transitions',
    name: 'Reporte De Transiciones',
    component: () => import('../views/TheTransitionsReport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/error-log',
    name: 'Logs de Errores',
    component: () => import('../views/TheErrorLogs.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/anamnesis',
    name: 'Anamnesis',
    component: () => import('../views/TheAnamnesis.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/hc-transition',
    name: 'Transición de HC',
    component: () => import('../views/TheHCTransition.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/attendance/weekly',
    name: 'Registrar asistencias',
    component: () => import('../views/TheAttendanceGeneration.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/attendance/list',
    name: 'Ver presentes / ausentes',
    component: () => import('../views/TheAttendancesList.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/attendance/office',
    name: 'Asistencia a Consultorio',
    component: () => import('../views/TheOfficeAttendance.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/monitoring',
    name: 'Monitoreo de paciente',
    component: () => import('../views/TheMonitoring.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/stock/abm',
    name: 'Gestor Productos',
    component: () => import('../views/TheABMProducts.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/stock/movements',
    name: 'Movimientos de Stock',
    component: () => import('../views/TheStockMovement.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/stock/product-delivery',
    name: 'Entrega de productos',
    component: () => import('../views/TheProductDelivery.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/export-hc',
    name: 'Exportar HC',
    component: () => import('../views/TheHCExport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/interconsultation-report',
    name: 'Reporte de interconsulta',
    component: () => import('../views/TheInterconsultationReport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/interconsultation-registration',
    name: 'Registro de interconsulta',
    component: () => import('../views/TheInterconsultationRegistration.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/dashboard-status',
    name: 'Tablero por estados',
    component: () => import('../views/TheGeneralStatusReport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/dashboard-evolutions',
    name: 'Tablero por evoluciones',
    component: () => import('../views/TheGeneralEvolutionsReport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/complementary-evaluations',
    name: 'Evaluaciones Complementarias',
    component: () => import('../views/TheStimulationAreasTest.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/dashboard-professionals',
    name: 'Tablero por profesionales',
    component: () => import('../views/TheGeneralProfessionalsReport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/monthly-report',
    name: 'Reporte mensual',
    component: () => import('../views/TheMonthlyReport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/stock-report',
    name: 'Reporte de stock',
    component: () => import('../views/TheStockReport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/town',
    name: 'Municipios',
    component: () => import('../views/TheTown.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/community',
    name: 'Comunidades',
    component: () => import('../views/TheCommunity.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/product-type',
    name: 'Tipos de producto',
    component: () => import('../views/TheProductType.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/stock-alerts',
    name: 'Alertas de stock',
    component: () => import('../views/TheStockAlert.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/anthro-import',
    name: 'Importación Anthro',
    component: () => import('../views/TheAnthroImport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/institution',
    name: 'Instituciones',
    component: () => import('../views/TheInstitution.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/patient-referral',
    name: 'Derivaciones de pacientes',
    component: () => import('../views/ThePatientReferral.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/referral-list',
    name: 'Reporte de derivaciones',
    component: () => import('../views/TheReferralReport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/referral-follow-up-list',
    name: 'Seguimientos por día',
    component: () => import('../views/TheReferralFollows.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/referral-explorer',
    name: 'Derivación',
    component: () => import('../views/TheReferralExplorer.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/permission',
    name: 'Permisos',
    component: () => import('../views/ThePermission.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/person-migration',
    name: 'Migración',
    component: () => import('../views/ThePersonMigration.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/workshop',
    name: 'Talleres',
    component: () => import('../views/TheWorkshop.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/workshop-schedule',
    name: 'Agenda de Talleres',
    component: () => import('../views/TheWorkshopSchedule.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/sync-status',
    name: 'Estado de sincronización',
    component: () => import('../views/TheSyncStatus.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/people-sync-errors',
    name: 'Personas no sincronizadas',
    component: () => import('../views/ThePeopleSyncErrors.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/clinical-history-sync-errors',
    name: 'Legajos no sincronizados',
    component: () => import('../views/TheClinicalHistorySyncErrors.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/follow-up-sync-errors',
    name: 'Seguimientos no sincronizados',
    component: () => import('../views/TheFollowUpSyncErrors.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/attendance-sync-errors',
    name: 'Asistencias con errores',
    component: () => import('../views/TheAttendanceSyncErrors.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/attendance-not-sync',
    name: 'Asistencias no sincronizados',
    component: () => import('../views/TheAttendanceNotSync.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/diagnosis',
    name: 'Diagnósticos',
    component: () => import('../views/TheDiagnosis.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/stock/center-delivery',
    name: 'Envío a otro centro',
    component: () => import('../views/TheCenterDelivery.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/care-report',
    name: 'Reporte de atenciones',
    component: () => import('../views/TheCareReport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/movement-sync-errors',
    name: 'Movimientos no sincronizados',
    component: () => import('../views/TheMovementsSyncErrors.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/patients-report',
    name: 'Reporte de pacientes',
    component: () => import('../views/ThePatientsReport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/report/follow-up',
    name: 'Reporte de seguimientos',
    component: () => import('../views/TheFollowUpReport.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/statement/nutrition',
    name: 'Informe nutricional',
    component: () => import('../views/TheNutritionalStatement.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/statement/nutrition-by-person',
    name: 'Informe nutricional por paciente',
    component: () => import('../views/TheNutritionalStatementByPatient.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/fractionate',
    name: 'Fraccionado',
    component: () => import('../views/TheFractionate.vue'),
    meta: {
      authRequired: true
    }
  }
]
