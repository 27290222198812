<template>
  <div>
    <v-dialog
      v-model="propsConfirm.confirm"
      hide-overlay
      persistent
      :width="propsConfirm.width"
      max-width="300"
      :height="propsConfirm.height"
      max-height="200"
    >
      <v-card>
        <v-card-title
          height="1"
          :class="propsConfirm.color"
          class="d-flex justify-center"
          dark
        >
          <v-icon>{{ propsConfirm.icon }}</v-icon>
          {{ propsConfirm.title }}
        </v-card-title>
        <v-card-text
          style="padding: 1.5rem"
          v-html="propsConfirm.message"
        />
        <v-card-actions>
          <v-btn
            v-if="propsConfirm.showCancel"
            color="error"
            text
            @click="response(false)"
          >
            {{ propsConfirm.dismissText }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="response(true)"
          >
            {{ propsConfirm.acceptText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'BaseConfirm',
  data () {
    return {}
  },
  computed: {
    ...mapState('baseConfirm', ['propsConfirm', 'confirmed'])
  },
  methods: {
    response (value) {
      this.setConfirmResult(value)
      this.confirmed()
    },
    ...mapMutations('baseConfirm', ['setConfirmResult'])
  }
}
</script>
<style scoped>
.content {
  font-size: 14px !important;
  margin-top: 0.5rem;
}
.v-icon {
  margin-right: 0.5rem;
}
</style>
