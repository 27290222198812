<template>
  <div>
    <v-menu
      offset-y
      max-width="240"
      class="mt-5"
    >
      <template #activator="{ on, attrs }">
        <div class="userLogged">
          <v-avatar
            size="47"
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            <span class="white--text headline">{{ currentUser.firstname.charAt(0) }}</span>
          </v-avatar>
        </div>
      </template>
      <v-list>
        <v-list-item-group>
          <div
            class="infoUser mb-1"
            style="align-items: center; padding: 0px"
          >
            <div>Información del Usuario</div>
          </div>
          <v-divider class="userDivider" />
          <div
            v-if="currentUser"
            class="infoUser"
          >
            <div>{{ currentUser.firstname }} {{ currentUser.lastname }}</div>
            <div>{{ currentUser.email }}</div>
            <div>{{ currentUser.area.name }}</div>
            <div>{{ centerName }}</div>
          </div>
        </v-list-item-group>

        <v-divider class="userDivider" />
        <v-list-item-group>
          <v-list-item
            v-for="(item, index) in options"
            :key="index"
            :to="item.route"
            dense
            @click="item.method()"
          >
            <v-list-item-icon>
              <v-icon :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="d-flex justify-start">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <BaseAccessChange
      v-if="showChangeAccess"
      ref="accessChangeForm"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { authActions } from '../../state/helpers'
import BaseAccessChange from '../User/BaseAccessChange'
export default {
  components: {
    BaseAccessChange
  },
  data () {
    return {
      userHaveImage: true,
      currenCenter: null,
      multipleAccess: false,
      logOutAlertMessage: 'Si cierra sesión se perderán todos los datos no sincronizados. ¿Desea continuar?'
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('user', ['userStore']),
    ...mapState('center', ['centerStore']),
    centerName () {
      if (!this.currenCenter) {
        return null
      }
      return this.currenCenter.name
    },
    showChangeAccess () {
      return this.multipleAccess && this.$root.onLine
    },
    options () {
      const items = []
      if (this.showChangeAccess) {
        items.push({
          icon: 'mdi-swap-horizontal',
          text: 'Cambiar acceso',
          route: null,
          method: this.changeAccess,
          color: 'warning'
        })
      }
      items.push({
        icon: 'mdi-sync',
        text: 'Refrescar',
        route: null,
        method: this.refresh,
        color: 'blue'
      })
      items.push({
        icon: 'mdi-logout',
        text: 'Cerrar sesión',
        route: null,
        method: this.loggedOut,
        color: 'error'
      })
      return items
    }
  },
  async beforeMount () {
    if (this.currentUser.center) {
      await this.getCenterById(this.currentUser.center.id)
      this.currenCenter = this.centerStore

    }
    if (!this.$root.onLine) return
    await this.getAccessForUser()
    this.multipleAccess = this.userStore.centerAreas.length > 1
  },
  methods: {
    ...authActions,
    ...mapActions('user', ['getAccessForUser']),
    ...mapActions('center', ['getCenterById']),
    ...mapActions('baseConfirm', ['confirm']),
    loggedOut () {
      this.confirm({
        message: this.logOutAlertMessage
      }).then(response => {
        if (response) {
          this.logOut(true)
        }
      })
    },
    userSettings () {
      console.log('userSettings')
    },
    changeAccess () {
      this.confirm({
        message: this.logOutAlertMessage
      }).then(response => {
        if (response) {
          this.$refs.accessChangeForm.openForm()
        }
      })
    },
    refresh () {
      location.reload()
    }
  }
}
</script>

<style scoped>
.userLogged {
  margin: 0px 10px 0px 10px;
}
.infoUser {
  height: auto;
  width: 240px;
  margin: 4px 0px 4px 0px;
  padding-left: 18px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
}
.userDivider {
  margin: 0px 8px 0px 8px;
}
</style>
