import moment from 'moment'
import { QueueStore } from 'workbox-background-sync'
import { getSyncPersonByClinicalHistoryId } from '../../services/person.service'
import { db } from '../database'
import { getClinicalHistoryById } from './clinicalHistory.repository'
import { getPeopleCount, getPersonByClinicalHistoryId, getPersonById, getPersonWithErrorById } from './person.repository'
import { getBatchById } from './batch.repository'
import { getProductById } from './product.repository'
import { forcePersonSyncError } from '../syncHandlers/person.sync.handler'
import { forceClinicalHistorySyncError } from '../syncHandlers/clinicalHistory.sync.handler'
import { forceFollowUpSyncError } from '../syncHandlers/followUp.sync.handler'
import { forceMovementSyncError } from '../syncHandlers/movement.sync.handler'

export const getNotSyncRegisters = async () => {
  const notSyncPeople = await db.personStore.where({ notSync: 1 }).toArray()
  const notSyncClinicalHistories = await db.clinicalHistoryStore.where({ notSync: 1 }).toArray()
  const notSyncFollows = await db.followUpStore.where({ notSync: 1 }).toArray()
  const notSyncAttendances = await db.attendanceStore.where({ notSync: 1 }).toArray()
  const notSyncMovements = await db.movementStore.toArray()
  const notSyncDiagnosis = await db.diagnosisStore.toArray()
  return { notSyncPeople, notSyncClinicalHistories, notSyncFollows, notSyncAttendances, notSyncMovements, notSyncDiagnosis }
}

export const getSyncErrorsCount = async () => {
  const peopleErrors = await db.failedPeopleStore.count()
  const clinicalHistoryErrors = await db.failedClinicalHistoriesStore.count()
  const followErrors = await db.failedFollowsStore.count()
  const attendancesErrors = await db.failedAttendancesStore.count()
  const movementsErrors = await db.failedMovementsStore.count()
  return peopleErrors + clinicalHistoryErrors + followErrors + attendancesErrors + movementsErrors
}

export const getWeirdErrorsCount = async () => {
  const weirdErrorsCount = await db.failedRequestsStore.count()
  return weirdErrorsCount
}

export const getPeopleSyncErrors = async () => {
  const peopleErrors = await db.failedPeopleStore.toArray()
  return peopleErrors
}

export const getClinicalHistorySyncErrors = async () => {
  const clinicalHistoryErrors = await db.failedClinicalHistoriesStore.toArray()
  for (const ch of clinicalHistoryErrors) {
    if (!ch.clinicalHistory.person) {
      let person = await getPersonById(ch.clinicalHistory.personId)
      if (!person) {
        person = await getPersonWithErrorById(ch.clinicalHistory.personId)
        person = person.person
      }
      ch.clinicalHistory.person = person
    }
  }
  return clinicalHistoryErrors
}

export const getFollowsSyncErrors = async () => {
  const followsErrors = await db.failedFollowsStore.toArray()
  for (const error of followsErrors) {
    if (!error || !error.followUp || !error.followUp.clinicalHistoryId) continue
    const person = await getPersonByClinicalHistoryId(error.followUp.clinicalHistoryId)
    error.followUp.person = person
  }
  return followsErrors
}

export const getAttendancesSyncErrors = async () => {
  const attendancesErrors = await db.failedAttendancesStore.toArray()
  for (const error of attendancesErrors) {
    const person = await getPersonByClinicalHistoryId(error.attendance.clinicalHistoryId)
    error.attendance.person = person
  }
  return attendancesErrors
}

export const getMovementsSyncErrors = async () => {
  const movementsErrors = await db.failedMovementsStore.toArray()
  for (const error of movementsErrors) {
    const person = await getPersonByClinicalHistoryId(error.movement.clinicalHistoryId)
    const batch = await getBatchById(error.movement.batchId)
    const product = await getProductById(batch.productId)
    error.movement.product = product
    error.movement.person = person
  }
  return movementsErrors
}

export const deletePersonSyncError = async (personId) => {
  const error = await db.failedPeopleStore.where('person.id').equals(Number(personId)).first()
  await db.failedPeopleStore.delete(error.id)
}

export const deletePersonAndCascade = async (personId) => {
  const error = await db.failedPeopleStore.where('person.id').equals(Number(personId)).first()
  await db.failedPeopleStore.delete(error.id)
  const localClinicalHistoryWithError = await db.failedClinicalHistoriesStore.where('clinicalHistory.personId').equals(Number(personId)).first()
  if (localClinicalHistoryWithError && localClinicalHistoryWithError.clinicalHistory) await deleteClinicalHistoryAndCascade(localClinicalHistoryWithError.clinicalHistory.id)
}

/**
 * Cuando corregimos un error de sincronización, almacenamos su nuevo ID
 * en todas las tablas con referencias a ésta. Reemplazamos ID local por ID del servidor
 */
export const cascadeNewPersonId = async (oldPersonId, newPersonId, person) => {
  const newPerson = await getPersonById(newPersonId)
  const localClinicalHistoryWithError = await db.failedClinicalHistoriesStore.where('clinicalHistory.personId').equals(Number(oldPersonId)).first()
  if (!localClinicalHistoryWithError) return
  localClinicalHistoryWithError.person = newPerson
  localClinicalHistoryWithError.clinicalHistory.person = person
  localClinicalHistoryWithError.clinicalHistory.personId = newPersonId
  localClinicalHistoryWithError.clinicalHistory.isLocked = 0
  await db.failedClinicalHistoriesStore.update(localClinicalHistoryWithError.id, {
    clinicalHistory: localClinicalHistoryWithError.clinicalHistory,
    person: person
  })
}

export const deleteClinicalHistorySyncError = async (clinicalHistoryId) => {
  const error = await db.failedClinicalHistoriesStore.where('clinicalHistory.id').equals(Number(clinicalHistoryId)).first()
  await db.failedClinicalHistoriesStore.delete(error.id)
}

export const cascadeNewClinicalHistoryId = async (oldCliicalHistoryId, newClinicalHistoryId) => {
  // Primero obtenemos los datos de la persona del legajo creado
  const person = await getSyncPersonByClinicalHistoryId(newClinicalHistoryId)
  // Luego cascadeamos seguimiento
  const localFollowsWithError = await db.failedFollowsStore.where('followUp.clinicalHistoryId').equals(Number(oldCliicalHistoryId)).toArray()
  if (localFollowsWithError && localFollowsWithError.length) {
    for (const error of localFollowsWithError) {
      error.followUp.clinicalHistoryId = newClinicalHistoryId
      error.followUp.person = person
      error.followUp.isLocked = 0
      await db.failedFollowsStore.update(error.id, {
        followUp: error.followUp
      })
    }
  }

  // Luego cascadeamos asistencia
  // TODO: REVISAR ESTE CASCADEO
  const localAttendancesWithError = await db.failedAttendancesStore.where('attendance.clinicalHistoryId').equals(Number(oldCliicalHistoryId)).toArray()
  if (localAttendancesWithError && localAttendancesWithError.length) {
    const clinicalHistory = await getClinicalHistoryById(newClinicalHistoryId)
    for (const error of localAttendancesWithError) {
      error.attendance.clinicalHistoryId = newClinicalHistoryId
      error.attendance.isLocked = 0
      error.attendance.clinicalHistory = clinicalHistory
      await db.failedAttendancesStore.update(error.id, {
        attendance: error.attendance
      })
    }
  }

  // Luego cascadeamos entrega de productos
  const localMovementsWithError = await db.failedMovementsStore.where('movement.clinicalHistoryId').equals(Number(oldCliicalHistoryId)).toArray()
  if (localMovementsWithError && localMovementsWithError.length) {
    for (const error of localMovementsWithError) {
      error.movement.clinicalHistoryId = newClinicalHistoryId
      error.movement.isLocked = 0
      await db.failedMovementsStore.update(error.id, {
        movement: error.movement
      })
    }
  }
}

export const deleteClinicalHistoryAndCascade = async (clinicalHistoryId) => {
  await deleteClinicalHistorySyncError(clinicalHistoryId)
  
  const localFollowsWithError = await db.failedFollowsStore.where('followUp.clinicalHistoryId').equals(Number(clinicalHistoryId)).toArray()
  if (localFollowsWithError && localFollowsWithError.length) {
    for (const error of localFollowsWithError) {
      await deleteFollowUpSyncError(error.followUp.id)
    }
  }

  const localAttendancesWithError = await db.failedAttendancesStore.where('attendance.clinicalHistoryId').equals(Number(clinicalHistoryId)).toArray()
  if (localAttendancesWithError && localAttendancesWithError.length) {
    for (const error of localAttendancesWithError) {
      await deleteAttendanceSyncError(error.attendance.id)
    }
  }
}

export const deleteFollowUpSyncError = async (followUpId) => {
  const error = await db.failedFollowsStore.where('followUp.id').equals(Number(followUpId)).first()
  await db.failedFollowsStore.delete(error.id)
}

export const deleteAttendanceSyncError = async (attendanceId) => {
  const error = await db.failedAttendancesStore.where('attendance.id').equals(Number(attendanceId)).first()
  await db.failedAttendancesStore.delete(error.id)
}

export const deleteMovementSyncError = async (movementId) => {
  const error = await db.failedMovementsStore.where('movement.id').equals(Number(movementId)).first()
  await db.failedMovementsStore.delete(error.id)
}

export const getLastUpdate = async () => {
  const lastUpdates = await db.updatesStore.toArray()
  if (!lastUpdates || !lastUpdates.length) return
  const lastUpdate = lastUpdates.sort((a, b) => {
    return moment(a.date).isBefore(moment(b.date)) ? 1 : -1
  })
  return moment(lastUpdate[0].date)
}

export const stampLastUpdate = async () => {
  const lastUpdate = {
    date: moment().toISOString()
  }
  await db.updatesStore.put(lastUpdate)
}

/**
 * Borrar toda la información local cuando se cierra sesión
 */
export const clearDatabase = async () => {
  // Primero llevamos los registros no sincronizados a los store de errores
  const notSyncPeople = await db.personStore.where({ notSync: 1 }).toArray()
  const notSyncClinicalHistories = await db.clinicalHistoryStore.where({ notSync: 1 }).toArray()
  const notSyncFollows = await db.followUpStore.where({ notSync: 1 }).toArray()
  const notSyncMovements = await db.movementStore.toArray()
  
  for (const person of notSyncPeople) {
    await forcePersonSyncError(person)
  }
  for (const clinicalHistory of notSyncClinicalHistories) {
    await forceClinicalHistorySyncError(clinicalHistory)
  }
  for (const followUp of notSyncFollows) {
    await forceFollowUpSyncError(followUp)
  }
  for (const movement of notSyncMovements) {
    await forceMovementSyncError(movement)
  }

  // Borramos las tablas (menos las de error)
  const tables = db.tables
  const filteredTables = tables.filter(table => !table.name.toUpperCase().includes('FAILED') && table.name !== 'diagnosisStore' )
  for (const table of filteredTables) {
    await table.clear()
  }

  // Borramos las colas de sincronización
  const queueStore = new QueueStore('sync-queue')
  const queueEntries = await queueStore.getAll()
  for (const entry of queueEntries) {
    await queueStore.deleteEntry(entry.id)
  }

}

export const getDataCheck = async () => {
  const people = await getPeopleCount()
  if (people && people > 0) return true
  return false
} 
