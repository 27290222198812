import httpStatus from "http-status"
import { paginateArray } from "../../utils/arrayHelper"
import { CONNECTION_ERROR_MESSAGE, FETCH_ERROR_MESSAGE } from "../../utils/constants"
import { db } from "../database"
import { getAttendanceByPatientAndDate } from "./attendance.repository"
import { getClinicalHistoryById } from "./clinicalHistory.repository"
import { getPatientsByValue } from "./patient.repository"
import { getBondsByChildId, getBondsFamilyById } from "./familyGroup.repository"

// Validamos la existencia de una persona segun nombre y apellido, o documento / cuil
const getExistingPerson = async (person) => {
  if (!person) return
  if (person.document) {
    const existingDocument = await db.personStore.where({ document: person.document }).toArray()
    if (existingDocument &&  existingDocument.length) return true
  }
  if (person.cuil) {
    const existingCuil = await db.personStore.where({ cuil: person.cuil }).toArray()
    if (existingCuil &&  existingCuil.length) return true
  }
  const allPeople = await db.personStore.toArray()
  const existingPerson = allPeople.filter(p => {
    return String(p.firstname).toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '') === String(person.firstname).toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '') &&
    String(p.lastname).toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '') === String(person.lastname).toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '')
  })
  if (existingPerson &&  existingPerson.length) return true
  return false
}


export const addOrUpdatePerson  = async (person) => {
  if (!person) return
  const personAdded = await db.personStore.put(person)
  return personAdded
}

export const addOrUpdatePersonWithValidation  = async (person) => {
  if (!person) return
  const existingPerson = await getExistingPerson(person)
  if (existingPerson) throw { statusCode: httpStatus.BAD_REQUEST, message: 'Ya existe esa persona.' }
  const personAdded = await db.personStore.put(person)
  return personAdded
}

export const addPeople  = async (people) => {
  if (!people) return
  await db.personStore.bulkPut(people)
}

export const getAllPeople = async (page, itemsPerPage, centerId) => {
  let allPeople = await db.personStore.toArray()
  if (centerId) {
    allPeople = allPeople.filter( person => person.centerId === centerId)
  }
  return paginateArray(allPeople, page, itemsPerPage)
}

export const getPeopleByFilters = async ({ page, itemsPerPage, firstname, lastname, document, personType, familyGroupId, centerId }) => {
  // Consultamos todas las personas, luego filtramos, y por último paginamos
  let people = await getAllPeople(-1, -1)
  people = people.items
  if (firstname) {
    people = people.filter(person => { return String(person.firstname).toUpperCase().includes(String(firstname).toUpperCase()) })
  }
  if (lastname) {
    people = people.filter(person => { return String(person.lastname).toUpperCase().includes(String(lastname).toUpperCase()) })
  }
  if (document) {
    people = people.filter(person => { return String(person.document).toUpperCase().includes(String(document).toUpperCase()) })
  }
  if (personType) {
    people = people.filter(person => { return person.personType === personType })
  }
  if (familyGroupId) {
    people = people.filter(person => { return person.familyGroupId === Number(familyGroupId) })
  }
  if (centerId) {
    people = people.filter(person => { return person.centerId === Number(centerId) })
  }
  return paginateArray(people, page, itemsPerPage)
}

export const getPersonById = async (id) => {
  const person = await db.personStore.get({id: Number(id)})
  return person
}

export const getPersonWithErrorById = async (id) => {
  const person = await db.failedPeopleStore.where('person.id').equals(Number(id)).first()
  return person
}

export const getPersonByRequestHash = async (requestHash) => {
  const person = await db.personStore.get({requestHash})
  return person
}

export const deleteNotSyncPeople = async () => {
  const people = await db.personStore.toArray()
  const notSyncPeople = people.filter(person => person.notSync)
  for (const person of notSyncPeople) {
    await db.personStore.delete(person.id)
  }
}

export const removePersonByRequestHash = async (requestHash) => {
  const person = await db.personStore.get({ requestHash })
  if (person) {
    await db.personStore.delete(person.id)
  }
}

export const addFailedSyncRequest = async (requestHash, error) => {
  if (error && error.message === FETCH_ERROR_MESSAGE) {
    error.message = CONNECTION_ERROR_MESSAGE
  }
  const person = await db.personStore.get({ requestHash })
  db.failedPeopleStore.put({ person, error })
}

export const getPersonByClinicalHistoryId = async(clinicalHistoryId) => {
  const clinicalHistory = await getClinicalHistoryById(clinicalHistoryId)
  if (!clinicalHistory) return null
  const person = await getPersonById(clinicalHistory.personId)
  return person
}

// Buscador de personas para la sección de asistencias (para agregarlas manualmente)
export const getPeopleForAttendanceSearch = async({ value, date, centerId }) => {
  const patients = await getPatientsByValue(value, centerId, true)
  const response = []
  for (const patient of patients) {
    const { person, clinicalHistory } = patient
    const todayAttendances = await getAttendanceByPatientAndDate(clinicalHistory.id, date)
    response.push({
      id: person.id,
      firstname: person.firstname,
      lastname: person.lastname,
      document: person.document,
      centerId: person.centerId,
      community: person.community,
      communityId: person.communityId,
      title: `${person.firstname} ${person.lastname}`,
      clinicalHistoryId: clinicalHistory.id,
      todayAttendance: todayAttendances && todayAttendances.length && todayAttendances[0]
    })
  }
  response.sort((p1, p2) => String(p1.lastname).toUpperCase().localeCompare(String(p2.lastname).toUpperCase()))
  return response.filter(person => !centerId || person.centerId === centerId )
}


// Buscador de personas para la sección de asistencias (para agregarlas manualmente)
export const getPeopleForFamilySearch = async({ value, centerId }) => {
  const patients = await getPatientsByValue(value, centerId)
  const response = []
  for (const patient of patients) {
    const { person, clinicalHistory } = patient
    response.push({
      id: person.id,
      firstname: person.firstname,
      lastname: person.lastname,
      document: person.document,
      centerId: person.centerId,
      familyGroupId: person.familyGroupId,
      title: `${person.firstname} ${person.lastname}`,
      description: `${clinicalHistory ? 'Legajo: ' + clinicalHistory.id + ' - ' : ''}Documento: ${person.document} - Comunidad: ${person.community && person.community.name}`
    })
  }
  return response.filter(person => !centerId || person.centerId === centerId )
}


export const getPeopleCount = async () => {
  const peopleCount = await db.personStore.count()
  return peopleCount
}

export const getParentByPersonId = async (personId) => {
  const bonds = await getBondsByChildId(Number(personId))
  if (!bonds?.length) return
  const parentBonds = bonds.filter(bond => bond.bond === 'padre/madre')
  if (!parentBonds || !parentBonds.length) return null
  const motherBond = parentBonds.find(bond => bond.relative.sex === 'Mujer')
  const fatherBond = parentBonds.find(bond => bond.relative.sex === 'Varón')
  const parentBond = motherBond || fatherBond
  const result = {
    id: parentBond.relative?.id,
    firstname: parentBond.relative?.firstname,
    lastname: parentBond.relative?.lastname
  }
  return result
}