import { db } from "../database"

export const addOrUpdateFailedRequest  = async (failedRequest) => {
  if (!failedRequest) return
  const failedRequestAdded = await db.failedRequestsStore.put(failedRequest)
  return failedRequestAdded
}

export const deleteFailedRequests  = async () => {
  await db.failedRequestsStore.clear()
}
