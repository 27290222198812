import { CONNECTION_ERROR_MESSAGE } from "../../utils/constants";
import { removeMovementsByRequestHash, addFailedSyncRequest, verifyLockedByRequestHash, getMovementByRequestHash } from "../repository/movement.repository";

// Creamos una nueva solicitud para la sincronización
// Asegurándonos que mandemos correctamente la referencia al id del legajo
// Buscamos evitar crear seguimiento a otro legajo (por enviar clinicalHistoryId local)
// Esto sucede porque la solicitud original (la que falló) guarda el id del legajo creado localmente
// Entonces lo reemplazamos por el clinicalHistoryId del servidor
const createNewRequest = async (request) => {
  // Primero tomamos información general
  const headers = request.headers
  const method = request.method
  const url = request.url
  // Luego clonamos el body
  const body = await request.json()
  // Buscamos la historia clínica local que ahora está actualizada
  const requestHash = request.headers.get('request-hash')
  const localMovement = await getMovementByRequestHash(requestHash)
  for (const movement of body) {
    movement.clinicalHistoryId = localMovement.clinicalHistoryId
  }
  const newRequest = new Request(url, {
    body: JSON.stringify(body),
    method: method,
    headers: headers,
  })
  return newRequest
}

export const movementOnSyncHandler = async (entry) => {
  const requestHash = entry.request.headers.get('request-hash')
  try {
    const clonedRequest = await createNewRequest(entry.request)
    const isLocked = await verifyLockedByRequestHash(requestHash)
    if (isLocked) {
      throw { message: 'El movimiento no fue creado porque la operación está bloqueada. (El legajo asociado tuvo un error al sincronizar)' }
    }
    const response = await fetch(clonedRequest);
    const body = await response.json()
    if (response.status >= 400) throw body
    await removeMovementsByRequestHash(requestHash)
  } catch (error) {
    console.log('ERR: An error ocurred trying to sync movement', error)
    await addFailedSyncRequest(requestHash, error)
    await removeMovementsByRequestHash(requestHash)
    throw error;
  }
}

export const forceMovementSyncError = async (notSyncMovement) => {
  await addFailedSyncRequest(notSyncMovement.requestHash, CONNECTION_ERROR_MESSAGE)
  await removeMovementsByRequestHash(notSyncMovement.requestHash)
}
