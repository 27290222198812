<template>
  <div>
    <v-app-bar
      app
      clipped-left
      height="65"
      class="elevation-4 orange-bg"
    >
      <v-skeleton-loader
        v-bind="{
          class: 'skeleton-nav'
        }"
        type="avatar"
      />
      <v-skeleton-loader
        v-bind="{
          class: 'skeleton-logo'
        }"
        type="image"
      />
      <v-spacer />
      <v-skeleton-loader
        v-bind="{
          class: 'skeleton-avatar'
        }"
        type="avatar"
      />
    </v-app-bar>
  </div>
</template>
<script>
export default {
  data () {
    return {
      miniVariant: null,
      drawer: null
    }
  },
  async mounted () {
    this.$watch('$refs.drawerNav.isMobile', (value) => {
      if (value === true) {
        this.miniVariant = false
      }
      if (value === false) {
        this.drawer = true
        this.miniVariant = true
      }
    })
  }
}
</script>
<style css scoped>
  .orange-bg {
    background-color: #E58D1F !important;
  }
  .skeleton-nav {
    height: 50px;
    width: 50px;
  }
  .skeleton-logo {
    margin-left: 20px;
    width: 200px;
    max-height: 100%;
  }
  .skeleton-avatar {
    height: 50px;
    width: 50px;
  }
</style>
