import axios from 'axios'
import moment from 'moment'

const getAllFollows = async () => {
  return await axios.get('/follow-up', {
    params: {
      page: -1,
      itemsPerPage: -1,
      onlyEnabledIndicators: true,
      // startDate: moment().subtract(2, 'months').format('YYYY-MM-DD'), NOW: GET FOLLOS OF ALL TIMES
      area: -1
    },
    headers: {
      'Cache-Control': 'no-cache'
    }
  }).then((response) => {
    return response.data
  }).catch(() => {
    return false
  })
}

const getAllIndicators = async () => {
  return await axios.get('/follow-up/indicators-by-area', {
    headers: {
      'Cache-Control': 'no-cache'
    }
  }).then((response) => {
    return response.data
  }).catch(() => {
    return false;
  })
}

const getFollowsForReport = async (filters) => {
  return await axios.get('/follow-up/for-report', {
    params: filters,
    headers: {
      'Cache-Control': 'no-cache'
    }
  }).then((response) => {
    return response.data
  }).catch(() => {
    return false;
  })
}

const cleanFollowUp = (followUpToSave) => {
  // Quitamos campos locales antes de almacenar en servidor
  delete followUpToSave.id
  delete followUpToSave.requestHash
  delete followUpToSave.notSync
  delete followUpToSave.isLocked
  delete followUpToSave.area
  delete followUpToSave.areaid
  delete followUpToSave.isEnabled
  delete followUpToSave.determinantResult
  delete followUpToSave.createdAt
  delete followUpToSave.updatedAt
  delete followUpToSave.createdBy
  delete followUpToSave.updatedBy
  delete followUpToSave.person
  return followUpToSave
}

export { getAllFollows, getAllIndicators, getFollowsForReport, cleanFollowUp }
