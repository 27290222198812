import moment from "moment"
import { CONNECTION_ERROR_MESSAGE, FETCH_ERROR_MESSAGE } from "../../utils/constants"
import { db } from "../database"
import { clinicalHistoryState } from "../../enums/clinicalHistoryState.enum"

export const addClinicalHistories  = async (clinicalHistories) => {
  if (!clinicalHistories) return
  for (const clinicalHistory of clinicalHistories) {
    await addOrUpdateClinicalHistory(clinicalHistory)
  }
}

export const getAllClinicalHistories = async (centerId) => {
  const allClinicalHistories = await db.clinicalHistoryStore.toArray()
  return allClinicalHistories.filter(clinicalHistory => !centerId || clinicalHistory.centerId === centerId)
}

export const addOrUpdateClinicalHistory  = async (clinicalHistory) => {
  if (!clinicalHistory) return
  const clinicalHistoryAdded = await db.clinicalHistoryStore.put(clinicalHistory)
  return clinicalHistoryAdded
}

export const getClinicalHistoryById = async (id) => {
  const clinicalHistory = await db.clinicalHistoryStore.get({id: Number(id)})
  return clinicalHistory
}

export const getClinicalHistoryByPersonId = async (id) => {
  const clinicalHistory = await db.clinicalHistoryStore.get({personId: Number(id)})
  return clinicalHistory
}

export const getClinicalHistoryByRequestHash = async (requestHash) => {
  const clinicalHistory = await db.clinicalHistoryStore.get({ requestHash })
  return clinicalHistory
}

export const removeClinicalHistoryByRequestHash = async (requestHash) => {
  const clinicalHistory = await db.clinicalHistoryStore.get({ requestHash })
  if (clinicalHistory) {
    await db.clinicalHistoryStore.delete(clinicalHistory.id)
  }
}

export const addFailedSyncRequest = async (requestHash, error) => {
  if (error && error.message === FETCH_ERROR_MESSAGE) {
    error.message = CONNECTION_ERROR_MESSAGE
  }
  const clinicalHistory = await db.clinicalHistoryStore.get({ requestHash })
  db.failedClinicalHistoriesStore.put({ clinicalHistory, error })
}

export const verifyLockedByRequestHash = async (requestHash) => {
  const clinicalHistory = await db.clinicalHistoryStore.get({ requestHash })
  return clinicalHistory.isLocked
}

// Desbloquea un legajo para sincronización una vez que ya fue sincronizada la persona
export const unlockByPersonId = async (oldPersonId, newPersonId, person) => {
  const clinicalHistory = await db.clinicalHistoryStore.get({ personId: oldPersonId })
  if (clinicalHistory) {
    await db.clinicalHistoryStore.update(clinicalHistory.id, {
      personId: newPersonId,
      isLocked: false,
      person: person
    })
  }
}

export const getActivePatientsByFilters = async (communityId, date, personType, states) => {
  let clinicalHistories = await db.clinicalHistoryStore.where('state').anyOf([clinicalHistoryState.IN_PROGRESS, clinicalHistoryState.WAITING_APPROVAL]).toArray()
  if (communityId) {
    clinicalHistories = clinicalHistories.filter(clinicalHistory => clinicalHistory.person.communityId === communityId)
  }
  if (personType) {
    clinicalHistories = clinicalHistories.filter(clinicalHistory => clinicalHistory.person.personType === personType)
  }
  if (date) {
    clinicalHistories = clinicalHistories.filter(clinicalHistory => !clinicalHistory.entryDate || (clinicalHistory.entryDate && moment(date).isSameOrAfter(clinicalHistory.entryDate)))
  }
  if (states && states.length) {
    clinicalHistories = clinicalHistories.filter(clinicalHistory => states.includes(clinicalHistory.state))
  }
  return clinicalHistories
}
