import moment from 'moment'

class PersonDto {
  constructor (row) {
    this.firstname = row.FirstName
    this.lastname = row.LastName
    this.dateOfBirth = moment(row.DateOfBirth, 'DD-MM-YYYY').format('YYYY-MM-DD')
  }
}

class FollowUpDto {
  constructor (row) {
    this.person = new PersonDto(row)
    this.externalId = row.DisplayID
    this.date = moment(row.Date, 'DD-MM-YYYY').format('YYYY-MM-DD')
    this.nutritionalStatus = {
      weight: row.Weight,
      size: row.Height,
      headCircumference: row.HC,
      waistCircumference: row.CC,
      bmi: row.BMI,
      zHeadCircumference: row.HCZ,
      zWaistCircumference: row.WCZ,
      zWeightAge: row.WAZ,
      zSizeAge: row.HAZ,
      zWeightSize: row.WHZ,
      zBmi: row.BAZ
    }
    this.observation = row.Notes
  }
}

/**
 * Process text from csv file and parse it to array of objects
 * @param {String} content of CSV
 * @param {String} delimiter character
 * @returns an array of objects
 */
const csvToArray = (str, delimiter = ';') => {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf('\n')).replace('\r', '').split(delimiter)
  headers[0] = 'DisplayID'

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf('\n') + 1).split('\n')

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows.map(function (row) {
    const values = row.replace('\r', '').split(delimiter)
    const el = headers.reduce(function (object, header, index) {
      object[header] = values[index]
      return object
    }, {})
    return el
  })
  // return the array
  return arr.map(row => new FollowUpDto(row))
}

/**
 * Procesa un archivo .csv y lo convierte a un arreglo javascript
 * @param {File} archivo a procesar
 * @returns las filas del archivo csv parseadas a un array (descarta la primera fila / headers /)
 */
const getRows = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()
      reader.addEventListener('load', function (e) {
        resolve(csvToArray(e.target.result))
      })
      reader.readAsBinaryString(file)
    } catch (error) {
      reject(error)
    }
  })
}

/**
 * Si falta alguno de estos datos se toma como formato inválido
 */
const validateRow = (row) => {
  if (!row ||
    !row.nutritionalStatus ||
    !row.nutritionalStatus.weight ||
    !row.nutritionalStatus.size ||
    !row.nutritionalStatus.bmi ||
    !row.nutritionalStatus.zWeightAge ||
    !row.nutritionalStatus.zSizeAge ||
    !row.nutritionalStatus.zWeightSize ||
    !row.nutritionalStatus.zBmi) {
    return 'Formato de archivo inválido'
  }
  return null
}

/**
 * procesador de arhivo csv
 * retorna un arreglo con los seguimientos
 */
const processAnthroFollows = async (file, filters) => {
  let error
  // Parseamos el archivo a una estructura javascript
  let rows = await getRows(file).then(rows => { return rows })
    .catch(err => {
      console.log(err)
      error = err
    })

  if (error) return { error }

  // Validamos el formato correcto de los datos
  const rowForValidation = rows[0]
  error = validateRow(rowForValidation)

  // Retornamos sólo los seguimientos que están dentro del rango de fechas seleccionados
  rows = rows.filter(row => {
    return moment(row.date).isSameOrAfter(moment(filters.dateFrom)) &&
      moment(row.date).isSameOrBefore(moment(filters.dateTo))
  })
  return { rows, error }
}

export {
  processAnthroFollows
}
