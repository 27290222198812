import { CONNECTION_ERROR_MESSAGE, FETCH_ERROR_MESSAGE } from "../../utils/constants"
import { db } from "../database"
import { getPersonByClinicalHistoryId } from "./person.repository"

export const addOrUpdateMovement  = async (movement) => {
  if (!movement) return
  const movementAdded = await db.movementStore.put(movement)
  return movementAdded
}

export const getMovements = async() => {
  const movements = await db.movementStore.toArray()
  const fullDataMovements = []
  for (const movement of movements) {
    movement.person = getPersonByClinicalHistoryId(movement.clinicalHistoryId)
  }
  return fullDataMovements
}

export const removeMovementsByRequestHash = async (requestHash) => {
  const movements = await db.movementStore.where({ requestHash }).toArray()
  for (const movement of movements) {
    await db.movementStore.delete(movement.id)
  }
}

export const addFailedSyncRequest = async (requestHash, error) => {
  if (error && error.message === FETCH_ERROR_MESSAGE) {
    error.message = CONNECTION_ERROR_MESSAGE
  }
  const movement = await db.movementStore.get({ requestHash })
  if (movement) {
    db.failedMovementsStore.put({ movement, error })
  }
}

export const getMovementByRequestHash = async (requestHash) => {
  const movement = await db.movementStore.get({ requestHash })
  return movement
}

export const verifyLockedByRequestHash = async (requestHash) => {
  const movement = await getMovementByRequestHash(requestHash)
  return movement?.isLocked
}
