import axios from 'axios'

export const state = {
  clinicalHistoryData: {
    id: null,
    state: null,
    bornInProgram: null,
    entryDate: '',
    exitDate: '',
    observations: null,
    personId: null, // FK,
    centerName: null
  }
}

export const mutations = {
  setClinicalHistory (state, data) {
    state.clinicalHistoryData = data
  },
  cleanState (state) {
    state.clinicalHistoryData = {
      id: null,
      state: null,
      bornInProgram: null,
      entryDate: '',
      exitDate: '',
      observations: null,
      personId: null, // FK
      centerName: null
    }
  }
}

export const actions = {
  getClinicalHistoryById: async ({ commit }, id) => {
    return await axios.get('clinicalHistory/' + id)
      .then((response) => {
        commit('setClinicalHistory', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getClinicalHistoryByPersonId: async ({ commit }, id) => {
    return await axios.get('clinicalHistory/person/' + id)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit, dispatch }, clinicalHistoryGeneralData) => {
    const isLocked = clinicalHistoryGeneralData.isLocked
    delete clinicalHistoryGeneralData.isLocked
    delete clinicalHistoryGeneralData.person
    return await axios.post('clinicalHistory', clinicalHistoryGeneralData, { params: { isLocked } })
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Legajo creado con éxito' }, { root: true })
        // Actualizamos estado de sincronización
        dispatch('sync/getNotSyncRegisters', {}, { root: true })
        return response.data
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  update: async ({ commit, state }, clinicalHistoryGeneralData) => {
    return await axios.put('clinicalHistory', clinicalHistoryGeneralData)
      .then((response) => {
        commit('baseSnackbar/success', { text: 'Legajo actualizado con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }

}
