import { getWebServiceUrl } from './urlHelper'

const getOrigin = () => {
  const currentUrl = String(window.location.href)
  return currentUrl.includes('dignamente') ? 'dignamente'
    : currentUrl.includes('patapila') ? 'patapila' : 'patapila'
}

const getAsset = (name) => {
  const origin = getOrigin()
  return `${getWebServiceUrl()}/assets/${origin}/${name}`
}

export {
  getOrigin,
  getAsset
}
