<template>
  <div>
    <BaseToolbar @openNav="openNav" />
    <nav app>
      <!-- Navigation -->
      <v-navigation-drawer
        ref="drawerNav"
        v-model="drawer"
        app
        clipped
        class="elevation-4"
        :mini-variant="miniVariant"
      >
        <!-- ITEMS -->
        <v-list
          nav
          dark
          dense
          class="menu-items"
        >
          <template
            v-for="link in filteredMenu"
          >
            <template v-if="link.childrens && link.childrens.length > 0">
              <v-list-group
                :key="link.name"
                dark
                no-action
                color="activeLink"
                @click="openLink()"
              >
                <template #activator>
                  <v-list-item-icon>
                    <v-icon>{{ link.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="principal">
                    <v-list-item-title class="d-flex flex-row justify-start principal">
                      {{ link.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="subLink in link.childrens"
                  :key="subLink.name"
                  :to="subLink.route"
                  style="padding: 0 8px"
                  color="activeLink"
                  class="d-flex flex-row justify-start"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="d-flex flex-row justify-start"
                      style="margin-left: 56px;"
                    >
                      {{ subLink.name }}
                      <v-spacer />
                      <v-icon small>{{ subLink.icon }}</v-icon>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </template>

            <template v-else>
              <v-list-item
                :key="link.name"
                color="activeLink"
                :to="link.route"
                link
              >
                <v-list-item-icon>
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="d-flex flex-row justify-start">{{ link.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
        </v-list>
        <v-list class="last-settings">
          <v-list-item class="d-flex justify-center">
            <v-list-item-icon v-if="miniVariant">
              <v-icon
                class="justify-center"
                color="warning"
                width="100%"
                @click="openSupport"
              >
                mdi-face-agent
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-btn
                class="justify-center warning"
                width="100%"
                plain
                outlined
                @click="openSupport"
              >
                Soporte
                <v-icon
                  right
                  dark
                >
                  mdi-face-agent
                </v-icon>
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseToolbar from './BaseToolbar.vue'
export default {
  name: 'BaseNavbar',
  components: {
    BaseToolbar
  },
  data () {
    return {
      drawer: null,
      miniVariant: null
    }
  },
  computed: {
    ...mapState('permission', ['menu']),
    filteredMenu () {
      if (this.$root.onLine) return this.menu
      return this.menu.filter(menu => menu.offlineAvailable)
        .map(menu => {
          if (!menu.childrens) return menu
          menu.childrens = menu.childrens.filter(child => child.offlineAvailable)
          return menu
        })
    },
    isMobile () {
      return this.$refs?.drawerNav?.isMobile
    }
  },
  async beforeMount () {
    await this.getMenu()
  },
  async mounted () {
    this.$watch('$refs.drawerNav.isMobile', (value) => {
      if (value === true) {
        this.miniVariant = false
      }
      if (value === false) {
        this.drawer = true
        this.miniVariant = true
      }
    })
  },
  methods: {
    openNav () {
      if (this.$refs.drawerNav.isMobile === false) {
        this.miniVariant = !this.miniVariant
      } else {
        this.miniVariant = false
        this.drawer = !this.drawer
      }
    },
    openLink () {
      this.miniVariant = false
    },
    goHome () {
      if (this.$route.fullPath !== '/') this.$router.push('/')
    },
    openSupport () {
      window.open('https://sites.google.com/patapila.org/manual-de-usuarios/pata-pila-app', '_blank');
    },
    ...mapActions('permission', ['getMenu'])
  }
}
</script>
<style lang="css" scoped>
  .principal{
    overflow: visible;
  }
  .last-settings {
    width: 100%;
    position: fixed;
    bottom: 0px;
  }
  .menu-items {
    height: 90%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
</style>
