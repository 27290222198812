import axios from 'axios'
import { processAnthroFollows } from '../../utils/anthroImportHelper'
import { personTypeEnum } from '../../enums/personType.enum'

export const state = {
  follows: [],
  totalFollows: 0,
  filters: {
    page: 1,
    itemsPerPage: 10,
    clinicalHistoryId: null,
    startDate: null,
    endDate: null,
    area: null,
    personType: null
  },
  indicators: [],
  followStore: {},
  lastFollowUp: null,
  aboutMumIndicators: null,
  patientSelectedStore: null,
  anthroImportFilters: {},
  processedFollows: [],
  failedFollows: [],
  successfullyFollows: []
}

export const mutations = {
  'SET_PATIENT_STORE' (state, data) {
    state.patientSelectedStore = data
  },
  'SET_FOLLOWS' (state, data) {
    state.follows = data ? data.items : []
    state.totalFollows = data ? data.totalItems : 0
  },
  'ADD_FOLLOWS' (state, data) {
    state.follows = state.follows.concat(data.items)
    state.totalFollows = data.totalItems
  },
  'SET_INDICATORS' (state, data) {
    state.indicators = data
  },
  'SET_LASTFOLLOWUP' (state, data) {
    state.lastFollowUp = data
  },
  'SET_ABOUTMUMINDICATORS' (state, data) {
    state.aboutMumIndicators = data
  },
  'SET_FOLLOW' (state, data) {
    state.followStore = data
    if (data && !state.followStore.nutritionalStatus) {
      state.followStore.nutritionalStatus = {
        weight: null,
        size: null,
        headCircumference: null,
        waistCircumference: null,
        hasOedema: null,
        bmi: null,
        zHeadCircumference: null,
        zWaistCircumference: null,
        zWeightAge: null,
        zSizeAge: null,
        zWeightSize: null,
        zBmi: null
      }
    } else if (data && !state.followStore.obstetricStatus) {
      state.followStore.obstetricStatus = {
        gestationalAge: null,
        weight: null,
        uterineHeight: null,
        bloodPressure: null,
        presentation: null,
        fcf: null,
        fetalMovements: null,
        toxoplasmosis: null
      }
    }
  },
  'SET_FILTERS' (state, data) {
    state.filters = data
  },
  'SET_PROCESSED_FOLLOWS' (state, data) {
    state.processedFollows = data
  },
  'SET_MIGRATION_RESULT' (state, data) {
    state.failedFollows = data.failedFollows
    state.successfullyFollows = data.successfullyFollows
  }
}

export const actions = {
  getByFilters: async ({ commit }, mode) => {
    state.filters.clinicalHistoryId = state.patientSelectedStore ? state.patientSelectedStore.clinicalHistoryId : state.filters.clinicalHistoryId
    // Si es el final retornamos
    if (mode.isScrolling && (state.follows.length === state.totalFollows)) { return }
    // Si está scrolleando vamos a agregar el resultado a los seguimientos existentes
    if (mode.isScrolling && (state.follows.length < state.totalFollows)) {
      state.filters.page = Math.ceil(state.follows.length / state.filters.itemsPerPage) + 1
    } else {
      // Si está filtrando carga todo desde cero
      state.follows = []
      state.filters.page = mode.page || 1
      state.filters.itemsPerPage = mode.itemsPerPage || 10
      state.filters.onlyEnabledIndicators = mode.onlyEnabledIndicators || false
    }
    await axios.get('follow-up', { params: state.filters })
      .then((response) => {
        if (mode.isScrolling) {
          // Agrega a los seguimientos
          commit('ADD_FOLLOWS', response.data)
        } else {
          // Reemplaza
          commit('SET_FOLLOWS', response.data)
        }
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  getLastFollowUp: async ({ commit }, clinicalHistoryId) => {
    const query = state.filters.area ? '?area=' + state.filters.area : ''
    await axios.get('follow-up/last-follow-up/' + clinicalHistoryId + query)
      .then((response) => {
        commit('SET_LASTFOLLOWUP', response.data)
        return response.status === 200
      })
      .catch((error) => {
        commit('SET_LASTFOLLOWUP', null)
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  getAboutMumIndicators: async ({ commit }, clinicalHistoryId) => {
    return await axios.get('follow-up/about-mum-indicators/' + clinicalHistoryId)
      .then((response) => {
        commit('SET_ABOUTMUMINDICATORS', response.data)
        return true
      })
      .catch((error) => {
        commit('SET_ABOUTMUMINDICATORS', null)
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  getIndicators: async ({ commit }, areaId) => {
    const area = areaId || ''
    const personType = state.filters.personType || personTypeEnum.PEDIATRIC
    const query = `?area=${area}&personType=${personType}&clinicalHistoryId=${state.filters.clinicalHistoryId}`
    await axios.get('follow-up/indicators-by-area' + query)
      .then((response) => {
        commit('SET_INDICATORS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  create: async ({ commit, dispatch }, follow) => {
    const resultsForFollow = []
    for (const indicator in follow.results) {
      resultsForFollow.push(
        {
          indicatorId: indicator,
          resultId: follow.results[indicator].resultId,
          isDeterminant: follow.results[indicator].isDeterminant
        }
      )
    }
    follow.results = resultsForFollow

    // For offline handler
    const isLocked = follow.isLocked
    delete follow.isLocked
    delete follow.person

    return await axios.post('/follow-up', follow, { params: { isLocked } })
      .then(
        (response) => {
          dispatch('getByFilters', false)
          dispatch('getLastFollowUp', follow.clinicalHistoryId)
          commit('baseSnackbar/success', { text: 'Seguimiento creado con éxito' }, { root: true })
          // Actualizamos estado de sincronización
          dispatch('sync/getNotSyncRegisters', {}, { root: true })
          dispatch('sync/getFollowsSyncErrors', {}, { root: true })
          return response.data
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  /**
   * Busca un seguimiento en la base de datos
    */
  getFollowById: async ({ commit }, id) => {
    return await axios.get('follow-up/' + id)
      .then((response) => {
        commit('SET_FOLLOW', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  update: async ({ commit, dispatch }, follow) => {
    const resultsForFollow = []
    for (const indicator in follow.results) {
      follow.results[indicator].indicatorId = indicator
      resultsForFollow.push(follow.results[indicator])
    }
    follow.results = resultsForFollow
    return await axios.put('/follow-up', follow)
      .then(
        (response) => {
          dispatch('getByFilters', false)
          dispatch('getLastFollowUp', follow.clinicalHistoryId)
          commit('baseSnackbar/success', { text: 'Seguimiento actualizado con éxito' }, { root: true })
          return response.data
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  getPatient: async ({ commit, dispatch }, clinicalHistoryId) => {
    return await axios.get('/person/patient-by-id/' + clinicalHistoryId)
      .then(
        (response) => {
          commit('SET_PATIENT_STORE', response.data)
          return response.data
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  getFirstAndLastFollow: async ({ commit }, filters) => {
    return await axios.get('follow-up/first-and-last', { params: filters })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },
  cleanStore: ({ commit }) => {
    commit('SET_PATIENT_STORE', null)
    commit('SET_FOLLOWS', null)
    commit('SET_LASTFOLLOWUP', null)
    commit('SET_FOLLOW', null)
    return false
  },
  getAllFollowsByAreaId: ({ commit }, filters) => {
    return axios.get('follow-up/patient/' + filters.clinicalHistoryId + '/area/' + filters.areaId + '/all')
      .then((response) => {
        return response.data.items
      })
      .catch(() => {
        return false
      })
  },
  /**
   * Procesa el archivo csv de anthro
   * y genera un listado listo para migrar
   */
  processFollows: async ({ commit }, file) => {
    const { rows, error } = await processAnthroFollows(file, state.anthroImportFilters)
    if (error) {
      commit('baseSnackbar/alert', { text: error, code: 400 }, { root: true })
      return false
    }
    commit('SET_PROCESSED_FOLLOWS', rows)
  },
  /**
   * Envía el lsitado de seguimientos procesados desde anthro
   * al servidor
   */
  importAnthroData: async ({ commit }, follows) => {
    return await axios.post('follow-up/import-from-anthro', follows, { params: { ...state.anthroImportFilters } })
      .then(
        (response) => {
          commit('SET_MIGRATION_RESULT', response.data)
          return true
        }
      )
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }
}
