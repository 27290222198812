import { CONNECTION_ERROR_MESSAGE } from "../../utils/constants";
import { removeClinicalHistoryByRequestHash, addFailedSyncRequest, verifyLockedByRequestHash, getClinicalHistoryByRequestHash } from "../repository/clinicalHistory.repository";
import { unlockByClinicalHistoryId } from "../repository/followUp.repository";

// Creamos una nueva solicitud para la sincronización
// Asegurándonos que mandemos correctamente la referencia al id de la persona
// Buscamos evitar crear legajo a otra persona (por enviar personId local)
// Esto sucede porque la solicitud original (la que falló) guarda el id de la persona creada localmente
// Entonces lo reemplazamos por el personId del serivdor
const createNewRequest = async (request) => {
  // Primero tomamos información general
  const headers = request.headers
  const method = request.method
  const url = request.url
  // Luego clonamos el body
  const body = await request.json()
  // Buscamos la historia clínica local que ahora está actualizada
  const requestHash = request.headers.get('request-hash')
  const localClinicalHistory = await getClinicalHistoryByRequestHash(requestHash)
  // reemplazamos con el nuevo personId (en algunos casos puede ser el mismo)
  body.personId = localClinicalHistory.personId
  const newRequest = new Request(url, {
    body: JSON.stringify(body),
    method: method,
    headers: headers,
  })
  return newRequest
}

export const clinicalHistoryOnSyncHandler = async (entry) => {
  const requestHash = entry.request.headers.get('request-hash')
  try {
    const isLocked = await verifyLockedByRequestHash(requestHash)
    if (isLocked) {
      throw { message: 'El legajo no fue creado porque la operación está bloqueada. (La persona asociada tuvo un error al sincronizar)' }
    }
    const clonedRequest = await createNewRequest(entry.request)
    const response = await fetch(clonedRequest);
    const body = await response.json()
    if (response.status >= 400) throw body
    const newClinicalHistoryId = Number(body)
    const oldClinicalHistory = await getClinicalHistoryByRequestHash(requestHash)
    await removeClinicalHistoryByRequestHash(requestHash)
    await unlockByClinicalHistoryId(oldClinicalHistory.id, newClinicalHistoryId)
  } catch (error) {
    await addFailedSyncRequest(requestHash, error)
    await removeClinicalHistoryByRequestHash(requestHash)
    console.log('ERR: An error ocurred trying to sync clinicalHistory', error)
    throw error;
  }
}

export const forceClinicalHistorySyncError = async (notSyncClinicalHistory) => {
  await addFailedSyncRequest(notSyncClinicalHistory.requestHash, CONNECTION_ERROR_MESSAGE)
  await removeClinicalHistoryByRequestHash(notSyncClinicalHistory.requestHash)
}

