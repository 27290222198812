import axios from 'axios'

export const state = {
  townStore: {
    id: '',
    name: '',
    provinceId: null,
    isEnabled: ''
  },
  towns: [],
  totalTowns: 0,
  filters: {
    page: 1,
    itemsPerPage: 10,
    includeDisabled: false,
    name: '',
    provinceId: ''
  }
}

export const mutations = {
  'SET_TOWNS' (state, data) {
    state.towns = data.items
    state.totalTowns = data.totalItems
  },
  'SET_TOWN' (state, data) {
    state.townStore = data
  }
}

export const actions = {

  getByFilters: async ({ commit }) => {
    await axios.get('town', { params: state.filters })
      .then((response) => {
        commit('SET_TOWNS', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  create: async ({ commit, dispatch }, town) => {
    return await axios.post('town', town)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Municipio creada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  update: async ({ commit, dispatch }, town) => {
    return await axios.put(`town/${town.id}`, town)
      .then((response) => {
        dispatch('getByFilters')
        commit('baseSnackbar/success', { text: 'Municipio actualizada con éxito' }, { root: true })
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  },

  getTownById: async ({ commit }, id) => {
    return await axios.get('town/' + id)
      .then((response) => {
        commit('SET_TOWN', response.data)
        return true
      })
      .catch((error) => {
        commit('baseSnackbar/alert', { text: error.response.data && error.response.data.message, code: error.response.status }, { root: true })
        return false
      })
  }

}
