import { cleanFollowUp } from "../../services/followUp.service"
import { CONNECTION_ERROR_MESSAGE } from "../../utils/constants"
import { addOrUpdateFailedRequest } from "../repository/failedRequests.repository"
import { removeFollowUpByRequestHash, addFailedSyncRequest, getFollowUpByRequestHash } from "../repository/followUp.repository"

const createFollowBody = (follow) => {
  const resultsForFollow = []
  for (const result of follow.results) {
    resultsForFollow.push(
      {
        indicatorId: result.indicatorId,
        resultId: result.resultId,
        isDeterminant: result.indicator?.isDeterminant
      }
    )
  }
  follow.results = resultsForFollow
  return cleanFollowUp(follow)
}

// Creamos una nueva solicitud para la sincronización
// Asegurándonos que mandemos correctamente la referencia al id del legajo
// Buscamos evitar crear seguimiento a otro legajo (por enviar clinicalHistoryId local)
// Esto sucede porque la solicitud original (la que falló) guarda el id del legajo creado localmente
// Entonces lo reemplazamos por el clinicalHistoryId del servidor
// Ademas nos aseguramos de mandar la última versión del seguimiento en caso de que haya sido editado
const createNewRequest = async (request, follow) => {
  // Primero tomamos información general
  const headers = request.headers
  const method = request.method
  const url = request.url
  // Luego clonamos el body
  const body = createFollowBody(follow)
  // Buscamos la historia clínica local que ahora está actualizada
  const requestHash = request.headers.get('request-hash')
  const localFollowUp = await getFollowUpByRequestHash(requestHash)
  // reemplazamos con el nuevo personId (en algunos casos puede ser el mismo)
  body.clinicalHistoryId = localFollowUp.clinicalHistoryId
  const newRequest = new Request(url, {
    body: JSON.stringify(body),
    method: method,
    headers: headers,
  })
  return newRequest
}

export const followUpOnSyncHandler = async (entry) => {
  const requestHash = entry?.request?.headers?.get('request-hash')
  const follow = await getFollowUpByRequestHash(requestHash)
  try {
    if (follow.isLocked) {
      throw { message: 'El seguimiento no fue creado porque la operación está bloqueada. (El legajo asociado tuvo un error al sincronizar)' }
    }
    const clonedRequest = await createNewRequest(entry.request, follow)
    const response = await fetch(clonedRequest)
    const body = await response.json()
    if (response.status >= 400) throw body
    await removeFollowUpByRequestHash(requestHash)
  } catch (error) {
    try {
      console.log('ERR: An error ocurred trying to sync followUp', error)
      await addFailedSyncRequest(requestHash, error)
      await removeFollowUpByRequestHash(requestHash)
    } catch (e) {
      const clonedRequest = await createNewRequest(entry.request, follow)
      await addOrUpdateFailedRequest(clonedRequest)
    }
    throw error
  }
}

export const forceFollowUpSyncError = async (notSyncFollowUp) => {
  try {
    await addFailedSyncRequest(notSyncFollowUp.requestHash, CONNECTION_ERROR_MESSAGE)
    await removeFollowUpByRequestHash(notSyncFollowUp.requestHash)
  } catch (error) {
    await addOrUpdateFailedRequest(notSyncFollowUp)
  }
}
